import * as React from 'react';
import { useEffect } from 'react';

import { useHideTsmPointsNotificationLoyaltyBannerExperiment } from 'experiments/hide-tsm-pointsnotification-loyaltybanner/use-enable-item-detail-reorder-experiment';
import { useRoute } from 'hooks/navigation/use-route';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useStoreContext } from 'state/store';
import { useUIContext } from 'state/ui';
import { routes } from 'utils/routing';

import { useTopServiceModeDetails } from './hooks/use-top-service-details';
import { TopServiceModeView } from './top-service-mode.view';

/**
 *
 * TopServiceMode purpose
 *
 */
export const TopServiceMode = ({ isInCart = false }: { isInCart?: boolean }) => {
  const enableOrdering = useFlag(LaunchDarklyFlag.ENABLE_ORDERING);
  const { resetStore, store } = useStoreContext();
  const { setShouldShowPreConfirmCurbsideModal } = useUIContext();
  const { pathname } = useRoute();
  const topServiceModeDetails = useTopServiceModeDetails();
  const hideTsmOnHome = useHideTsmPointsNotificationLoyaltyBannerExperiment();

  useEffect(() => {
    if (!enableOrdering) {
      resetStore();
    }
  }, [enableOrdering, store]);

  if (hideTsmOnHome || pathname === routes.account) {
    return null;
  }

  return (
    <TopServiceModeView
      {...topServiceModeDetails}
      openModal={() => setShouldShowPreConfirmCurbsideModal(true)}
      isInCart={isInCart}
    />
  );
};
