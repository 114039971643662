import { useCallback } from 'react';

import { ApolloError } from '@apollo/client';
import { useToast } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { GestureResponderEvent } from 'react-native';

import { useResendCurrentLoginOtpMutation } from 'generated/graphql-gateway';
import { LogUserErrorSeverity, logUserErrorMessage } from 'hooks/log-user-error-message';
import { HttpErrorCodes } from 'remote/constants';
import { CustomEventNames, EventTypes, logEvent } from 'state/amplitude';
import { getStoredOtpCredentials } from 'state/auth/hooks/use-account-authentication';
import { platform } from 'utils/environment';

interface IUseResendOtpProps {
  setOtpValidationError: (value: string | null) => void;
  setResendOrSendNewOtpError: (value: string | null) => void;
  setShowHasSentResentOtpMessage: (value: boolean) => void;
  setShowSendNewOtpForm: (value: boolean) => void;
  sendSameOtpCodeOnce: boolean;
}

export const useResendOtp = ({
  setOtpValidationError,
  setResendOrSendNewOtpError,
  setShowHasSentResentOtpMessage,
  setShowSendNewOtpForm,
  sendSameOtpCodeOnce,
}: IUseResendOtpProps) => {
  const { formatMessage } = useIntl();
  const { email, phoneNumber } = getStoredOtpCredentials() || {};
  const toast = useToast();

  const [resendCurrentLoginOtp, { loading }] = useResendCurrentLoginOtpMutation({
    onCompleted: () => {
      setResendOrSendNewOtpError(null);
      setShowHasSentResentOtpMessage(true);
    },

    onError: (error: ApolloError) => {
      switch (error?.graphQLErrors?.[0]?.extensions?.statusCode) {
        case HttpErrorCodes.TooManyRequests:
          setResendOrSendNewOtpError(
            logUserErrorMessage({
              message: formatMessage({ id: 'pleaseTryAgainLater' }),
              severity: LogUserErrorSeverity.Irrecoverable,
            })
          );
          break;
        default:
          setResendOrSendNewOtpError(
            logUserErrorMessage({
              message: formatMessage({ id: 'authError' }),
              severity: LogUserErrorSeverity.Recoverable,
            })
          );
          break;
      }
    },
  });

  const resendOtp = useCallback(
    async (ev: GestureResponderEvent) => {
      ev.preventDefault();
      setShowHasSentResentOtpMessage(false);
      setOtpValidationError(null);

      logEvent(CustomEventNames.SIGN_IN_OTP_RE_REQUEST, EventTypes.Other);
      await resendCurrentLoginOtp({
        variables: {
          input: {
            email,
            phoneNumber,
            platform: platform(),
          },
        },
      });
      setShowSendNewOtpForm(sendSameOtpCodeOnce ?? false);
      toast.show({
        text: formatMessage({ id: 'resendCodeSuccess' }),
        variant: 'positive',
      });
    },
    [
      email,
      phoneNumber,
      resendCurrentLoginOtp,
      setOtpValidationError,
      setShowHasSentResentOtpMessage,
      setShowSendNewOtpForm,
      sendSameOtpCodeOnce,
    ]
  );

  return {
    resendOtp,
    isLoading: loading,
  };
};
