import { ImpactArgs, NotifyArgs } from './types';

export const hapticImpact = (args: ImpactArgs = {}) => {
  // eslint-disable-next-line no-console
  console.debug(`Haptic ${args.style} impact triggered`);
};

export const hapticNotification = (args: NotifyArgs) => {
  // eslint-disable-next-line no-console
  console.debug(`Haptic ${args.type} notification triggered`);
};

export const hapticSelection = () => {
  // eslint-disable-next-line no-console
  console.debug('Haptic selection triggered');
};
