// import Ajv from 'ajv';
// import { schema } from './config.schema';

import { Io } from './types';

// Converts a JS value to it's JSON encodable version with single quote strings.
// e.g. "foo" => 'foo'
// e.g., [true, "blah"] => [true, 'blah']
// e.g., {"key": "value"] => { key: 'value' }
export function writeToken(value: any, defaultValue: any = '') {
  return value || defaultValue;
}
const CYAN = '\x1b[36m%s\x1b[0m'

export async function fetchSanityConfig(sanityProjectId: string, sanityDataset: string, io: Io) {
  console.log(CYAN, '[RN Sanity Prebuild] Generating application config');
  console.log(
    CYAN,
    `[RN Sanity Prebuild] Using dataset "${sanityDataset}" in project id "${sanityProjectId}"`
  );

  const resp = await io.fetch(
    `https://${sanityProjectId}.api.sanity.io/v1/data/query/${sanityDataset}?query=*%5B_id%20%3D%3D%20%27front-end-configuration-singleton%27%5D`
  );

  const data = await resp.json();

  // @todo Type the config based on FE config schema
  const config = data.result[0] as any;

  if (!config || Object.keys(config).length === 0) {
    console.log({ data, sanityDataset });
    throw new Error('GROQ query for front end config did not contain a valid result');
  }

  /**
   * Validate our downloaded config against the JSON schema.
   * We should not allow builds to succeed with an invalid
   * config.
   */
  // const validator = new Ajv();
  // const valid = validator.validate(schema, config);

  // if (!valid) {
  //   throw new Error('Sanity config did not pass validation');
  // }

  const structuredData = {
    apiKeys: {
      amplitude: writeToken(config.apiKeys?.amplitude),
      branch: writeToken(config.apiKeys?.branch),
      braze: writeToken(config.apiKeys?.braze),
      bringg: writeToken(config.apiKeys?.bringg),
      googleMaps: writeToken(config.apiKeys?.googleMaps),
      googleMapsWeb: writeToken(config.apiKeys?.googleMapsWeb),
      googleMapsIOS: writeToken(config.apiKeys?.googleMapsIOS),
      googleMapsAndroid: writeToken(config.apiKeys?.googleMapsAndroid),
      googleAnalytics: writeToken(config.apiKeys?.googleAnalytics),
      launchDarkly: writeToken(config.apiKeys?.launchDarkly),
      launchDarklyMobileKey: writeToken(config.apiKeys?.launchDarklyMobileKey),
      radar: writeToken(config.apiKeys?.radar),
      amplitudeExperiments: writeToken(config.apiKeys?.amplitudeExperiments),
      flyBuyIOS: writeToken(config.apiKeys?.flyBuyIOS),
      flyBuyAndroid: writeToken(config.apiKeys?.flyBuyAndroid),
    },
    apple: {
      merchantId: writeToken(config.apple?.merchantId),
      migrationMerchantId: writeToken(config.apple?.migrationMerchantId),
      prepaidMerchantId: writeToken(config.apple?.prepaidMerchantId),
      migrationPrepaidMerchantId: writeToken(config.apple?.migrationPrepaidMerchantId),
      paymentsNetworks: writeToken(config.apple?.paymentsNetworks, []),
    },
    aws: {
      gqlApiUrl: writeToken(config.aws.gqlApiUrl),
      gqlGatewayApiUrl: writeToken(config.aws.gqlGatewayApiUrl),
      region: writeToken(config.aws.region),
      userPoolClientId: writeToken(config.aws.userPoolClientId),
      userPoolId: writeToken(config.aws.userPoolId),
    },
    bringg: {
      region: writeToken(config.bringg?.region),
    },
    datadog: {
      logs: {
        clientToken: writeToken(config.dataDog?.logs?.clientToken),
        dataCenter: writeToken(config.dataDog?.logs?.dataCenter),
      },
      rum: {
        applicationId: writeToken(config.dataDog?.rum?.applicationId),
        clientToken: writeToken(config.dataDog?.rum?.clientToken),
        dataCenter: writeToken(config.dataDog?.rum?.dataCenter),
      },
      rumRn: {
        applicationId: writeToken(config.dataDog?.rumRn?.applicationId),
        clientToken: writeToken(config.dataDog?.rumRn?.clientToken),
        dataCenter: writeToken(config.dataDog?.rumRn?.dataCenter),
      },
    },
    forter: {
      siteId: writeToken(config.forter.siteId),
    },
    google: {
      paymentsEnvironment: writeToken(config.google?.paymentsEnvironment),
      paymentsGateway: writeToken(config.google?.paymentsGateway),
      migrationPaymentsGateway: writeToken(config.google?.migrationPaymentsGateway),
      paymentsGatewayMerchantId: {
        ca: writeToken(config.google?.paymentsGatewayMerchantId?.ca),
        us: writeToken(config.google?.paymentsGatewayMerchantId?.us),
      },
      migrationPaymentsGatewayMerchantId: {
        ca: writeToken(config.google?.migrationPaymentsGatewayMerchantId?.ca),
        us: writeToken(config.google?.migrationPaymentsGatewayMerchantId?.us),
      },
      paymentsNetworks: writeToken(config.google?.paymentsNetworks, []),
      prepaidPaymentsGateway: writeToken(config.google?.prepaidPaymentsGateway),
      migrationPrepaidPaymentsGateway: writeToken(config.google?.migrationPrepaidPaymentsGateway),
      prepaidPaymentsGatewayMerchantId: {
        ca: writeToken(config.google?.prepaidPaymentsGatewayMerchantId?.ca),
        us: writeToken(config.google?.prepaidPaymentsGatewayMerchantId?.us),
      },
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: writeToken(config.google?.migrationPrepaidPaymentsGatewayMerchantId?.ca),
        us: writeToken(config.google?.migrationPrepaidPaymentsGatewayMerchantId?.us),
      },
    },
    googleAdManager: {
      campaignId: writeToken(config.googleAdManager?.campaignId),
    },
    googleTagManager: {
      containerId: writeToken(config.googleTagManager?.containerId),
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: writeToken(config.oneTrust?.cookieCompliance?.consentCategoryBranch),
        consentCategoryBraze: writeToken(config.oneTrust?.cookieCompliance?.consentCategoryBraze),
        consentCategoryMParticle: writeToken(
          config.oneTrust?.cookieCompliance?.consentCategoryMParticle
        ),
        globalConsentState: writeToken(config.oneTrust?.cookieCompliance?.globalConsentState),
        scriptUrl: writeToken(config.oneTrust?.cookieCompliance?.scriptUrl),
        scriptId: writeToken(config.oneTrust?.cookieCompliance?.scriptId),
      },
    },
    pypestream: {
      scriptUrl: writeToken(config.pypestream?.scriptUrl),
      appId: writeToken(config.pypestream?.appId),
    },
    restaurants: {
      displayEnv: writeToken(config.restaurants?.displayEnv),
      validMobileOrderingEnvs: writeToken(config.restaurants?.validMobileOrderingEnvs, []),
    },
    urls: {
      braze: writeToken(config.urls?.braze),
      firstData: writeToken(config.urls?.firstData),
      firstDataTh: writeToken(config.urls?.firstDataTh),
      webBaseUrl: writeToken(config.urls?.webBaseUrl),
      thDigital: writeToken(config.urls?.thDigital),
    },
    tokenEx: {
      publicKey: writeToken(config.tokenEx?.publicKey),
    },
    orbital: {
      baseUrl: writeToken(config.orbital?.baseUrl),
      subscriberId: writeToken(config.orbital?.subscriberId),
      pieFormat: writeToken(config.orbital?.pieFormat),
      mode: writeToken(config.orbital?.mode),
    },
    firstData: {
      corporateStoreId: writeToken(config.firstData?.corporateStoreId),
      braintreeAuthorizationKey: writeToken(config.firstData?.braintreeAuthorizationKey),
      braintreeBillingAgreementDescription: writeToken(config.firstData?.braintreeBillingAgreementDescription),
      cashAppMerchantId: writeToken(config.firstData?.cashAppMerchantId),
    },
    timeFormat: writeToken(config.timeFormat),
    dateFormat: writeToken(config.dateFormat),
    appStoreDeeplink: writeToken(config.appStoreDeeplink),
  };

  console.log(CYAN, '[RN Sanity Prebuild] Created Application Config!');
  return structuredData;
}
