import { Box, Header, Icon } from '@rbilabs/universal-components';

import { theme } from 'styles/configure-theme';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

export const StyledBackIcon = Icon.withConfig({
  color: theme.token('icon-header-contrast'),
  size: '5',
  ...hiddenAccessibilityPlatformProps,
});

export const PanelContainer = Box.withConfig({
  safeAreaTop: true,
  position: 'relative',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: {
    base: 'flex-start',
    md: 'flex-end',
  },
  marginTop: '$5',
  _web: { marginTop: '$4' },
  paddingX: '$4',
});

export const StyledHeader = Header.withConfig({
  position: 'absolute',
  textAlign: 'center',
  left: 0,
  right: 0,
  margin: 0,
  bottom: 0,
  variant: 'headerTwo',
});
