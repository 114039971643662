import React, { ReactNode, useCallback, useEffect, useState } from 'react';

import { Pressable } from '@rbilabs/universal-components';
import { Dimensions, Modal, TouchableOpacity, View } from 'react-native';
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { primitive } from 'styles/constants/primitives';

import { PanelContainer, StyledBackIcon, StyledHeader } from './styled';

const { width } = Dimensions.get('window');

const DESKTOP_WIDTH = 560;
const ANIMATION_DURATION = 300;

interface SideActionPanelProps {
  title: string;
  open: boolean;
  onClose(): void;
  children: ReactNode;
}

export const SideActionPanel = ({
  title,
  open = false,
  onClose,
  children,
}: SideActionPanelProps) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const isMobile = useIsMobileBreakpoint();
  const [visible, setVisible] = useState(false);
  const translateX = useSharedValue(isMobile ? width : DESKTOP_WIDTH);

  const openPanel = useCallback(() => {
    setVisible(true);
    translateX.value = withTiming(0, { duration: ANIMATION_DURATION });
  }, [translateX]);

  const closePanel = useCallback(() => {
    translateX.value = withTiming(
      isMobile ? width : DESKTOP_WIDTH,
      { duration: ANIMATION_DURATION },
      () => {
        // Close modal on animation ends
        runOnJS(setVisible)(false);
        runOnJS(onClose)();
      }
    );
  }, [translateX, isMobile, onClose]);

  const animatedStyle = useAnimatedStyle(() => ({
    transform: [{ translateX: translateX.value }],
    width: isMobile ? '100%' : DESKTOP_WIDTH,
  }));

  useEffect(() => {
    // Prevent Panel to be closed on mount
    if (isFirstRender && open) {
      setIsFirstRender(false);
      return;
    } else if (isFirstRender) {
      return;
    }

    if (open) {
      openPanel();
    } else {
      closePanel();
    }
  }, [closePanel, isFirstRender, open, openPanel]);

  return (
    <Modal transparent visible={visible} animationType="none">
      <View style={{ flex: 1, flexDirection: isMobile ? 'column' : 'row-reverse' }}>
        {/* Clickable area outside the panel (web only) */}
        {!isMobile && (
          <TouchableOpacity
            style={{ flex: 1, backgroundColor: primitive.$blackOpacity50 }}
            onPress={onClose}
          />
        )}

        <Animated.View
          style={[
            {
              position: 'absolute',
              top: 0,
              bottom: 0,
              width: isMobile ? '100%' : DESKTOP_WIDTH,
              right: isMobile ? 'auto' : 0,
              backgroundColor: primitive.bk.$mayoEggWhite,
            },
            animatedStyle,
          ]}
        >
          <PanelContainer>
            <StyledHeader>{title}</StyledHeader>
            <Pressable onPress={onClose}>
              {isMobile ? <StyledBackIcon variant="back" /> : <StyledBackIcon variant="cancel" />}
            </Pressable>
          </PanelContainer>
          {children}
        </Animated.View>
      </View>
    </Modal>
  );
};
