import React from 'react';

import { Button, makeUclComponent } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import Dialog from 'components/dialog';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useCart } from 'hooks/use-cart';
import { useOrderContext } from 'state/order';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

export interface ICurbsideConfirmationModalProps {
  showDialog: boolean;
  onDismiss: () => void;
}

const StyledButton = makeUclComponent(ActionButton).withConfig({
  marginBottom: '3',
});

export const CurbsideConfirmationModal: React.FC<React.PropsWithChildren<
  ICurbsideConfirmationModalProps
>> = ({ showDialog, onDismiss }) => {
  const { formatMessage } = useIntl();
  const {
    setCurbsidePickupOrderId,
    setCurbsidePickupOrderTimePlaced,
    cancelCurbsideOrderCallbackUrl,
  } = useOrderContext();
  const { emptyCart } = useCart();
  const { navigate } = useNavigation();
  const { curbsidePickupOrderId } = useOrderContext();
  const { noStoreSelected } = useStoreContext();

  const cancelCurbsideOrder = () => {
    emptyCart();
    setCurbsidePickupOrderId('');
    setCurbsidePickupOrderTimePlaced('');
    onDismiss();
    if (noStoreSelected) {
      navigate(routes.storeLocator);
    } else if (cancelCurbsideOrderCallbackUrl) {
      navigate(cancelCurbsideOrderCallbackUrl);
    }
  };

  return (
    <Dialog
      showDialog={showDialog}
      testID="curbside-confirmation-modal"
      onDismiss={onDismiss}
      modalAppearanceEventMessage="Curbside Confirmation Modal"
      heading={formatMessage({ id: 'cancelCurbsideOrderTitle' })}
      body={formatMessage({ id: 'cancelCurbsideOrderText' })}
      actions={
        <Button.Group>
          <StyledButton
            fullWidth
            onPress={() => {
              navigate(`${routes.orderConfirmation}/${curbsidePickupOrderId}?showToast=true`);
              onDismiss();
            }}
            testID="keep-curbside-order"
          >
            {formatMessage({ id: 'keepCurbsideOrder' })}
          </StyledButton>
          <StyledButton
            variant={ActionButtonVariants.OUTLINE}
            fullWidth
            onPress={cancelCurbsideOrder}
            testID="cancel-curbside-order"
          >
            {formatMessage({ id: 'cancelCurbsideOrder' })}
          </StyledButton>
        </Button.Group>
      }
    />
  );
};
