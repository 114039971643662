import {
  isPayPalSupported,
  isVenmoSupported,
} from 'components/payments/integrations/firstdata/braintree.config';
import { AllLaunchDarklyFlags } from 'state/launchdarkly/flags';
import { region } from 'utils/environment';
import { Region } from 'utils/environment/types';

// these defaults were retrieved from launchdarkly PROD mobile on 2/06/2024
// they are only used if all else fails (LD call fails and nothing is cached)
// The below type enforces that when we add a flag a default is set

const safeFlagDefaults: Omit<
  AllLaunchDarklyFlags,
  | 'enable-test-google-pay-web'
  | 'enable-test-apple-pay-web'
  | 'enable-vault-after-making-one-time-payment'
> = {
  // TODO: these flags below should be removed from LD, they do not appear to be used by our codebase anymore
  // 'chef-recommendation-engine-2': 'SANITY',
  // chefUpsellItemCount: 2,
  // 'deprecate-chef': true,

  // 2/6/2024 new unknown, unused flags from backend:
  // "enable-feature-nutrition-integration": false,
  // 'enable-premium-combo-slots': 'none',
  // "enable-store-locator-refactored": false,
  // "nutritionEuDisplay": false,

  // TODO: these flags aren't returned by LD  but required by the enum
  // should we remove or is it part of payments refactor maybe?

  // the rest of the flags...
  'enable-ads-personalization-opt-out': false,
  'enable-paypal-payments': isPayPalSupported(),
  'enable-venmo-payments': isVenmoSupported(),
  'enable-cash-app-payments': false,
  'show-ordering-issue-info': true,
  'show-increased-delivery-fees-modal': false,
  tipPercentThresholdCents: 1500,
  enableFavoriteStores: true,
  enableSignUp: true,
  'send-postal-code-only-for-first-data-payment': false,
  enableDeliveryCancellationPolicy: true,
  enableOffers: true,
  'enable-cash-payment': false,
  'skip-loyalty-store-validation': false,
  'enable-delivery-checkout-outside-opening-hours': false,
  enableApplePay: true,
  enableAutoSelectRestaurant: true,
  'enable-sort-offers-for-service-mode': true,
  'enable-delivery-details-form-require-address-2': false,
  'enable-top-service-mode': true,
  enableInternalDiagnosticTools: false,
  disableDriveThru: false,
  'use-high-volume-support-msg': false,
  disableTakeOut: false,
  'enable-feature-onboarding': false,
  'enable-loyalty-offers-filters': false,
  autoFillTestCard: false,
  enableOrdering: true,
  'hide-tip-amount-checkout': false,
  'enable-alpha-beta-store-ordering': false,
  'enable-reward-page-stand-alone': false,
  'order-locator-tabs': { '0': 'nearby', '1': 'favorite', '2': 'recent' },
  'enable-find-restaurant-link': true,
  'enable-loyalty-fe': true,
  'enable-nutrition-explorer-allergens': false,
  'enable-add-extras': true,
  'enable-zero-bounce-error-modal': true,
  'enable-locator-clear-store-and-sm-button': false,
  'enable-my-code-redesign': false,
  'reset-cart-timeout-hrs': 48,
  enableAutoSignInOnSignUp: true,
  'send-same-otp-code-once': true,
  'tiered-delivery-fees': {
    '0': {
      googleFoodOrdering: { fee: 100, discount: 0, smallCartFee: 250, serviceFeePercent: 15 },
      app: { discount: 0, serviceFeePercent: 15, smallCartFee: 250, fee: 100 },
      web: { discount: 0, fee: 100, smallCartFee: 250, serviceFeePercent: 15 },
      kiosk: { discount: 0, fee: 0, serviceFeePercent: 0, smallCartFee: 0 },
    },
    '1000': {
      app: { fee: 100, discount: 0, smallCartFee: 0, serviceFeePercent: 15 },
      web: { fee: 100, discount: 0, smallCartFee: 0, serviceFeePercent: 15 },
      kiosk: { discount: 0, fee: 0, serviceFeePercent: 0, smallCartFee: 0 },
      googleFoodOrdering: { discount: 0, fee: 100, serviceFeePercent: 15, smallCartFee: 0 },
    },
  },
  'show-loyalty-banner-v1': true,
  'fe-personalized-offers-template': true,
  'enable-delivery-only': false,
  enableStoreConfirmationModal: true,
  'enable-static-in-store-identification': false,
  'enable-loyalty-points-in-header': false,
  'enable-payment-error-continue': true,
  'supported-card-brands-variations': ['amex', 'diners', 'discover', 'jcb', 'mc', 'visa'],
  'enable-menu-reordering-availability-override': [],
  'enable-closing-time-dialog': false,
  'store-card-button-options': { '0': 'info', '1': 'order', '2': 'offers' },
  'enable-loyalty-promo-codes': true,
  'enable-pypestream': region !== Region.CA,
  'enable-store-menu-pricing': false,
  'enable-dayparts-per-store': false,
  'enable-street-number-after-street-name': false,
  fireOrderAhead: 240,
  'enable-loyalty-standard-offers': true,
  'enable-discounts-on-order-receipt': false,
  enableFeatureHomePage: true,
  'enable-order-number-generation': false,
  'enable-rewards-static-page': false,
  'timed-fire-options': { generateOptions: null },
  'radius-for-store-locator': 32000,
  'enable-global-offers-cooldown': true,
  enableDeliveryWatchDriverLocation: false,
  'enable-edit-offers-cart-button': false,
  'enable-my-code-payment-method': false,
  'hide-items-from-nutrition-info': {
    proteins: false,
    cholesterol: false,
    calories: false,
    saltPer100: false,
    energyKJPer100: false,
    saturatedFat: false,
    carbohydrates: false,
    sodium: false,
    fatPer100: false,
    caloriesPer100: false,
    sugarPer100: false,
    sugar: false,
    carbohydratesPer100: false,
    proteinsPer100: false,
    fat: false,
    salt: false,
    saturatedFatPer100: false,
    transFat: false,
    fiber: false,
    weight: true,
    energyKJ: false,
  },
  enableAccountDeleteOneTrust: true,
  enableFreeDeliveryCheckoutProgressBar: false,
  'enable-reorder': true,
  'force-ota-app-update': 'none',
  enableGooglePay: true,
  'enable-qr-code-loyalty-id': false,
  'enable-account-in-footer': true,
  enableCustomBirthdayInput: true,
  defaultServiceModeInStore: 'DISABLED',
  'enable-forced-native-app-update': 'none',
  enableAutoLocale: false,
  'payment-fields-variations': {
    zip: true,
    state: false,
    country: false,
    name: true,
    addressLine1: false,
    city: false,
    expiration: true,
    cardNumber: true,
    cvv: true,
    addressLine2: false,
  },
  'enable-fees-on-order-receipt': false,
  curbsideVehicleDescriptionMaxLength: 17,
  'enable-redeem-in-restaurant-primary': false,
  'loyalty-in-restaurant-status-poll-interval-ms': 3000,
  resetStoreResetCartTimeout: 0,
  'enable-in-restaurant-variations': { enableRewardRedemption: true, enableOfferRedemption: true },
  'force-restaurant-selection-for-rewards': true,
  enableCurbside: true,
  deliveryRadiusInMiles: 6,
  delivery: true,
  'enable-loyalty-offers': true,
  'enable-price-mismatch-internal-only': false,
  'sign-up-fields-variations': {
    email: true,
    dob: true,
    zip: false,
    country: false,
    phone: false,
    name: true,
    wantsPromotionalEmails: true,
    agreesToTermsOfService: true,
    gender: false,
  },
  'enable-signup-cart-button-text': false,
  'enable-forter-fraud': true,
  'enable-offers-static-page': false,
  checkoutDeliveryMinimumOverrideCents: 500,
  'enable-offers-edit-cart': false,
  'enable-offer-redemption-methods': 'all',
  'enable-claim-loyalty-points': true,
  enableCognitoSignupInBe: true,
  'enable-loyalty-offers-v2-fe': false,
  'enable-prepaid-payment-methods': {
    ONE_TIME_METHOD: true,
    GOOGLE_PAY: true,
    EXISTING_METHOD: true,
    CREDIT: true,
    APPLE_PAY: true,
  },
  'enable-quest-tiles': true,
  'enable-move-signup-after-cart': false,
  disableDineIn: false,
  'gift-card-payment-method': true,
  'enable-cooldown-toast-info': false,
  enableAllergensDisclaimer: false,
  'enable-main-fab-button': true,
  'enable-foodware-items': false,
  'max-rbi-api-retries': 5,
  'max-sanity-api-retries': 10,
  'enable-fast-lane-drive-thru': false,
  'enable-optional-amplitude-tracking': false,
  'enable-debug-menu': false,
  'product-details-force-picker-image': false,
  'enable-mobile-order-drive-thru-lane': false,
  'enable-sms-marketing-subscriptions': false,
  'enable-unlink-walmart-flow': false,
  'enable-apple-pay-web': false,
  'enable-id-and-pay-fe': false,
  'enable-social-login-google': true,
  'enable-social-login-apple': true,
  'enable-social-login-facebook': true,
  'enable-checkout-animation': false,
  'enable-top-order-status-bar': false,
  'order-injection-flow': 'INJECT_NOW',
  'flybuy-variations': 'disabled',
  'enable-foundation-donations': false,
  'enable-walmart-plus-integration': false,
  'enabled-otp-login': true,
  'delivery-fees-stripe': 'disabled',
  'user-away-from-store': 0,
  'enable-non-traditional-store-ui': false,
  'enable-order-bay': false,
  'enable-ios-live-activity': false,
  'enable-cart-cancellation-policy': false,
  'enable-walmart-plus-trial-offer': false,
  'enable-auto-select-closest-store-prompt': false,
  'enable-google-pay-web': false,
  'enable-account-new-badge': '',
  'top-order-status-bar-time-out': 120,
  'auto-select-store-prompt-timeout': 0,
  'enable-webview-debugging': false,
  'enable-one-menu': false,
  'enable-mobile-drive-thru-service-mode': false,
  'enable-smart-alerts': false,
};

export default safeFlagDefaults;
