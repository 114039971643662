import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/global-state';

export const selectContentCards = ({ loyalty }: RootState) => loyalty.contentCards.contentCards;

export const selectNewsFeedContentCards = ({ loyalty }: RootState) =>
  loyalty.contentCards.newsFeedContentCards;

export const selectHomePageFeedContentCards = ({ loyalty }: RootState) =>
  loyalty.contentCards.homePageFeedContentCards;

export const selectUnreadContentCards = ({ loyalty }: RootState) =>
  loyalty.contentCards.unreadContentCards;

export const selectWebContentCards = ({ loyalty }: RootState) =>
  loyalty.contentCards.webContentCards;

export const selectOpenInbox = ({ loyalty }: RootState) => loyalty.contentCards.openInbox;

export const selectBrazeContentCards = createSelector(
  selectContentCards,
  selectNewsFeedContentCards,
  selectHomePageFeedContentCards,
  selectUnreadContentCards,
  selectWebContentCards,
  selectOpenInbox,
  (
    contentCards,
    newsFeedContentCards,
    homePageFeedContentCards,
    webContentCards,
    unreadContentCards,
    openInbox
  ) => {
    return {
      contentCards: contentCards || [],
      newsFeedContentCards: newsFeedContentCards || [],
      homePageFeedContentCards: homePageFeedContentCards || [],
      webContentCards: webContentCards || [],
      unreadContentCards,
      openInbox,
    };
  }
);
