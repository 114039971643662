import { useCallback, useEffect, useState } from 'react';

import { useToast } from '@rbilabs/universal-components';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { omit, pick } from 'lodash-es';
import { useIntl } from 'react-intl';

import { IUserFormData, IUserInfoSubmitData } from 'components/user-info-form/types';
import {
  GetMeDocument,
  IUpdateUserDetailsInput,
  IUserDetailsFragment,
  useGetMeQuery,
  useUpdateMeMutation,
} from 'generated/graphql-gateway';
import {
  ICommunicationPreference,
  IDeliveryAddress,
  IFavoriteStore,
} from 'generated/graphql-gateway';
import useEffectOnUpdates from 'hooks/use-effect-on-updates';
import { ModalCb } from 'hooks/use-error-modal';
import { usePrevious } from 'hooks/use-previous';
import { checkForUnexpectedSignOut, getCurrentSession } from 'remote/auth';
import { updateUserAttributes as cognitoUpdateUserAttributes } from 'remote/auth/cognito';
import {
  CustomEventNames,
  EventTypes,
  logEvent,
  updateAmplitudeUserAttributes,
} from 'state/amplitude';
import { UpdateUserInfoOptions } from 'state/auth/types';
import { useLDContext } from 'state/launchdarkly';
import { useNetworkContext } from 'state/network';
import { getName } from 'utils/attributes';
import AuthStorage from 'utils/cognito/storage';
import crashlytics from 'utils/crashlytics';
import { EventName, addEventListener, removeEventListener } from 'utils/event-hub';
import { StorageKeys } from 'utils/local-storage';
import logger, { addLoggingContext } from 'utils/logger';
import { useMemoAll } from 'utils/use-memo-all';

import { CommunicationPreferences, FavoriteStores, UserDetails } from './types';
import { useThirdPartyAuthentication } from './use-third-party-authentication';

export * from './types';

export interface IUseCurrentUser {
  openErrorDialog: ModalCb;
}

const configUserDetails = (details: IUserDetailsFragment) => ({
  dob: details.dob || '',
  email: details.email || '',
  emailVerified: details.emailVerified as boolean,
  name: details.name || '',
  phoneNumber: details.phoneNumber || '',
  phoneVerified: details.phoneVerified as boolean,
  promotionalEmails: details.promotionalEmails as boolean,
  isoCountryCode: details.isoCountryCode || '',
  zipcode: details.zipcode || '',
  defaultReloadAmt: details.defaultReloadAmt as number,
  defaultAccountIdentifier: details.defaultAccountIdentifier || '',
  defaultFdAccountId: details.defaultFdAccountId || '',
  defaultPaymentAccountId: details.defaultPaymentAccountId || '',
  autoReloadEnabled: details.autoReloadEnabled as boolean,
  autoReloadThreshold: details.autoReloadThreshold as number,
  loyaltyTier: details.loyaltyTier,
  communicationPreferences: (details.communicationPreferences as CommunicationPreferences) || null,
  favoriteStores: (details.favoriteStores as FavoriteStores) || null,
  deliveryAddresses: (details.deliveryAddresses as Array<IDeliveryAddress>) || null,
});

export const useCurrentUser = ({ openErrorDialog }: IUseCurrentUser) => {
  const toast = useToast();
  const { formatMessage } = useIntl();
  const { updateLDUser } = useLDContext();
  const { isNetworkConnected } = useNetworkContext();
  const { logUserInToThirdPartyServices } = useThirdPartyAuthentication();
  const [currentUserSession, setCurrentUserSession] = useState<CognitoUserSession | null>(null);

  const { data: userData, loading, refetch, called } = useGetMeQuery({
    skip: !AuthStorage.getItem(StorageKeys.USER_AUTH_TOKEN),
    // Apollo client loading state gets stuck: https://github.com/apollographql/react-apollo/issues/3425
    // Temporary fix while we wait for a stable 3.0.0 version
    fetchPolicy: 'cache-and-network',
  });

  // NOTE: The updateMeMutation will attempt to refetch and update userData from useGetMeQuery
  // The refetchQueries query needs to match the useGetMeQuery exactly, including the variables
  const [updateMeMutation, { loading: useUpdateMeMutationLoading }] = useUpdateMeMutation({
    refetchQueries: [{ query: GetMeDocument }],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    getCurrentSession().then(user => {
      AuthStorage.setItem(StorageKeys.USER_AUTH_TOKEN, user?.getAccessToken().payload.username);
      setCurrentUserSession(user);
    });

    const listener = (newCurrentUser: CognitoUserSession) => setCurrentUserSession(newCurrentUser);
    addEventListener(EventName.SET_CURRENT_USER, listener);

    return () => removeEventListener(EventName.SET_CURRENT_USER, listener);
  }, []);

  const prevUserData = usePrevious(userData);

  // || null below to avoid big context switch during loading where .me is "undefined" instead of null
  const user = currentUserSession ? userData?.me || null : null;
  const cognitoId = user?.cognitoId ?? AuthStorage.getItem(StorageKeys.USER_AUTH_TOKEN) ?? null;

  const setCurrentUser = useCallback(
    async (session: null | CognitoUserSession) => {
      if (session && called) {
        await refetch();
      }
      setCurrentUserSession(session);
    },
    [called, refetch]
  );

  const refetchCurrentUser = useCallback(async () => {
    refetch();
  }, [refetch]);

  const refreshCurrentUser = useCallback(async () => {
    try {
      AuthStorage.setItem(
        StorageKeys.USER_AUTH_TOKEN,
        currentUserSession?.getAccessToken().payload.username
      );
      await setCurrentUser(currentUserSession);
    } catch (error) {
      logger.error({ error, message: 'An error occurred while refreshing the current user.' });
      openErrorDialog({
        // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
        error,
        message: formatMessage({ id: 'authRetryError' }),
        modalAppearanceEventMessage: 'Error: Setting Current User Error',
      });
    }
  }, [currentUserSession, formatMessage, openErrorDialog, setCurrentUser]);

  const refreshCurrentUserWithNewSession = useCallback(async () => {
    try {
      const session = await getCurrentSession();
      AuthStorage.setItem(StorageKeys.USER_AUTH_TOKEN, session?.getAccessToken().payload.username);
      setCurrentUserSession(session);
      await setCurrentUser(currentUserSession);
    } catch (error) {
      logger.error({
        error,
        message: 'An error occurred while refreshing the current user with new session.',
      });
      openErrorDialog({
        // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
        error,
        message: formatMessage({ id: 'authRetryError' }),
        modalAppearanceEventMessage: 'Error: Setting Current User Error With New Session',
      });
    }
  }, [currentUserSession, formatMessage, openErrorDialog, setCurrentUser]);

  const updateUserInfo = useCallback(
    async (form: IUserInfoSubmitData, options?: UpdateUserInfoOptions) => {
      try {
        // QUESTION - why do we await the current session here if we don't do anything with it?
        await getCurrentSession();
        cognitoUpdateUserAttributes(pick(form, 'phoneNumber', 'dob', 'name'));
        updateAmplitudeUserAttributes(form);
        updateLDUser({
          key: user?.cognitoId,
          ...getName({ name: form.name }, { firstName: '', lastName: '' }),
          name: form.name,
          email: ((form as unknown) as IUserFormData).email,
        });

        const updateMeParams = omit(
          form,
          'agreesToTermsOfService',
          'defaultCheckoutPaymentMethodId',
          'defaultReloadPaymentMethodId',
          'deliveryAddresses',
          'email',
          'emailVerified',
          'phoneVerified'
        );

        const input = {
          ...updateMeParams,
          defaultAccountIdentifier: form.defaultCheckoutPaymentMethodId,
          defaultPaymentAccountId: form.defaultReloadPaymentMethodId,
        };

        await updateMeMutation({ variables: { input } });
      } catch (error) {
        if (!options) {
          return;
        }

        const errorMessage = 'Error: Update User Info Failure';

        if (!options.shouldMuteUserInfoErrors) {
          logger.error({ error, message: errorMessage });
          toast.show({
            text: formatMessage({ id: 'updateInfoError' }),
            variant: 'negative',
          });
        } else {
          logger.error({
            error,
            message: `${errorMessage} - Muted`,
          });
        }
        if (options.shouldThrowException) {
          throw new Error(errorMessage);
        }
      }
    },
    [updateLDUser, user?.cognitoId, updateMeMutation, toast, formatMessage]
  );

  const updateUserCommPrefs = useCallback(
    async (
      communicationPreferences: Array<ICommunicationPreference>,
      options?: { shouldThrowException?: boolean }
    ) => {
      const shouldThrowException = options?.shouldThrowException ?? false;
      try {
        const promotionalEmailsInput =
          (communicationPreferences.length && {
            promotionalEmails: communicationPreferences.some(({ value }) => value === 'true'),
          }) ||
          {};
        const input = {
          communicationPreferences,
          ...promotionalEmailsInput,
        };
        const { data } = await updateMeMutation({ variables: { input } });

        if (!data) {
          return logger.error({ message: 'An error occurred updating communication preference' });
        }

        const details = configUserDetails(data.updateMe.details);
        updateAmplitudeUserAttributes(details);
      } catch (error) {
        const errorMessage = 'Error: Update User Communication Preferences Failure';
        logger.error({ error, message: errorMessage });
        toast.show({
          text: formatMessage({ id: 'updateInfoError' }),
          variant: 'negative',
        });
        if (shouldThrowException) {
          throw new Error(errorMessage);
        }
      }
    },
    [updateMeMutation, toast, formatMessage]
  );

  const updateUserFavStores = useCallback(
    async (favoriteStores: Array<IFavoriteStore>) => {
      try {
        const input = { favoriteStores } as IUpdateUserDetailsInput;
        const { data } = await updateMeMutation({ variables: { input } });
        if (!data) {
          logger.error({ message: 'An error occurred updating favorite store' });
          toast.show({
            text: formatMessage({ id: 'updateInfoError' }),
            variant: 'negative',
          });
        }
      } catch (error) {
        logger.error({ message: `An error occurred updating favorite store: ${error}` });
        toast.show({
          text: formatMessage({ id: 'updateInfoError' }),
          variant: 'negative',
        });
      }
    },
    [toast, formatMessage, updateMeMutation]
  );

  const checkIfUnexpectedSignOut = useCallback(async () => {
    const errorUnexpectedSignOut = await checkForUnexpectedSignOut();
    if (errorUnexpectedSignOut) {
      const isRefreshTokenExpired = /Refresh Token has expired/i.test(
        errorUnexpectedSignOut.message
      );

      logger.error({
        message: isRefreshTokenExpired ? 'Refresh token expired' : 'Unexpected Sign out',
        error: errorUnexpectedSignOut,
      });
      // sent amplitude event telling that there has been an unexpected sign out
      logEvent(
        isRefreshTokenExpired
          ? CustomEventNames.REFRESH_TOKEN_EXPIRED
          : CustomEventNames.UNEXPECTED_SIGN_OUTS,
        EventTypes.Other,
        {
          cognitoId: prevUserData?.me?.cognitoId,
          error: errorUnexpectedSignOut,
        }
      );
    }
  }, [prevUserData]);

  useEffect(() => {
    // decorate logger with cognito id
    addLoggingContext({ userId: cognitoId });
    // Add cognito id to crashlytics
    if (cognitoId) {
      crashlytics().setUserId(cognitoId);
    }
  }, [cognitoId]);

  useEffect(() => {
    if (!user) {
      checkIfUnexpectedSignOut();
    }
    // No longer needed... Use USER_AUTH_TOKEN for performance reasons
    // TODO: RN - Cleanup once we are no longer supporting capacitor
    AuthStorage.removeItem(StorageKeys.USER);
  }, [checkIfUnexpectedSignOut, prevUserData, user]);

  useEffectOnUpdates(() => {
    // any update fetch + set current user
    if (isNetworkConnected) {
      refreshCurrentUser();
    }
  }, [isNetworkConnected]);

  // when user data populates for the first time, it means the user got signed in, therefore we should sign them into all third party services as well
  useEffect(() => {
    if (userData && !prevUserData) {
      logUserInToThirdPartyServices((userData.me as unknown) as UserDetails);
    }
  }, [userData, prevUserData, logUserInToThirdPartyServices]);

  const isAuthenticated = !!currentUserSession;
  const userLoading = loading;

  const result = useMemoAll({
    refetchCurrentUser,
    refreshCurrentUser,
    refreshCurrentUserWithNewSession,
    setCurrentUser,
    updateUserCommPrefs,
    updateUserFavStores,
    updateUserInfo,
    user,
    isAuthenticated,
    currentUserSession,
    setCurrentUserSession,
    userLoading,
    useUpdateMeMutationLoading,
  });

  return result;
};
