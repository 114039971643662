import { Icon } from '@rbilabs/universal-components';

import Picture from 'components/picture';

import { ToastTemplateContainer } from '../../styled';
import { IMenuToastTemplate } from '../../types';

import {
  AddToCartContainer,
  IconContainer,
  PictureContainer,
  TextMessage,
  TextMessageContainer,
} from './styled';

export const AddToCartTemplate = ({ toast }: IMenuToastTemplate) => {
  const image = toast.data.image || null;
  return (
    <ToastTemplateContainer>
      <AddToCartContainer>
        {image && (
          <PictureContainer>
            <Picture alt={toast.message.toString()} height="$12" width="$12" image={image} />
            <IconContainer>
              <Icon variant="checkMarkGreen" size="$6" />
            </IconContainer>
          </PictureContainer>
        )}
        <TextMessageContainer>
          <TextMessage>{toast.message}</TextMessage>
        </TextMessageContainer>
      </AddToCartContainer>
    </ToastTemplateContainer>
  );
};
