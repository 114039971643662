import { Loggable } from '@rbi-ctg/frontend';
import { LogLevel } from 'utils/logger';

export const UNKNOWN_ERROR_MESSAGE = 'Unknown application error. Unable to parse error message.';

interface Context {
  [x: string]: Loggable | Loggable[];
}

export interface IDatadogLogger {
  context?: Context;
  message: Loggable;
  status: LogLevel;
}
