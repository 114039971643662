import { Svg, SvgImage, SvgProps, registerSVG } from '../../../components/svg';

export function GPayMark(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 -25 120 120">
      <SvgImage href={require('./GPayMark.png')} />
    </Svg>
  );
}

registerSVG('GPayMark', GPayMark);
