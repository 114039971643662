import React from 'react';

import { Header, ScrollView } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import Modal, { ModalContent, ModalSize } from 'components/modal';
import { useFeatureDisclaimers } from 'hooks/use-feature-disclaimers';

import { ItemNutrition } from './item-nutrition';
import { NutritionModalDisclaimer } from './nutrition-modal-disclaimer';
import { INutritionProps } from './types';

const Nutrition: React.FC<React.PropsWithChildren<INutritionProps>> = ({
  nutritionFacts,
  onDismiss,
}) => {
  const { formatMessage } = useIntl();
  const { featureDisclaimers } = useFeatureDisclaimers();
  const { calorie } = featureDisclaimers || {};

  const modalHeading = formatMessage({ id: 'nutritionalInformation' });

  return (
    <Modal
      size={ModalSize.FULLSCREEN}
      onDismiss={onDismiss}
      amplitudeEventData={{
        modalAppearanceEventMessage: 'Nutritional Information',
      }}
      testID="nutrition-modal"
      backgroundColor={Styles.color.background}
    >
      <ModalContent alignItems="center">
        <ScrollView showsVerticalScrollIndicator={false} focusable>
          <Header variant="headerTwo" alignSelf="center" fontSize="xl" marginTop="$12" focusable>
            {modalHeading}
          </Header>
          {nutritionFacts
            .filter(
              elem =>
                elem.itemName?.toLowerCase() !== 'combo item' &&
                elem.itemName?.toLowerCase() !== 'mix n match'
            )
            .map(({ itemName, itemImage, facts, allergenGroups }) => (
              <ItemNutrition
                key={itemName}
                itemImage={itemImage}
                itemName={itemName}
                nutritionInfo={facts}
                allergenGroups={allergenGroups}
              />
            ))}
          <NutritionModalDisclaimer content={calorie?.localeRaw} />
        </ScrollView>
      </ModalContent>
    </Modal>
  );
};

export default Nutrition;
