import { ICombo, IComboSlot, IOffer, PickerSelection } from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';
import { AvailableRewards, IAppliedRewards } from 'state/loyalty/hooks/types';
import { LoyaltyOffer } from 'state/loyalty/types';
import { IPricingFunctionFn } from 'state/menu/types';
import { ProductMenuObject } from 'state/product-wizard/types';

export const computeComboSlotCalories = (comboSlot: IComboSlot) => {
  return (comboSlot.options || []).reduce((sum, comboSlotOption) => {
    const comboSlotOptionCalories = comboSlotOption.option?.nutritionWithModifiers?.calories || 0;
    const comboSlotOptionQuantity = comboSlotOption.defaultAmount ?? comboSlotOption.minAmount ?? 0;
    return sum + comboSlotOptionCalories * comboSlotOptionQuantity;
  }, 0);
};

const computeComboCalories = (combo: ICombo) => {
  let totalCalories = 0;

  (combo.options || []).forEach(comboSlot => {
    totalCalories += computeComboSlotCalories(comboSlot);
  });

  if (combo.mainItem) {
    totalCalories += combo.mainItem.nutritionWithModifiers?.calories || 0;
  }

  return totalCalories;
};

export const computeProductCalories = (selectedProduct: PickerSelection) => {
  if (selectedProduct._type === MenuObjectTypes.COMBO) {
    return computeComboCalories(selectedProduct);
  }
  return selectedProduct.nutritionWithModifiers?.calories || 0;
};

export const computeDiscountPrice = ({
  appliedLoyaltyRewards,
  availableLoyaltyRewardsMap,
  cartIdEditing,
  pricingFunction,
  productQuantity,
  rewardBenefitId,
  selectedProduct,
  menuObject,
  stagedCartPoints,
  enableCartV2,
  isFromCart,
}: {
  appliedLoyaltyRewards: IAppliedRewards;
  availableLoyaltyRewardsMap: AvailableRewards;
  cartIdEditing?: string;
  pricingFunction: IPricingFunctionFn;
  productQuantity: number;
  rewardBenefitId?: string;
  selectedProduct: PickerSelection;
  menuObject?: ProductMenuObject;
  stagedCartPoints?: number;
  enableCartV2?: boolean;
  isFromCart?: boolean;
}) => {
  // RewardBenefitId has values when coming from Rewards (is applying a single Reward).
  // CartIdEditing has values when editing a CartEntry. That CartEntry might have Rewards applied.
  // If both are empty, user is coming from Menu, and there is no discount to apply
  const isDiscountEnabledForCurrentRoute = rewardBenefitId || cartIdEditing;
  // If the item we are pricing is not applicable for Rewards, there is no discount to apply
  const reward =
    availableLoyaltyRewardsMap[selectedProduct._id] ||
    availableLoyaltyRewardsMap[menuObject?._id || ''];

  if (!isDiscountEnabledForCurrentRoute || !reward) {
    return 0;
  }

  const price = pricingFunction({ item: selectedProduct, quantity: productQuantity });
  const individualItemPrice = (price || 0) / productQuantity;
  let timesDiscountApplied = 1;
  if (cartIdEditing) {
    // User is editing a cartEntry. If the cart has rewards applied, discount them ...
    const timesRewardApplied = appliedLoyaltyRewards[cartIdEditing]?.timesApplied ?? 0;
    // ... but never exceeding the total quantity.
    // Exceeding rewards are removed when user ends the edition (see `unApplyExceedingRewards`).
    timesDiscountApplied = Math.min(productQuantity, timesRewardApplied);
  }

  if (enableCartV2) {
    let discountPrice = 0;
    const timesRewardApplied = cartIdEditing
      ? appliedLoyaltyRewards[cartIdEditing]?.timesApplied
      : 0;

    // Check if a discount should be applied based on the current context
    const shouldApplyDiscount = (isFromCart && timesRewardApplied > 0) || rewardBenefitId;

    if (!shouldApplyDiscount) {
      return discountPrice;
    }

    const { pointCost } = reward;
    const usedPointsInCart = timesRewardApplied * pointCost;

    const calculateTotalPointCost = (quantity: number) => pointCost * quantity - usedPointsInCart;

    const hasSufficientPoints = (quantity: number) =>
      (stagedCartPoints || 0) >= calculateTotalPointCost(quantity);

    // Function to calculate discount price.
    // Since we need to re-calculate the discount price when the user changes the quantity of the item,
    // we need to add the times the reward has been applied to the max redeemable items.
    const calculateDiscountPrice = (quantity: number, maxRedeemable: number) => {
      return individualItemPrice * Math.min(maxRedeemable + timesRewardApplied, quantity);
    };

    if (hasSufficientPoints(productQuantity)) {
      // If there are enough points, apply discount to all items
      discountPrice = individualItemPrice * productQuantity;
    } else {
      // Calculate the maximum redeemable items based on available points
      const maxRedeemableItems = Math.floor((stagedCartPoints || 0) / pointCost);
      // Apply discount only to the maximum redeemable items
      discountPrice = calculateDiscountPrice(productQuantity, maxRedeemableItems);
    }

    return discountPrice;
  }

  const discountPrice = individualItemPrice * timesDiscountApplied;
  return discountPrice;
};

export const getOfferVendorConfigForPricing = (offer: IOffer | LoyaltyOffer | null) => {
  return offer?.marketPrice?.vendorConfigs || offer?.vendorConfigs;
};
