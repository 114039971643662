import { useCallback } from 'react';

import { loginCRM, updateAmplitudeUserId } from 'state/amplitude';
import { logoutBranch, setBranchIdentity } from 'state/branch/utils';
import useBrazeContext from 'state/braze/hooks/use-braze-context';
import { useLDContext } from 'state/launchdarkly';
import Braze from 'utils/braze';

import { UserDetails } from './types';

export const useThirdPartyAuthentication = () => {
  const { setUserId: brazeSetUserId } = useBrazeContext();
  const { login: launchDarklyLogin, logout: launchDarklyLogout } = useLDContext();

  const logUserInToThirdPartyServices = useCallback(
    (currentUser: UserDetails) => {
      launchDarklyLogin(currentUser);

      loginCRM({
        customerid: currentUser.cognitoId,
        rbiCognitoId: currentUser.cognitoId,
        ...currentUser.details,
      });

      brazeSetUserId(
        currentUser.thLegacyCognitoId
          ? `us-east-1:${currentUser.thLegacyCognitoId}`
          : currentUser.cognitoId
      );

      setBranchIdentity(currentUser.cognitoId);

      // Update Braze Email for the user
      Braze.setEmail(currentUser.details.email);
    },
    [brazeSetUserId, launchDarklyLogin]
  );

  const logUserOutOfThirdPartyServices = useCallback(() => {
    launchDarklyLogout();
    updateAmplitudeUserId(null);
    brazeSetUserId(null);
    logoutBranch();
  }, [brazeSetUserId, launchDarklyLogout]);

  return { logUserInToThirdPartyServices, logUserOutOfThirdPartyServices };
};
