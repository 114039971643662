import {
  ICartEntry,
  ICombo,
  IComboSlot,
  IComboSlotSelections,
  IComboSlotSelectionsWithData,
  IItem,
  IItemOption,
  IItemOptionModifierWithQuantity,
  IModifierSelection,
  IPicker,
  IPickerOption,
  ISection,
  MenuObject,
  PickerSelection,
} from '@rbi-ctg/menu';
import { ComboUIPattern, MenuObjectTypes } from 'enums/menu';
import { CustomEventNames, EventTypes, logEvent } from 'state/amplitude';
import {
  CartEntryType,
  IComboCartEntryOptions,
  ICreateCartEntryOptions,
  MAX_CART_QUANTITY,
  MIN_CART_QUANTITY,
  createCartEntry,
} from 'utils/cart';
import logger from 'utils/logger';
import { computeSelectedOption, defaultPickerAspect, partitionModalState } from 'utils/menu';
import { getDefaultSelectionsForComboSlot } from 'utils/menu/get-default-selections-for-combo-slot';
import { isCombo, isItem, isPicker } from 'utils/menu/is-menu-type';

export interface IMenuObjectToCartEntry {
  mainEntry: ICartEntry | undefined;
  upsellEntries: ICartEntry[];
  modifiersInjected: IModifierSelection[];
}

export interface IModifierSelectionAction {
  comboSlotId?: string;
  itemId: string;
  option: IItemOption;
  modifier: IItemOptionModifierWithQuantity;
  item: IItem;
  fifoCounter?: number;
}
interface IBaseWizardState {
  modifierSelections: IModifierSelectionAction[];
}

interface IWizardItemState extends Partial<IBaseWizardState> {
  quantity: number;
}

interface IWizardComboState extends Partial<IWizardItemState> {
  comboSlotSelections: IComboSlotSelections;
  quantity: number;
}

interface IWizardPickerState extends Partial<IWizardComboState> {
  pickerSelection?: PickerSelection;
  pickerSelections: Record<string, string>;
  quantity: number;
}

export const convertModActionToSelection = (
  action: IModifierSelectionAction,
  options?: {
    fifoPositions?: number[];
    modifierOverride?: IModifierSelectionAction['modifier'];
    quantityOverride?: number;
  }
): IModifierSelection => ({
  comboSlotId: action.comboSlotId,
  itemId: action.itemId,
  _key: action.option._key,
  name: action.option.name,
  _type: action.option._type,
  upsellModifier: action.option.upsellModifier,
  componentStyle: action.option.componentStyle,
  allowMultipleSelections: action.option.allowMultipleSelections,
  injectDefaultSelection: action.option.injectDefaultSelection,
  modifier: {
    ...(options?.modifierOverride ?? action.modifier),
    quantity: (options?.quantityOverride ?? action.modifier.quantity) || 1,
  },
  fifoPositions: options?.fifoPositions ?? [],
});

// NOTE: Currently there is no api to forward options for each action
// for now call the single convert function above manually for each if you need to override
export const convertModActionsToSelections = (
  modifierActions: IModifierSelectionAction[]
): IModifierSelection[] => {
  return modifierActions.map(action => {
    return convertModActionToSelection(action);
  });
};

export const getSelectionsForComboSlot = (
  comboSlot: IComboSlot,
  selections?: ICartEntry[]
): IComboSlotSelectionsWithData => {
  const { options: comboSlotOptions = [], ...slotRest } = comboSlot;

  if (!comboSlotOptions.length) {
    return { data: slotRest, selections: [] };
  }

  const defaultComboSlotOptions = getDefaultSelectionsForComboSlot(comboSlotOptions);

  // if no user selections passed in use defaults
  if (!selections) {
    return { data: slotRest, selections: defaultComboSlotOptions };
  }

  const comboSlotSelectionIds = new Set(selections.map(comboSlotOption => comboSlotOption._id));

  // check if comboSlot has any userSelections
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  const userSelections = comboSlotOptions.reduce((acc, comboSlotOption) => {
    if (!comboSlotSelectionIds.has(comboSlotOption.option._id)) {
      return acc;
    }
    return [
      ...acc,
      {
        option: comboSlotOption,
        quantity:
          selections.find(selection => selection._id === comboSlotOption.option._id)?.quantity ?? 1,
      },
    ];
  }, []);

  return {
    data: slotRest,
    // fallback to defaults if no userSelections
    // @ts-expect-error TS(2322) FIXME: Type 'IComboSlotOption | IComboSlotSelection[]' is... Remove this comment to see the full error message
    selections: userSelections.length ? userSelections : defaultComboSlotOptions,
  };
};

export const getComboSlotSelections = (
  comboSlots: IComboSlot[],
  selections?: ICartEntry[]
): IComboSlotSelections => {
  const comboSlotSelections = {};
  comboSlots.forEach(comboSlot => {
    // check if selections has this comboSlot if it does use its selections
    const comboSlotCartEntries = selections?.find(entry => entry._id === comboSlot._id)?.children;
    comboSlotSelections[comboSlot._id] = getSelectionsForComboSlot(comboSlot, comboSlotCartEntries);
  });
  return comboSlotSelections;
};

/**
 * Finds all item options within a list of cart entries.
 *
 * @param {ICartEntry[]} cartEntries - The list of cart entries to search.
 * @param {ICartEntry[]} [itemOptions=[]] - Store found item options.
 * @returns {ICartEntry[]} An array containing all found item options.
 */
const findSelectionsItemOptions = (
  cartEntries: ICartEntry[],
  itemOptions: ICartEntry[] = []
): ICartEntry[] => {
  for (const cartEnrty of cartEntries) {
    if (cartEnrty.type === CartEntryType.itemOption) {
      itemOptions.push(cartEnrty);
    }
    if (cartEnrty.children && cartEnrty.children.length > 0) {
      findSelectionsItemOptions(cartEnrty.children, itemOptions);
    }
  }
  return itemOptions;
};

/**
 * Returns the default modifier selections based on the item and optionally an array of cartEntries
 *
 * @param {IItem} item The item to get the defaults from
 * @param {ICartEntry[]} [selections] cartEntries to get the users existing selections from (used by edit cart)
 * @param {string} [comboSlotId] the comboSlots Id that we should forward to the selection
 */
export const getItemModifiers = (
  item: IItem,
  selections?: ICartEntry[],
  comboSlotId?: string
): IModifierSelectionAction[] => {
  const modifierSelections: IModifierSelectionAction[] = [];
  if (item && item.options) {
    item.options.forEach(itemOption => {
      // This item option is misconfigured, must have at least one option
      if (!itemOption.options || !itemOption.options.length) {
        return;
      }

      const baseModifierSelection = {
        itemId: item._id,
        option: itemOption,
        item,
        ...(comboSlotId && { comboSlotId }),
      };

      // TODO: determine if we need to support multiple default modifiers for the same itemOption
      const defaultModifier = itemOption.options.find(option => option.default);

      const defaultModifierSelection = {
        ...baseModifierSelection,
        modifier: { ...defaultModifier, quantity: itemOption.maxAmount },
      } as IModifierSelectionAction;

      // are there any user selections? if not return early the defaults
      if (!selections?.length) {
        // unable to find any defaults there are no modifier selections
        if (!defaultModifier) {
          return;
        }

        modifierSelections.push(defaultModifierSelection);
        return;
      }

      const possibleModifierIds = new Set(itemOption.options.map(modifier => modifier._key));

      const selectionsItemOptions = findSelectionsItemOptions(selections);
      const matchingItemOption = selectionsItemOptions.find(
        selectionItemOption => selectionItemOption._id === itemOption._key
      );
      // The users selections don't include this itemOption just return the default for this itemOption
      if (!matchingItemOption) {
        modifierSelections.push(defaultModifierSelection);
        return;
      }

      const userSelectedModifierQuantityMap = matchingItemOption.children.reduce(
        (selectedModifiers, modifier) => {
          // This modifier that was selected is not a valid option don't include it
          if (!possibleModifierIds.has(modifier._id)) {
            return selectedModifiers;
          }
          return { ...selectedModifiers, [modifier._id]: modifier.quantity };
        },
        {}
      );
      const userModifierIds = Object.keys(userSelectedModifierQuantityMap);
      // Not all the users selections are valid use the default modifier for this itemOption
      if (userModifierIds.length !== matchingItemOption.children.length) {
        modifierSelections.push(defaultModifierSelection);
        return;
      }

      // We found the users modifiers go back to the data and get the ones we want (don't rely on the user selections for data)
      const resolvedModifiers = itemOption.options.filter(modifier =>
        userModifierIds.includes(modifier._key)
      );
      // Not able to resolve the selected modifier (should not hit this case but just in case)
      if (!resolvedModifiers.length) {
        modifierSelections.push(defaultModifierSelection);
        return;
      }

      resolvedModifiers.forEach(modifier => {
        modifierSelections.push({
          ...baseModifierSelection,
          modifier: {
            ...modifier,
            quantity: userSelectedModifierQuantityMap[modifier._key],
          },
        });
      });
    });
  }

  return modifierSelections;
};

const getItemSelections = (item: IItem, selections?: ICartEntry): IWizardItemState => {
  // Make sure the item is the same before using the selections
  const usersSelections = item._id === selections?._id ? selections?.children : [];
  const modifierSelections = getItemModifiers(item, usersSelections ?? []);

  return {
    quantity: escapeQuantity(selections?.quantity ?? 1),
    modifierSelections,
  };
};

const getComboSelections = (combo: ICombo, selections?: ICartEntry): IWizardComboState => {
  const modifiers: IModifierSelectionAction[] = [];
  const comboSlotCartEntries = selections?.children.filter(
    entry => entry.type === CartEntryType.comboSlot
  );
  const comboSlotSelections = getComboSlotSelections(combo.options, comboSlotCartEntries);
  const mainItemCartEntry = selections?.children.find(
    ({ _id, type, sanityId }) =>
      type !== CartEntryType.comboSlot &&
      (sanityId === combo.mainItem?._id || _id === combo.mainItem?._id)
  );
  const mainItemSelections =
    combo?.mainItem && getItemSelections(combo?.mainItem, mainItemCartEntry).modifierSelections;

  if (mainItemSelections && mainItemSelections.length) {
    modifiers.push(...mainItemSelections);
  }

  (combo.options || []).forEach(({ _id: comboSlotId, options: comboSlotOptions }) => {
    const currentComboSlotsSelectionEntries = comboSlotCartEntries?.find(
      entry => entry._id === comboSlotId
    )?.children;
    (comboSlotOptions || []).forEach(comboSlotOption => {
      const comboSlotOptionItem = comboSlotOption.option;
      if (comboSlotSelections[comboSlotId]) {
        const isSelected = comboSlotSelections[comboSlotId].selections.find(
          ({ option: { option: selectedOption } }) => comboSlotOptionItem === selectedOption
        );
        if (isSelected) {
          // get the selections that match this comboSlotOption
          const comboSlotOptionItemEntry = currentComboSlotsSelectionEntries?.find(
            entry => entry._id === comboSlotOptionItem._id
          );
          const selectionsForComboSlotOption = comboSlotOptionItemEntry?.children;

          modifiers.push(
            ...(comboSlotOptionItem._type === MenuObjectTypes.PICKER
              ? getPickerSelections(comboSlotOptionItem, comboSlotOptionItemEntry)
                  .modifierSelections ?? []
              : getItemModifiers(comboSlotOptionItem, selectionsForComboSlotOption, comboSlotId))
          );
        }
      }
    });
  });

  return {
    modifierSelections: modifiers,
    quantity: escapeQuantity(selections?.quantity ?? 1),
    comboSlotSelections,
  };
};

export const getPickerSelections = (
  picker: IPicker,
  selections?: ICartEntry,
  pickerSelectionOverrides?: ICartEntry['pickerSelections']
): IWizardPickerState => {
  let pickerSelections: IWizardPickerState['pickerSelections'] = {};
  let pickerSelection: IWizardPickerState['pickerSelection'];
  let selectedOption: IPickerOption | undefined;

  // We allow you to completely override the pickerSelections if needed to skip all this logic and just use the pickerSelections you pass in
  // This is used in wizard when you manually make a new pickerSelection
  // NOTE: if you override the pickerSelections it will always force the default selections for all options/modifiers
  if (!pickerSelectionOverrides) {
    // NOTE: We can't rely solely on the _id of the cartEntry as pickers can have multiple options with the same id
    // We are using the pickerSelections that are on the cartEntry instead to remap back to the correct option
    // narrow down to just the picker options the user selected
    const possibleOptions = picker.options.filter(({ option }) => option._id === selections?._id);
    // Always cross reference the pickerSelections against the found options mappings
    // Find the one that matched the pickerSelections
    selectedOption = possibleOptions.find(({ pickerItemMappings }) =>
      pickerItemMappings.every(
        mapping =>
          mapping.pickerAspectValueIdentifier ===
          selections?.pickerSelections?.[mapping.pickerAspect._id]
      )
    );
  }

  // unable to find the option previously selected or no previous selection, use the defaults
  if (!selectedOption) {
    pickerSelections = pickerSelectionOverrides ?? defaultPickerAspect(picker);
    pickerSelection = computeSelectedOption(
      pickerSelectionOverrides ?? pickerSelections,
      picker
    ) as typeof pickerSelection;
  } else {
    // We were able to resolve using the pickerSelections so it should be safe to just use them
    pickerSelections = selections?.pickerSelections ?? {};
    pickerSelection = selectedOption?.option as typeof pickerSelection;
  }

  return {
    pickerSelections,
    ...(pickerSelection && { pickerSelection }),
    // We need to be sure that if we are overriding the pickerSelections that we don't use any of the users selections
    // This is done purposefully, we want to reset all selections if you change a picker aspect
    ...(pickerSelection?._type === MenuObjectTypes.ITEM &&
      getItemSelections(pickerSelection, pickerSelectionOverrides ? undefined : selections)),
    ...(pickerSelection?._type === MenuObjectTypes.COMBO &&
      getComboSelections(pickerSelection, pickerSelectionOverrides ? undefined : selections)),
    quantity: escapeQuantity(selections?.quantity ?? 1),
  } as IWizardPickerState;
};

// Below we are guarding against malicious attempts to get quantities greater then 9.
// We are forcing quantity to be a number 1 - 9
export const escapeQuantity = (quantity: number): number =>
  Math.min(Math.max(quantity, MIN_CART_QUANTITY), MAX_CART_QUANTITY);

export const getSelectionsFromMenuData = ({
  data,
  selectionsEntry,
  pickerSelectionOverrides,
}: {
  data: Exclude<MenuObject, ISection>;
  selectionsEntry?: ICartEntry;
  pickerSelectionOverrides?: ICartEntry['pickerSelections'];
}): Partial<IWizardPickerState> => {
  if (isItem(data)) {
    return getItemSelections(data, selectionsEntry);
  }

  if (isCombo(data)) {
    return getComboSelections(data, selectionsEntry);
  }

  if (isPicker(data)) {
    return getPickerSelections(data, selectionsEntry, pickerSelectionOverrides);
  }

  return {};
};

export const calculateTotalStepsForCombo = (combo: ICombo) => {
  const hasMainItemAndMods = !!combo.mainItem?.options?.length;
  if (combo.uiPattern === ComboUIPattern.parallel) {
    return 0;
  }

  if (combo.uiPattern === ComboUIPattern.series) {
    return hasMainItemAndMods ? combo.options.length : combo.options.length - 1;
  }

  return 0;
};

export const calculateTotalStepsForPicker = (
  picker: IPicker,
  pickerSelections: Record<string, string>
) => {
  const selectedOption = computeSelectedOption(pickerSelections, picker) as
    | IPicker
    | ICombo
    | IItem;
  const optionsSteps = calculateTotalSteps(selectedOption, {});
  let pickerSteps = 0;

  if (picker.uiPattern === 'series') {
    // mods get their own screen after the picker aspects, optionsSteps determine if those should be on the same step or not
    pickerSteps = picker.pickerAspects.length + optionsSteps;
  }

  if (picker.uiPattern === 'parallel') {
    pickerSteps = optionsSteps;
  }

  if (picker.uiPattern === 'seriesWithLastAsModifier') {
    // mods always go with the last aspect, optionsSteps determine if those should be on the same step or not
    pickerSteps = picker.pickerAspects.length - 1 + optionsSteps;
  }

  return pickerSteps;
};

// NOTE: All return values are zero based with zero being the first step
export const calculateTotalSteps = (
  data: Exclude<MenuObject, ISection>,
  pickerSelections: Record<string, string>
): number => {
  // if data is an item its always a single step
  if (isItem(data)) {
    return 0;
  }

  if (isCombo(data)) {
    return calculateTotalStepsForCombo(data);
  }

  if (isPicker(data)) {
    return calculateTotalStepsForPicker(data, pickerSelections);
  }

  return 0;
};

export const transformMenuObjectToCartItem = (
  menuObject: PickerSelection,
  options?: IComboCartEntryOptions & {
    shouldLogEvents?: boolean;
    existingCartId?: string;
  }
): IMenuObjectToCartEntry => {
  const {
    quantity = 1,
    modifierSelections = [],
    pickerSelections = {},
    comboSlotSelections = {},
    shouldLogEvents = false,
    existingCartId,
    pathname,
    reorder,
    menuObjectSettings,
  } = options ?? {};

  const requiredModifiers: IModifierSelection[] = modifierSelections.filter(sel => {
    return sel.injectDefaultSelection || !sel.modifier.default;
  });

  const createCartEntryWithCatch = (args: ICreateCartEntryOptions) => {
    try {
      return createCartEntry(args);
    } catch (err) {
      if (shouldLogEvents) {
        logEvent(CustomEventNames.OFFER_ADDED, EventTypes.Other, {
          Status: 'Failure',
        });
      }
      logger.error({ error: err, message: 'Create cart entry failure' });
      return;
    }
  };

  let mainEntry: ICartEntry | undefined;
  let upsellEntries: ICartEntry[] = [];

  if (menuObject._type === 'combo') {
    const { includedComboSlotSelections, prunedItems } = partitionModalState(
      menuObject,
      comboSlotSelections,
      modifierSelections
    );

    const comboEntry = createCartEntryWithCatch({
      item: menuObject,
      price: 0, // Priced later on repriceCartEntries
      comboSlotSelections: includedComboSlotSelections,
      modifierSelections: requiredModifiers,
      pickerSelections,
      quantity,
      pathname,
      menuObjectSettings,
      ...(reorder && { reorder }),
      ...(existingCartId && { cartId: existingCartId }),
    });

    const upsellItems = prunedItems.reduce<ICartEntry[]>(
      (acc, { item: prunedItemSelection, modifierSelections: prunedItemModifierSelections }) => {
        const newCartEntry = createCartEntryWithCatch({
          item: prunedItemSelection,
          pathname,
          modifierSelections: prunedItemModifierSelections,
          price: 0, // Priced later on repriceCartEntries
          ...(reorder && { reorder }),
          ...(existingCartId && { cartId: existingCartId }),
        });

        if (newCartEntry) {
          acc.push(newCartEntry);
        }

        return acc;
      },
      []
    );

    mainEntry = comboEntry;
    upsellEntries = [...upsellItems];
  }

  if (menuObject._type === 'item') {
    const itemEntry = createCartEntryWithCatch({
      item: menuObject,
      pathname,
      modifierSelections: requiredModifiers,
      pickerSelections,
      price: 0, // Priced later on repriceCartEntries
      quantity,
      menuObjectSettings,
      ...(reorder && { reorder }),
      ...(existingCartId && { cartId: existingCartId }),
    });

    mainEntry = itemEntry;
  }

  return {
    mainEntry,
    upsellEntries,
    modifiersInjected: requiredModifiers,
  };
};
