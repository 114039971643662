import React, { useCallback } from 'react';

import { IconProps, InputProps } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import AutocompleteOptionsDropdown from 'components/ucl/autocomplete-options-dropdown';
import useLocationPredictions from 'hooks/geolocation/use-location-predictions';
import useDebounceEffect from 'hooks/use-debounce-effect';
import { CustomEventNames, EventTypes, logEvent } from 'state/amplitude';

type BaseInputProps = Pick<InputProps, 'value'>;

export interface IAutocompleteAddressInputProps extends BaseInputProps {
  testID: string;
  autoFocus?: boolean;
  label: string;
  labelColor?: string;
  addressesOnly?: boolean;
  required: boolean;

  message?: string;
  setMessage?: React.Dispatch<React.SetStateAction<string>>;

  onAddressSelected: (address: string, placeId?: string) => void;
  onInputValueChange: React.Dispatch<React.SetStateAction<string | undefined>>;
  onBlur?(): void;
  inputCustomProps?: InputProps;
  rightIconCustomProps?: IconProps;
}

export const AutocompleteAddressInput = React.forwardRef<
  HTMLInputElement,
  IAutocompleteAddressInputProps
>(
  (
    {
      testID,
      label,
      labelColor,
      message,
      addressesOnly = false,
      onAddressSelected = () => {},
      onInputValueChange,
      onBlur,
      setMessage,
      value = '',
      inputCustomProps,
      rightIconCustomProps,
    },
    ref
  ) => {
    const { formatMessage } = useIntl();

    /**
     * Retrieve the predictions based on the user's entry and map them to the format that the component prop expects.
     * Only pass on the first 5 predictions.
     */
    const predictions = useLocationPredictions(value, { addressesOnly })?.slice(0, 5);

    const handleDropdownValueSelected = useCallback(
      ({ address, placeId }: any) => {
        onAddressSelected(address, placeId);
        logEvent(CustomEventNames.MANUAL_LOCATION_SEARCH, EventTypes.Search);
      },
      [onAddressSelected]
    );

    const handleInputValueChange = useCallback(
      (inputValue: string | undefined) => {
        onInputValueChange(inputValue || '');

        if (setMessage) {
          setMessage('');
        }
      },
      [onInputValueChange, setMessage]
    );

    useDebounceEffect(
      () => {
        onInputValueChange(value);
      },
      500,
      [value]
    );

    return (
      <AutocompleteOptionsDropdown
        noPredictionsFoundText={formatMessage({ id: 'noLocationsMatchYourSearch' })}
        testID={testID}
        onSelection={handleDropdownValueSelected}
        predictions={predictions}
        onInputChange={handleInputValueChange}
        onBlur={onBlur}
        value={value}
        label={label}
        labelColor={labelColor}
        errorMessage={message}
        ref={ref}
        clearTextIconTitle={formatMessage({ id: 'clearText' })}
        inputCustomProps={inputCustomProps}
        rightIconCustomProps={rightIconCustomProps}
      />
    );
  }
);

export default AutocompleteAddressInput;
