import React, { useCallback } from 'react';

import { View } from 'react-native';

import { IBaseProps } from '@rbi-ctg/frontend';
import { useRoute } from 'hooks/navigation/use-route';
import { logButtonClick, logNavigationClick } from 'state/amplitude';
import { ClickEventComponentNames, CustomEventNames } from 'state/amplitude/constants';
import { useMatch } from 'state/location';
import { routes } from 'utils/routing';

import { NAV_X_PADDING } from './constants';
import StyledLink from './styled-link';

interface INavLinkProps extends IBaseProps {
  to: string;
  hidden?: boolean;
  requiresExactMatch?: boolean;
  onClick?: (to?: string, isActive?: boolean) => void;
  component?(isActive: boolean): React.ReactNode;
  navBarText?: string;
  componentKey?: string;
  isFirst: boolean;
  isLast: boolean;
}

const MemoizedNavLink = React.memo(function MemoizedNavLink({
  to,
  isActive,
  component,
  children,
  testID: dataQaId,
  onClick,
  hidden,
  componentKey,
  navBarText = '',
  isFirst,
  isLast,
}: INavLinkProps & {
  isActive: boolean;
}) {
  const onClickAndLogToAmplitude = useCallback(() => {
    if (navBarText !== '') {
      logButtonClick(
        {
          attributes: {
            component: ClickEventComponentNames.NAV_BAR,
            componentId: componentKey,
            name: navBarText,
          },
        },
        { logDuplicateClickEvent: true }
      );
    }
    logNavigationClick(CustomEventNames.NAV_CLICK, { linkTo: to });

    if (onClick) {
      onClick(to, isActive);
    }
  }, [componentKey, navBarText, to, onClick, isActive]);
  return (
    <StyledLink
      touchableOpacityEffect
      to={to}
      onPress={onClickAndLogToAmplitude}
      testID={dataQaId}
      hitSlop={{
        top: 10,
        right: isFirst ? NAV_X_PADDING : undefined,
        left: isLast ? NAV_X_PADDING : undefined,
        bottom: 10,
      }}
    >
      <View focusable={hidden ? false : undefined}>
        {component ? component(isActive) : children}
      </View>
    </StyledLink>
  );
});

export const NavLink = ({ requiresExactMatch, to, ...restProps }: INavLinkProps) => {
  const pathToMatch = requiresExactMatch ? to : `${to}/*`;
  let isActive = !!useMatch(pathToMatch);
  const { pathname } = useRoute();
  // Fixing rewards nav link icon being activated for `/rewards/offers` or `/rewards/offers-group`
  if (pathname.includes(routes.loyaltyOfferList) && pathToMatch === `${routes.rewards}/*`) {
    isActive = false;
  }
  // Activating offers nav bar icon for new offers group list
  if (
    pathname.includes(routes.loyaltyOfferGroup) &&
    pathToMatch.includes(routes.loyaltyOfferList)
  ) {
    isActive = true;
  }
  return <MemoizedNavLink isActive={isActive} to={to} {...restProps} />;
};

export const iconSize = 25;
