import React, { useState } from 'react';

import { Box, Text, useToast } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import { ModalContent, ModalHeading } from 'components/modal';
import Modal from 'components/modal/modal-screen';
import { BackArrowHistory } from 'components/navigation/back-arrow';
import { KeyboardAwareView } from 'components/ucl/keyboard-aware-view';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import useErrorModal from 'hooks/use-error-modal';
import { CustomEventNames, EventTypes, logEvent } from 'state/amplitude';
import { usePaymentContext } from 'state/payment';
import { useUIContext } from 'state/ui';
import logger from 'utils/logger';

import GiftCardImage from '../gift-card-image';

import { CardBalanceText, CurrentBalanceDescription, CurrentBalanceText, ModalHr } from './styled';

export const SuccessTransferGiftCardModal: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Vaulting Gift Card',
  });
  const { formatMessage } = useIntl();
  const { getPrepaidPaymentMethod, mergePrepaidCardBalances } = usePaymentContext();
  const { formatCurrencyForUser } = useUIContext();
  const { goBack } = useNavigation();
  const { params } = useRoute<{ transferCardBalance?: number; cardNumber?: string }>();
  const toast = useToast();

  const handleDismiss = () => {
    goBack();
  };

  const transferCard = async () => {
    setIsLoading(true);
    try {
      const newBalance = await mergePrepaidCardBalances({
        sourceCardNumber: cardNumber || '',
      });

      logEvent(CustomEventNames.GIFT_CARD_BALANCE_TRANSFER, EventTypes.Other, {
        'Current Balance': newBalance,
      });

      toast.show({
        text: formatMessage({ id: 'transferBalanceCompletedMessage' }),
        variant: 'positive',
      });

      handleDismiss();
    } catch (error) {
      // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
      openErrorDialog({ message: formatMessage({ id: 'giftCardAddingError' }), error });
      logger.error({ error, message: 'Error transfer gift card' });
    } finally {
      setIsLoading(false);
    }
  };

  const currentBalance = getPrepaidPaymentMethod()?.prepaid?.currentBalance;
  const transferCardBalance = params.transferCardBalance;
  const cardNumber = params.cardNumber;
  const formattedCurrecyBalance = formatCurrencyForUser(currentBalance);
  const formattedTransferCardBalance = formatCurrencyForUser(transferCardBalance);
  const formatCardNumber = cardNumber?.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ');

  const cardAlias = formatMessage({ id: 'cardAlias.bk' });
  const isTransferCardBalanceValid = transferCardBalance && transferCardBalance > 0;
  const { actionDetails, actionHandler, actionText } = isTransferCardBalanceValid
    ? {
        actionDetails: formatMessage(
          { id: 'wouldYouLikeToAddToPrepaidCard' },
          { amount: formattedTransferCardBalance, cardAlias }
        ),
        actionText: formatMessage({ id: 'yesTransfer' }, { amount: formattedTransferCardBalance }),
        actionHandler: transferCard,
      }
    : {
        actionDetails: formatMessage({ id: 'wouldYouLikeToAddADifferentCard' }, { cardAlias }),
        actionText: formatMessage({ id: 'yesAddNewCard' }, { cardAlias }),
        actionHandler: () => {
          //navigate(-1) go back to transfer-gift-card route
          goBack();
        },
      };

  return (
    <>
      <BackArrowHistory />
      <Modal
        onDismiss={handleDismiss}
        amplitudeEventData={{
          modalAppearanceEventMessage: 'Success transfer gift card',
        }}
      >
        <KeyboardAwareView withScroll>
          <ModalContent alignItems="center">
            <GiftCardImage />
            <ModalHeading testID="modal-heading">
              {formatMessage(
                { id: 'prepaidCardBalance' },
                { cardAlias: formatMessage({ id: 'cardAlias.bk' }) }
              )}
            </ModalHeading>
            <CardBalanceText testID="card-balance">
              {formatMessage(
                {
                  id: 'theCardBalanceIs',
                },
                {
                  cardNumber: (
                    <Text fontWeight="bold">
                      {formatCardNumber}
                      {'\n'}
                    </Text>
                  ),
                }
              )}
            </CardBalanceText>
            <CurrentBalanceText testID="transfer-balance">
              {formattedTransferCardBalance}
            </CurrentBalanceText>
            <ModalHr />
            <CurrentBalanceDescription>
              {formatMessage({ id: 'yourBalanceIs' }, { currentBalance: formattedCurrecyBalance })}
            </CurrentBalanceDescription>
            <CardBalanceText>{actionDetails}</CardBalanceText>
            <Box marginTop="$5" marginX="auto" marginBottom="$0" maxWidth="400px" width="100%">
              <ActionButton
                fullWidth
                testID="confirm-now"
                onPress={actionHandler}
                isLoading={isLoading}
              >
                {actionText}
              </ActionButton>
            </Box>
          </ModalContent>
        </KeyboardAwareView>

        <ErrorDialog />
      </Modal>
    </>
  );
};
