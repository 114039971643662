import { useContext, useEffect, useMemo, useState } from 'react';

import { ScrollView } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { BrazeContext } from 'state/braze';
import {
  getReadContentCardsFromLocalStorage,
  setReadContentCardsInLocalStorage,
} from 'state/braze/hooks/utils';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import Braze, { ContentCard } from 'utils/braze';

import { CustomContentCard } from './content-cards';
import {
  CardsContainer,
  CenteredLine,
  EmptyInboxCaption,
  EmptyInboxTitle,
  StyledPicture,
  UnreadSeparator,
  UnreadSeparatorText,
} from './styled';

export const NotificationInbox = () => {
  const dispatch = useAppDispatch();
  const contentCards = useAppSelector(selectors.loyalty.selectNewsFeedContentCards);
  const [unreadContentCardsIds, setUnreadContentCardsIds] = useState<string[]>([]);
  const { logContentCardImpression } = useContext(BrazeContext);
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (contentCards.length === 0) {
      return;
    }

    const readCardsFromLocalStorage = getReadContentCardsFromLocalStorage();
    const unreadCardsIds = contentCards
      .filter(({ id, viewed }) => !viewed && !readCardsFromLocalStorage.includes(id))
      .map(({ id }) => id);

    if (unreadCardsIds.length > 0) {
      setUnreadContentCardsIds(prevState => {
        return [...new Set([...prevState, ...unreadCardsIds])];
      });
    }

    contentCards.forEach(({ id, viewed }) => {
      if (!viewed) {
        setReadContentCardsInLocalStorage([id]);
        dispatch(actions.loyalty.refreshUnreadContentCards());
        logContentCardImpression(id);
      }
    });
  }, [contentCards, dispatch, logContentCardImpression, unreadContentCardsIds.length]);

  const orderedContentCards = useMemo(
    () =>
      contentCards.reduce(
        (acc, cur) => {
          const key = unreadContentCardsIds.includes(cur.id) ? 'unread' : 'read';
          acc[key].push(cur);
          return acc;
        },
        { read: [] as ContentCard[], unread: [] as ContentCard[] }
      ),
    [contentCards, unreadContentCardsIds]
  );

  useEffect(() => {
    return () => Braze.requestContentCardsRefresh();
  }, []);

  return (
    <ScrollView width="full" height="full" margin="auto">
      <CardsContainer>
        {contentCards.length === 0 ? (
          <>
            <StyledPicture source={require('./empty-state-image.png')} />
            <EmptyInboxTitle>{formatMessage({ id: 'emptyInboxTitle' })}</EmptyInboxTitle>
            <EmptyInboxCaption>{formatMessage({ id: 'emptyInboxCaption' })}</EmptyInboxCaption>
          </>
        ) : (
          <>
            {orderedContentCards.unread.map(card => (
              <CustomContentCard key={card.id} card={card} />
            ))}
            <UnreadSeparator>
              <CenteredLine />
              <UnreadSeparatorText>
                {formatMessage({ id: 'contentCardsSeparatorLabel' })}
              </UnreadSeparatorText>
              <CenteredLine />
            </UnreadSeparator>
            {orderedContentCards.read.map(card => (
              <CustomContentCard key={card.id} card={card} />
            ))}
          </>
        )}
      </CardsContainer>
    </ScrollView>
  );
};
