import React from 'react';

import { HelmetHTML } from 'components/layout/helmet-html';
import { AppModals } from 'navigation/app-modals';
import { AppRoutes } from 'navigation/app-routes';
import { GlobalStylesheet } from 'styles/global';

export const AppContainerInner: React.FC = React.memo(() => {
  return (
    <>
      <GlobalStylesheet />
      <HelmetHTML />
      <AppRoutes />
      <AppModals />
    </>
  );
});
