import { useEffect } from 'react';

import { Platform } from 'react-native';

import { Header, setHeaders } from 'state/graphql/headers';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { appVersion } from 'utils/app-version-info';
import NativeAuthStorage from 'utils/cognito/storage';
import crashlytics from 'utils/crashlytics';
import { addContext as addLoggerContext } from 'utils/datadog';
import { getDeviceId } from 'utils/device-id';
import { getApiKey, region } from 'utils/environment';
import { initGTM, sendGoogleTagManagerEvent } from 'utils/google-tag-manager';
import { StorageKeys } from 'utils/local-storage';
import logger from 'utils/logger';
import { TraceName } from 'utils/performance';
import { startTrace } from 'utils/performance/trace-lite';

import {
  Types,
  add,
  getSessionId,
  init,
  setDeviceId as setAmplitudeDeviceId,
  setSessionId as setAmplitudeSessionId,
  setOptOut,
} from './amplitude-package';

import { updateAmplitudeUserAttributes } from '.';

export const APPFLOW_DISPLAYED_BLOCKING_UI = 'Displayed Update UI';

const amplitudeKey = getApiKey({ key: 'amplitude', region });
const staticAmplitudeSessionId = { current: 'UN-INITIALIZED' };

/**
 * Initialize the Amplitude client.
 * If a user is already logged in, we will initialize with the user's cognito ID.
 */
export const initializeAmplitude = async () => {
  try {
    await NativeAuthStorage.sync();
    const cognitoId = NativeAuthStorage.getItem(StorageKeys.USER_AUTH_TOKEN);

    const options: Types.ReactNativeOptions = {
      trackingSessionEvents: true,
      appVersion,
      defaultTracking: { sessions: true, pageViews: false },
      trackingOptions: {
        appSetId: true,
        idfv: true,
      },
    };
    const trace = startTrace(TraceName.APP_START_INIT_AMPLITUDE);
    try {
      if (Platform.OS === 'web') {
        try {
          const gtmPlugin: Types.DestinationPlugin = {
            name: 'google-tag-manager',
            type: 'destination',
            setup: initGTM,
            execute: sendGoogleTagManagerEvent,
          };
          await add(gtmPlugin).promise;
        } catch (error) {
          logger.error({ error, message: 'Failed to add Google Tag Manager plugin to Amplitude' });
        }
      }

      if (cognitoId) {
        await init(amplitudeKey, cognitoId, options).promise;

        // This will automatically add global user attributes.
        updateAmplitudeUserAttributes();
      } else {
        await init(amplitudeKey, undefined, options).promise;
      }
    } catch (error) {
      logger.error({ error, message: 'Error initializing Amplitude SDK' });
    } finally {
      trace?.stop();
    }

    let amplitudeSessionId: string | null | undefined = null;
    let error: any = null;
    try {
      amplitudeSessionId = getSessionId()?.toString();
    } catch (_error) {
      error = _error;
    } finally {
      if (!amplitudeSessionId) {
        /**
         * TODO evaluate in prod if this ever fails anymore and if it doesn't, remove this code.
         * Setting a custom session ID since it failed. Note, the session ID does note have to be unique across users.
         * https://www.docs.developers.amplitude.com/data/sdks/typescript-react-native/#custom-user-id
         * https://help.amplitude.com/hc/en-us/articles/115002323627-Track-sessions-in-Amplitude#:~:text=The%20session%20ID%20does%20not,you%20use%20to%20group%20sessions.
         */
        const newSessionID = Date.now();
        setAmplitudeSessionId(newSessionID);
        amplitudeSessionId = newSessionID.toString();
        logger.warn({
          message: `Failed to get Amplitude SessionID. Generated one manually`,
          error,
        });
      }

      addLoggerContext('amplitudeSession', amplitudeSessionId);
      crashlytics().setAttribute('amplitudeSession', amplitudeSessionId);
      setHeaders(Header.SESSION_ID, amplitudeSessionId);
      staticAmplitudeSessionId.current = amplitudeSessionId;
    }

    const id = await getDeviceId();
    if (id) {
      setAmplitudeDeviceId(id?.toLocaleLowerCase()); // toLower keeps this consistent with how it was done from mparticle for continuity of tracking
    }
  } catch (error) {
    logger.error({ error, message: 'Unknown amplitude initialization error' });
  }
};

export const useAmplitudeOptOut = () => {
  const enableOptionalAmplitudeTracking = useFlag(
    LaunchDarklyFlag.ENABLE_OPTIONAL_AMPLITUDE_TRACKING
  );

  useEffect(() => {
    const cognitoId = NativeAuthStorage.getItem(StorageKeys.USER_AUTH_TOKEN);

    // If we turn on optional Amplitude tracking
    // users will be able to opt out of Amplitude tracking.
    // If we decide that we do not want to disable events,
    // when we disable the flag (making Amplitude tracking not-optional),
    // we need to change setOptOut to false in order to send events to Amplitude.
    // This would guarantee any users who previously opted out, will be opted back in.
    // We should always faithfully record in local storage + Amplitude what the user wants us to do.
    // If we have good reason to not respect their preferences - that is where we should override their preferences.
    // REVISIT
    if (cognitoId && !enableOptionalAmplitudeTracking) {
      setOptOut(false);
    }
  }, [enableOptionalAmplitudeTracking]);
};
