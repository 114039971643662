import React, { useCallback, useEffect, useRef, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Header, Text, ToastProps, VStack, useToast } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import {
  LayoutRectangle,
  NativeSyntheticEvent,
  Platform,
  TextInput as RNTextInput,
  TextInputChangeEventData,
} from 'react-native';
import { scrollIntoView } from 'seamless-scroll-polyfill';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { CircularWarningIcon } from 'components/icons/circular-warning-icon';
import { TextInput } from 'components/ucl/text-input';
import { GraphQLErrorMessages } from 'enums/graphql';
import { ProviderType } from 'generated/graphql-gateway';
import { LogUserErrorSeverity, useLogUserErrorMessage } from 'hooks/log-user-error-message';
import { useNavigation } from 'hooks/navigation/use-navigation';
import useErrorModal, { IErrorModal } from 'hooks/use-error-modal';
import AppleAuthButton from 'pages/authentication/components/social-auth-buttons/apple/apple-auth-button';
import FacebookAuthButton from 'pages/authentication/components/social-auth-buttons/facebook/facebook-auth-button';
import GoogleAuthButton from 'pages/authentication/components/social-auth-buttons/google/google-auth-button';
import { UserAuth } from 'pages/authentication/components/social-auth-buttons/types';
import { EmailIcon } from 'pages/authentication/sign-in/EmailIcon';
import { HttpErrorCodes } from 'remote/constants';
import { CustomEventNames, EventTypes, logEvent } from 'state/amplitude';
import { useAuthContext } from 'state/auth';
import {
  SocialLoginError,
  USER_DECLINED_EMAIL_ACCESS_PERMISSION_ERROR_MESSAGE,
} from 'state/auth/errors';
import useAuthFlow from 'state/auth/hooks/use-auth-flow';
import { ModalAuthScreen } from 'state/auth/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLocationContext } from 'state/location';
import { useScrollContext } from 'state/scroll';
import { useUIContext } from 'state/ui';
import { isIOS, isWeb } from 'utils/environment';
import { GraphQLErrorCodes, parseGraphQLErrorCodes } from 'utils/errors';
import { isEmailValid } from 'utils/form';
import formatListOfWords, { JoinType } from 'utils/intl/formatListOfWords';
import LocalStorage from 'utils/local-storage';
import { Keys } from 'utils/local-storage/constants';
import logger from 'utils/logger';
import { OTPAuthDeliveryMethod } from 'utils/otp';
import { routes } from 'utils/routing';

import { IAuthCampaign } from '../components/auth-campaigns/hooks/use-auth-campaign';

import { LabelStyled, StyledButtonWidthLimit, TextInputWrapper } from './styled';

const { All, SMS, Email, None } = OTPAuthDeliveryMethod;

// Additional Y amount for show email input and submit button
const ADDITIONAL_SCROLL_Y = 50;

const MAX_SOCIAL_LOGIN_ERROR_DIALOG_CONTENT_WIDTH = '520px';

// we want to use the OTP flag value to adjust our messaging
const useFormattedMessages = () => {
  const { formatMessage } = useIntl();
  return (flagValue: OTPAuthDeliveryMethod) => {
    switch (flagValue) {
      case All:
        const combined = [
          formatMessage({ id: 'emailAddress' }),
          formatMessage({ id: 'phoneNumber' }),
        ];
        const combinedTitle = formatListOfWords({
          formatMessage,
          list: combined,
          joinType: JoinType.DISJUNCTION,
        });
        return [
          combinedTitle,
          formatMessage({ id: 'emailOrPhoneNumberRequiredError' }),
          formatMessage({ id: 'notValidEmailOrPhoneNumberError' }),
        ];
      case SMS:
        return [
          formatMessage({ id: 'phoneNumber' }),
          formatMessage({ id: 'phoneNumberRequiredError' }),
          formatMessage({ id: 'notValidPhoneNumberError' }),
        ];
      case Email:
      case None:
      default:
        return [
          formatMessage({ id: 'emailAddress' }),
          formatMessage({ id: 'emailRequiredError' }),
          formatMessage({ id: 'notValidEmailError' }),
        ];
    }
  };
};

interface ISignInFormProps {
  authCampaignExtraFields?: IAuthCampaign['extraFields'];
}

export const SignInForm = ({ authCampaignExtraFields }: ISignInFormProps) => {
  const {
    isAuthenticated,
    signIn,
    socialLogin,
    setModalAuthState,
    modalAuthIsOpen,
    openSignInModal,
    originLocation,
    modalAuthState,
    user,
    setIsReAuthenticating,
    setOriginLocation,
    onSignInSuccess,
    alertMessage,
  } = useAuthContext();

  const chooseMessages = useFormattedMessages();
  const { formatMessage } = useIntl();
  const toast = useToast();
  const { attemptedSignUpWithExistingEmail, errorMessage: initialErrorMessage } = useAuthFlow();

  const { userEmail } = useUIContext();
  const { signUpEmail } = useLocationContext();

  const emailInputEl = useRef<RNTextInput>(null);
  const isGoogleAuthEnabled = useFlag(LaunchDarklyFlag.ENABLE_SOCIAL_LOGIN_GOOGLE);
  const isAppleAuthEnabled = useFlag(LaunchDarklyFlag.ENABLE_SOCIAL_LOGIN_APPLE);
  const isFacebookAuthEnabled = useFlag(LaunchDarklyFlag.ENABLE_SOCIAL_LOGIN_FACEBOOK);
  const isOTPFlowEnabled = useFlag(LaunchDarklyFlag.ENABLE_OTP_LOGIN);

  const { navigate } = useNavigation();
  const defaultEmail = signUpEmail || userEmail || '';
  const [emailInput, setEmailInput] = useState(defaultEmail);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);
  const [isLoadingFacebook, setIsLoadingFacebook] = useState(false);
  const [isLoadingApple, setIsLoadingApple] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    modalAuthState?.errorMessage || initialErrorMessage
  );
  const [shouldDisableAuthButtons, setShouldDisableAuthButtons] = useState(false);
  const disableEmailInput = !!modalAuthState.disableEmailInput;

  useLogUserErrorMessage({ message: errorMessage, severity: LogUserErrorSeverity.Recoverable });

  const [ErrorDialog, openErrDialog, dismissDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Sign In Failure',
  });

  const { setUserEmail } = useUIContext();

  const [EmailDoesNotMatchErrorDialog, openEmailDoesNotMatchErrDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Social login attempt with different email',
    onConfirm: () => {
      // This would only happen when re-authenticating via social login.
      setIsReAuthenticating(false);
      // Clears user email field in the sign-in form.
      setUserEmail('');

      const signInErrorToast: ToastProps = {
        text: formatMessage({ id: 'authError' }),
        variant: 'negative',
      };

      if (modalAuthIsOpen) {
        openSignInModal();
        toast.show(signInErrorToast);
        return;
      }

      navigate(routes.signIn, {
        state: { toast: signInErrorToast },
      });
    },
  });

  const navigateOnSuccess = !modalAuthIsOpen;

  const aimToSignIn = useCallback(
    async ({ email = '', phoneNumber = '' }: any) => {
      try {
        setIsLoading(true);
        setShouldDisableAuthButtons(true);

        if (modalAuthState.onPreSignIn) {
          await modalAuthState.onPreSignIn();
        }

        await signIn({
          email,
          phoneNumber,
          ...(email && { navigateOnSuccessParams: { email } }),
          navigateOnSuccess,
          authenticationMethod: 'OTP',
          showSignUpIfUserNotExists: true,
        });
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setShouldDisableAuthButtons(false);

        let message = formatMessage({ id: 'authError' });

        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        if (error?.graphQLErrors?.[0]?.extensions?.statusCode === HttpErrorCodes.TooManyRequests) {
          message = `${formatMessage({ id: 'maxAttemptsReached' })} ${formatMessage({
            id: 'pleaseTryAgainLater',
          })}`;
        }

        openErrDialog({
          // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
          error,
          message,
        });

        logger.warn({ error });
      }
    },
    [modalAuthState, signIn, navigateOnSuccess, formatMessage, openErrDialog]
  );

  const PROVIDER_TYPE_DISPLAY_VALUE_MAP = {
    [ProviderType.APPLE]: 'Apple',
    [ProviderType.FACEBOOK]: 'Facebook',
    [ProviderType.GOOGLE]: 'Google',
  };

  const getSocialLoginUserFriendlyMessageForError = (error: SocialLoginError): string => {
    switch (error.message) {
      case USER_DECLINED_EMAIL_ACCESS_PERMISSION_ERROR_MESSAGE:
        return formatMessage(
          { id: 'userSocialLoginEmailUnavailable' },
          { providerType: PROVIDER_TYPE_DISPLAY_VALUE_MAP[error.providerType] }
        );
      default:
        return formatMessage({ id: 'userSocialLoginGenericErrorDescription' });
    }
  };

  const getSocialLoginErrorDialogProps = ({
    title,
    message,
  }: {
    title: string;
    message: string;
  }): Pick<IErrorModal, 'title' | 'message' | 'dialogProps'> => {
    return {
      dialogProps: {
        contentMaxWidth: MAX_SOCIAL_LOGIN_ERROR_DIALOG_CONTENT_WIDTH,
        headingComponent: (
          <Header variant={'headerTwo'} alignSelf={'center'} _android={{ paddingTop: '$14' }}>
            <VStack alignItems={'center'}>
              <CircularWarningIcon />
              <Text marginTop="$3">{title}</Text>
            </VStack>
          </Header>
        ),
      },
      message: <Text textAlign="center">{message}</Text>,
    };
  };

  useEffect(() => {
    if (attemptedSignUpWithExistingEmail && !isLoading) {
      setEmailInput(defaultEmail);
      aimToSignIn({ email: defaultEmail });
    }
  }, [defaultEmail, attemptedSignUpWithExistingEmail, aimToSignIn, isLoading]);

  const [labelMessage, requiredErrorMessage, invalidErrorMessage] = chooseMessages(
    OTPAuthDeliveryMethod.Email
  );

  const handleEmailChange = (ev: NativeSyntheticEvent<TextInputChangeEventData>) => {
    setErrorMessage(undefined);
    setEmailInput(ev.nativeEvent.text);
  };

  const parse = useCallback(
    async (input: string) => {
      const trimmedInput = input.trim();
      if (!trimmedInput) {
        setErrorMessage(requiredErrorMessage);
        return {};
      }

      const noLetters = /^[\0-9]{2,}$/.test(trimmedInput);

      const isEmail = isEmailValid(trimmedInput);

      if (!noLetters && !isEmail) {
        setErrorMessage(formatMessage({ id: 'notValidEmailError' }));
        return {};
      }

      if (isEmail) {
        return { email: trimmedInput };
      }

      setErrorMessage(invalidErrorMessage);
      // If there was an error, try to focus the input after an attempted sign in
      // this will force screen readers to read the alert even if it never left the screen
      if (emailInputEl.current) {
        emailInputEl.current.focus();
      }
      return {};
    },
    [invalidErrorMessage, requiredErrorMessage, formatMessage]
  );

  const handleSubmit = useCallback(async () => {
    const { email } = await parse(emailInput);

    if (email) {
      setErrorMessage('');
      aimToSignIn({ email });
    }
  }, [aimToSignIn, emailInput, parse]);

  const { scrollTo } = useScrollContext();

  const emailInputPosition = useRef<LayoutRectangle>();

  const handleEmailFocus = useCallback(() => {
    if (emailInputPosition.current && Platform.OS === 'android') {
      scrollTo({
        x: 0,
        y: emailInputPosition.current.y + emailInputPosition.current.height + ADDITIONAL_SCROLL_Y,
      });
    }
  }, [scrollTo]);

  const socialSignIn = useCallback(
    async (userInfo: UserAuth, providerType: ProviderType, eventName: CustomEventNames) => {
      try {
        if (modalAuthState.onPreSignIn) {
          await modalAuthState.onPreSignIn();
        }

        if (userInfo.email) {
          if (isAuthenticated && user?.details.email !== userInfo.email) {
            setIsLoadingApple(false);
            setIsLoadingFacebook(false);
            setIsLoadingGoogle(false);
            setShouldDisableAuthButtons(false);

            openEmailDoesNotMatchErrDialog({
              message: formatMessage({ id: 'emailAddressDoesNotMatch' }),
            });

            return;
          }

          if (LocalStorage.getItem(Keys.SOCIAL_LOGIN_USER_EMAIL) !== userInfo.email) {
            LocalStorage.setItem(Keys.SOCIAL_LOGIN_USER_EMAIL, userInfo.email);
          }
        } else {
          openErrDialog({
            ...getSocialLoginErrorDialogProps({
              title: formatMessage({ id: 'userSocialLoginFailed' }),
              message: formatMessage(
                { id: 'userSocialLoginEmailUnavailable' },
                { providerType: PROVIDER_TYPE_DISPLAY_VALUE_MAP[providerType] }
              ),
            }),
          });

          setShouldDisableAuthButtons(false);

          return;
        }

        if (
          userInfo?.name?.trim()?.length &&
          LocalStorage.getItem(Keys.SOCIAL_LOGIN_USER_NAME) !== userInfo.name
        ) {
          LocalStorage.setItem(Keys.SOCIAL_LOGIN_USER_NAME, userInfo.name);
        }

        await socialLogin({
          providerType,
          providerToken: userInfo.token || '',
        });

        logEvent(eventName, EventTypes.Other);
        LocalStorage.removeItem(Keys.SOCIAL_LOGIN_USER_NAME);

        if (!modalAuthIsOpen) {
          const signInSuccessToast = {
            text: formatMessage({ id: 'signInSuccess' }),
            variant: 'positive',
          };

          const path = originLocation || routes.base;
          setOriginLocation(null);

          return navigate(path, {
            replace: true,
            state: { toast: signInSuccessToast },
          });
        }

        onSignInSuccess();
      } catch (error) {
        let message = formatMessage({ id: 'authError' });
        const title = formatMessage({ id: 'userSocialLoginFailed' });

        if (error instanceof ApolloError) {
          const gqlErrorCodes = parseGraphQLErrorCodes(error);

          const userNotFoundError = error.graphQLErrors.find(
            error => error.extensions.code === GraphQLErrorCodes.USER_NOT_FOUND
          );

          const isUserNotFoundError =
            !!userNotFoundError || error.message === GraphQLErrorMessages.USER_NOT_FOUND;

          const isEmailNotVerifiedError = gqlErrorCodes.some(
            parsedError => parsedError.errorCode === GraphQLErrorCodes.EMAIL_NOT_VERIFIED
          );

          const isTooManyRequestsError =
            error.graphQLErrors?.[0]?.extensions?.statusCode === HttpErrorCodes.TooManyRequests;

          const isEmailMissingFromTokenError = gqlErrorCodes.some(
            parsedError => parsedError.errorCode === GraphQLErrorCodes.EMAIL_MISSING_FROM_TOKEN
          );

          if (isUserNotFoundError) {
            const notFoundUserData: unknown = userNotFoundError?.extensions.user;

            let fallbackUserName: string | undefined = undefined;
            let fallbackUserEmail: string | undefined = undefined;

            if (notFoundUserData && typeof notFoundUserData === 'object') {
              if (
                'name' in notFoundUserData &&
                typeof notFoundUserData.name === 'string' &&
                notFoundUserData.name
              ) {
                fallbackUserName = notFoundUserData.name;
              }

              if (
                'email' in notFoundUserData &&
                typeof notFoundUserData.email === 'string' &&
                notFoundUserData.email
              ) {
                fallbackUserEmail = notFoundUserData.email;
              }
            }

            const email = LocalStorage.getItem(Keys.SOCIAL_LOGIN_USER_EMAIL) || fallbackUserEmail;
            const name = LocalStorage.getItem(Keys.SOCIAL_LOGIN_USER_NAME) || fallbackUserName;

            if (modalAuthIsOpen) {
              setModalAuthState({
                screen: ModalAuthScreen.SIGN_UP,
                shouldDisplaySignInSuccessMessage: false,
                user: { name, email },
                providerType,
              });
              return;
            }

            return navigate(routes.signUp, {
              state: {
                email,
                name,
                authenticationMethod: 'Social',
                socialAuthenticationType: providerType,
              },
            });
          } else if (isEmailNotVerifiedError) {
            message = formatMessage({ id: 'userAuthEmailNotVerified' });
          } else if (isEmailMissingFromTokenError) {
            message = formatMessage(
              { id: 'userSocialLoginEmailUnavailable' },
              { providerType: PROVIDER_TYPE_DISPLAY_VALUE_MAP[providerType] }
            );
          } else if (isTooManyRequestsError) {
            message = `${formatMessage({ id: 'maxAttemptsReached' })} ${formatMessage({
              id: 'pleaseTryAgainLater',
            })}`;
          }
        }

        openErrDialog({
          error: error as Error,
          ...getSocialLoginErrorDialogProps({
            title,
            message,
          }),
          onConfirm: () => socialSignIn(userInfo, providerType, eventName),
          onDismiss: dismissDialog,
          confirmationText: formatMessage({ id: 'retry' }),
          dismissText: formatMessage({ id: 'close' }),
        });

        logger.warn({ error });
      }
    },
    [
      modalAuthState,
      socialLogin,
      modalAuthIsOpen,
      onSignInSuccess,
      isAuthenticated,
      user?.details.email,
      openEmailDoesNotMatchErrDialog,
      formatMessage,
      originLocation,
      setOriginLocation,
      navigate,
      openErrDialog,
      dismissDialog,
      setModalAuthState,
      PROVIDER_TYPE_DISPLAY_VALUE_MAP,
    ]
  );

  const signInUpForm = (
    <>
      <TextInputWrapper>
        <TextInput
          alertMessage={alertMessage}
          accessibilityLabel={errorMessage ? errorMessage : labelMessage}
          testID="signin-email-input"
          errorMessage={errorMessage}
          label={labelMessage}
          required
          type="email"
          onChange={handleEmailChange}
          ref={emailInputEl}
          autoComplete="email"
          value={emailInput}
          autoCapitalize="none"
          keyboardType="email-address"
          returnKeyType="done"
          onSubmitEditing={handleSubmit}
          onLayout={ev => {
            emailInputPosition.current = ev.nativeEvent.layout;
            if (isWeb) {
              // This is a hack to avoid overlapping on mobile web
              const element = document.querySelector('[data-testid="keyboard-aware-auth-modal"]');
              const signinEmailInput = document.querySelector('[data-testid="signin-email-input"]');
              if (element && !isIOS()) {
                element.scrollTop = element.scrollHeight + ADDITIONAL_SCROLL_Y;
              } else if (signinEmailInput) {
                scrollIntoView(signinEmailInput);
              }
            }
          }}
          onFocus={handleEmailFocus}
          placeholder={formatMessage({ id: 'emailAddress' })}
          isDisabled={!!disableEmailInput}
        />
      </TextInputWrapper>
      {authCampaignExtraFields}
      <StyledButtonWidthLimit>
        <ActionButton
          fullWidth
          testID="signin-button"
          isLoading={isLoading}
          disabled={shouldDisableAuthButtons}
          onPress={handleSubmit}
        >
          {formatMessage({ id: 'signInUp' })}
        </ActionButton>
      </StyledButtonWidthLimit>
    </>
  );

  return (
    <>
      {isGoogleAuthEnabled && (
        <GoogleAuthButton
          isLoading={isLoadingGoogle}
          isDisabled={shouldDisableAuthButtons}
          onStart={() => {
            setIsLoadingGoogle(true);
            setShouldDisableAuthButtons(true);
          }}
          onSuccess={async userInfo => {
            await socialSignIn(
              userInfo,
              ProviderType.GOOGLE,
              CustomEventNames.SOCIAL_LOGIN_GOOGLE_COMPLETE
            );

            setIsLoadingGoogle(false);
          }}
          onError={error => {
            setIsLoadingGoogle(false);
            setShouldDisableAuthButtons(false);

            if (
              error.code !== 'COOKIE_CLOSE_POPUP' &&
              !error?.message?.includes('cancelled') &&
              !error?.message?.includes('canceled the sign in request')
            ) {
              openErrDialog({
                error,
                ...getSocialLoginErrorDialogProps({
                  title: formatMessage({ id: 'userSocialLoginFailed' }),
                  message: getSocialLoginUserFriendlyMessageForError(error),
                }),
              });
            }
          }}
        />
      )}
      {isFacebookAuthEnabled && (
        <FacebookAuthButton
          isLoading={isLoadingFacebook}
          isDisabled={shouldDisableAuthButtons}
          onStart={() => {
            setIsLoadingFacebook(true);
            setShouldDisableAuthButtons(true);
          }}
          onSuccess={async userInfo => {
            await socialSignIn(
              userInfo,
              ProviderType.FACEBOOK,
              CustomEventNames.SOCIAL_LOGIN_FACEBOOK_COMPLETE
            );

            setIsLoadingFacebook(false);
          }}
          onError={error => {
            setIsLoadingFacebook(false);
            setShouldDisableAuthButtons(false);

            if (error?.status === 'unknown' || error?.isCancelled) {
              return;
            }

            openErrDialog({
              error,
              ...getSocialLoginErrorDialogProps({
                title: formatMessage({ id: 'userSocialLoginFailed' }),
                message: getSocialLoginUserFriendlyMessageForError(error),
              }),
            });
          }}
        />
      )}
      {isAppleAuthEnabled && (
        <AppleAuthButton
          isLoading={isLoadingApple}
          isDisabled={shouldDisableAuthButtons}
          onStart={() => {
            setIsLoadingApple(true);
            setShouldDisableAuthButtons(true);
          }}
          onSuccess={async userInfo => {
            await socialSignIn(
              userInfo,
              ProviderType.APPLE,
              CustomEventNames.SOCIAL_LOGIN_APPLE_COMPLETE
            );

            setIsLoadingApple(false);
          }}
          onError={error => {
            setIsLoadingApple(false);

            if (
              error?.err?.error === 'popup_closed_by_user' ||
              error?.message?.includes('AuthorizationError')
            ) {
              return;
            }

            openErrDialog({
              error,
              ...getSocialLoginErrorDialogProps({
                title: formatMessage({ id: 'userSocialLoginFailed' }),
                message: getSocialLoginUserFriendlyMessageForError(error),
              }),
            });
          }}
        />
      )}
      {!showForm && isOTPFlowEnabled && (
        <ActionButton
          eventAttributes={{ Name: 'Continue with Email' }}
          leftIcon={<EmailIcon />}
          fullWidth
          borderColor={Styles.color.black}
          marginY="$2"
          variant={ActionButtonVariants.OUTLINE}
          disabled={shouldDisableAuthButtons}
          onPress={() => setShowForm(true)}
        >
          <LabelStyled>{formatMessage({ id: 'continueWithEmail' })}</LabelStyled>
        </ActionButton>
      )}
      {isOTPFlowEnabled && showForm && signInUpForm}
      <ErrorDialog />
      <EmailDoesNotMatchErrorDialog />
    </>
  );
};
