import { useCallback, useMemo } from 'react';

import { useIntl } from 'react-intl';
import { useToast as useMenuToast } from 'react-native-toast-notifications';

import { ItemAvailabilityStatus } from 'enums/menu';
import { useEnableCartV2Experiment } from 'experiments/use-enable-cart-v2-experiment';
import useFeatureMenuAddOn from 'hooks/use-feature-menu-add-on';
import { useLoyaltyRewardsList } from 'hooks/use-loyalty-rewards-list';
import { useEnableProductDetailsPageRedesign } from 'pages/menu/hooks/use-enable-product-details-page-redesign';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { MenuToastEventTypes } from 'state/menu/toast-menu/types';
import { useMapToFrontendCartEntry } from 'state/order/hooks/use-map-to-frontend-cart-entry';
import { isOfferType } from 'state/order/utils';
import { maybeCreateCartEntriesFromOffer, maybeCreateCartEntryFromReward } from 'utils/cart';
import { isWeb } from 'utils/environment';
import { routes } from 'utils/routing';

/**
 *
 * Returns a function to reorder a previous order.
 */
export const useHandleReorder = ({
  addToCart,
  navigate,
  linkTo,
  setPendingReorder,
  setReordering,
  storeHasSelection,
  setUnavailableCartEntries,
  setReorderedOrderId,
}) => {
  const { formatMessage } = useIntl();
  const mapBackendToFrontend = useMapToFrontendCartEntry();
  const enableAddExtras = useFlag(LaunchDarklyFlag.ENABLE_ADD_EXTRAS);
  const { sections: addOnSections } = useFeatureMenuAddOn();
  const menuToast = useMenuToast();
  const enableCartV2 = useEnableCartV2Experiment();
  const enableProductDetailsPageRedesign = useEnableProductDetailsPageRedesign();

  const shouldDisplayWebToast = enableProductDetailsPageRedesign && isWeb;

  // Temporary flag to disable reordering of items/combos based on Sanity IDs.
  // Should be removed once One Menu is live.
  const excludedIds = useFlag(LaunchDarklyFlag.ENABLE_MENU_REORDERING_AVAILABILITY_OVERRIDE);
  const sanityIdsExcludedFromReordering = useMemo(() => {
    return excludedIds || [];
  }, [excludedIds]);

  const hasAddons = addOnSections.length > 0;

  const { allRewards } = useLoyaltyRewardsList();
  const allRewardsMap = useMemo(() => {
    return allRewards?.reduce((dict, item) => {
      dict[item._id] = item;
      return dict;
    }, {});
  }, [allRewards]);

  const processEntry = useCallback(
    async (entry, reorderCartEntries) => {
      const mappedEntries = isOfferType(entry.type)
        ? allRewardsMap[entry.sanityId]
          ? [maybeCreateCartEntryFromReward(entry, allRewardsMap[entry.sanityId])]
          : maybeCreateCartEntriesFromOffer(entry, sanityIdsExcludedFromReordering)
        : [entry];

      return Promise.all(
        mappedEntries.map(async mappedEntry => {
          const { cartEntry: frontendEntry, status } = await mapBackendToFrontend(
            enableCartV2 ? { ...mappedEntry, quantity: 1 } : mappedEntry,
            reorderCartEntries
          );

          return { frontendEntry, status };
        })
      );
    },
    [allRewardsMap, enableCartV2, mapBackendToFrontend, sanityIdsExcludedFromReordering]
  );

  return useCallback(
    async (order, url = routes.orders) => {
      if (!order || !storeHasSelection) {
        // if there's no order or no order or store selection, navigate to where was initially intended
        return navigate(url);
      }

      setReordering(true);
      setReorderedOrderId(order.rbiOrderId);

      const { reorderCartEntries } = order.cart;

      const availableEntries = [];
      const unavailableEntries = [];

      let containsExtras = false;

      const allEntries = await Promise.all(
        reorderCartEntries.flatMap(entry => {
          // Cart v2 process each item quantity in the order individually.
          // ie: If the order has 3 items of the same item, we want to add 3 items to the cart and not 1 with quantity 3
          const quantity = enableCartV2 ? entry.quantity : 1;
          return Array.from({ length: quantity }, () => processEntry(entry, reorderCartEntries));
        })
      );

      allEntries.flat().forEach(({ frontendEntry, status }) => {
        if (status === ItemAvailabilityStatus.CART_EXTRA) {
          containsExtras = true;
        } else if (status === ItemAvailabilityStatus.AVAILABLE) {
          availableEntries.push(frontendEntry);
        } else {
          unavailableEntries.push(frontendEntry);
        }
      });

      availableEntries.forEach(entry => addToCart(entry, undefined, !shouldDisplayWebToast));

      if (availableEntries.length && shouldDisplayWebToast) {
        const [firstEntry] = availableEntries;
        const message =
          availableEntries.length > 1
            ? formatMessage(
                { id: 'addToCartMultipleSuccess' },
                { itemName: firstEntry.name, quantity: availableEntries.length - 1 }
              )
            : formatMessage({ id: 'addToCartSuccess' }, { itemName: firstEntry.name });

        menuToast.show(message, {
          placement: 'top',
          type: MenuToastEventTypes.ADD_TO_CART,
          data: { image: firstEntry.image },
        });
      }

      setReordering(false);
      setReorderedOrderId(null);
      setPendingReorder(null);

      // this is needed to prioritize the unavailable item modal
      if (unavailableEntries.length) {
        setUnavailableCartEntries(unavailableEntries);
        return navigate(url);
      }

      if (enableAddExtras && hasAddons && containsExtras) {
        // If the order had extras go to the extras modal in the cart
        linkTo(routes.cartAddExtras);
      } else {
        navigate(routes.cart);
      }
    },
    [
      storeHasSelection,
      setReordering,
      setReorderedOrderId,
      shouldDisplayWebToast,
      setPendingReorder,
      enableAddExtras,
      hasAddons,
      navigate,
      enableCartV2,
      processEntry,
      addToCart,
      formatMessage,
      menuToast,
      setUnavailableCartEntries,
      linkTo,
    ]
  );
};
