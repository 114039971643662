import { ISanityImage } from '@rbi-ctg/menu';

import { MenuEntry, MenuItemType } from './temp/menu-api-aliases';

/**
 * Represents an entity (item, combo, picker, etc.) that can be ordered. Not a section.
 */
export interface IMainMenuProduct {
  price: number;
  calories: number;
}

/**
 * Represents a node in the main menu hierarchy
 */
export interface IMainMenuNode {
  id: string;
  name: string;
  image: {
    resource: ISanityImage;
    description: string;
  };
  children: IMainMenuNode[];
  product: IMainMenuProduct | undefined;
  type: MenuItemType;
}

export interface IndexedMenuLookupOptions {
  /**
   * The matched entry must have this type
   */
  type?: MenuEntry['type'];

  /**
   * The matched entry must have this group type
   */
  groupType?: MenuEntry['groupType'];
}

/**
 * Fast lookup for menu entries by id
 */
export class IndexedMenu {
  public static readonly EMPTY = new IndexedMenu(new Map());

  constructor(private readonly map: Map<string, MenuEntry>) {}

  /**
   * Returns the menu entry with the given id or throws an error if it does not exist
   * @param id The id of the entry to look up
   * @param options Options to filter the lookup
   */
  public get(id: string, options?: IndexedMenuLookupOptions) {
    const entry = this.map.get(id);
    if (!entry) {
      throw new Error(`Entry not found: ${id}`);
    }

    if (options?.type && entry.type !== options.type) {
      throw new Error(`Entry type mismatch: ${entry.type} !== ${options.type}`);
    }

    if (options?.groupType && entry.groupType !== options.groupType) {
      throw new Error(`Entry group type mismatch: ${entry.groupType} !== ${options.groupType}`);
    }
    return entry;
  }

  /**
   * Returns the menu entry with the given id or undefined if it does not exist
   * @param id The id of the entry to look up
   * @param options Options to filter the lookup
   */
  public find(id: string, options?: IndexedMenuLookupOptions) {
    const entry = this.map.get(id);
    if (!entry) {
      return undefined;
    }

    if (options?.type && entry.type !== options.type) {
      return undefined;
    }

    if (options?.groupType && entry.groupType !== options.groupType) {
      return undefined;
    }

    return entry;
  }
}
