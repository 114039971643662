import * as React from 'react';
import { FC } from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';
import { Platform } from 'react-native';

import ActionButton, { ActionButtonSizes } from 'components/action-button';
import { useGeolocation } from 'state/geolocation';
import DEFAULT_MAP_ZOOM from 'utils/restaurant/default-map-zoom';

import { ResetLocationButton } from '../..';

import { IMapButtonsProps } from './types';

/**
 *
 * MapButtons handles logic & UI for the Search This Area
 *  and Reset Location buttons within the map container.
 *
 */
export const MapButtons: FC<React.PropsWithChildren<IMapButtonsProps>> = ({
  handleResetLocation,
  hasPanned,
  isDesktop,
  onPressSearch,
  zoom,
}) => {
  const { formatMessage } = useIntl();
  const { loadingUserCoordinates } = useGeolocation();

  const displaySearchButton =
    Platform.OS === 'ios'
      ? hasPanned && zoom <= DEFAULT_MAP_ZOOM
      : hasPanned && zoom >= DEFAULT_MAP_ZOOM;

  return (
    <Box flex={1} flexDirection="row" justifyContent="center">
      <Box
        alignSelf="center"
        display={displaySearchButton ? 'flex' : 'none'}
        marginX="$0"
        marginTop="$0"
        testID="map-buttons"
        justifySelf="center"
        marginBottom="$2.5"
      >
        <ActionButton
          size={isDesktop ? ActionButtonSizes.LARGE : ActionButtonSizes.SMALL}
          onPress={onPressSearch}
          testID="search-this-area-button"
        >
          {formatMessage({ id: 'searchThisArea' })}
        </ActionButton>
      </Box>

      <Box position="absolute" bottom={isDesktop ? '$8' : '$7'} right={'$4'} alignSelf="flex-end">
        <ResetLocationButton onButtonPress={handleResetLocation} loading={loadingUserCoordinates} />
      </Box>
    </Box>
  );
};
