import React, { FC } from 'react';

import { Box } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import {
  Header,
  HeaderBackground,
  HeaderWrapper,
  ToggleViewButtonWrapper,
} from '../../store-locator.styled';
import { ClearStoreSmButton } from '../clear-store-sm-button';
import { ToggleViewButton } from '../toggle-view-button';

import { ILocatorHeaderProps } from './types';

export const LocatorHeaderBase: FC<ILocatorHeaderProps> = ({ currentView, onViewChange }) => {
  const { formatMessage } = useIntl();
  const label = formatMessage({ id: 'selectLocation' });

  return (
    <HeaderBackground>
      <HeaderWrapper>
        <Box flexDirection={'row'}>
          <ClearStoreSmButton />
        </Box>
        <Box accessible focusable accessibilityLabel={label} accessibilityRole="header">
          <Header variant="headerThree" margin={0}>
            {label}
          </Header>
        </Box>

        <ToggleViewButtonWrapper>
          <ToggleViewButton currentView={currentView} updateStoreLocatorView={onViewChange} />
        </ToggleViewButtonWrapper>
      </HeaderWrapper>
    </HeaderBackground>
  );
};
