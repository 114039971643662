import { createContext, useContext, useMemo, useState } from 'react';

import { IRestaurant } from '@rbi-ctg/store';
import { ServiceMode } from 'generated/graphql-gateway';
import useEffectOnce from 'hooks/use-effect-once';
import { useEnableUnifiedMenu } from 'hooks/use-enable-unified-menu';
import { useLocale } from 'state/intl';
import { useServiceModeContext } from 'state/service-mode';
import { useStoreContext } from 'state/store';
import { getDateInTimezone } from 'utils/dateTime';
import { useMemoAll } from 'utils/use-memo-all';

import { findActiveDayPartIds } from './dayparts';
import { LocalizedText, Menu } from './temp/menu-api-aliases';
import { createMenu } from './temp/menu-mock';
import { IMainMenuNode, IndexedMenu } from './types';
import { createIndexedMenu, createMainMenuNode, getPosServiceMode } from './utils';

export interface IUnifiedMenuContext {
  indexedMenu: IndexedMenu;
  mainMenu: IMainMenuNode[];
  isLoading: boolean;
}

export const UnifiedMenuContext = createContext<IUnifiedMenuContext>({
  indexedMenu: IndexedMenu.EMPTY,
  mainMenu: [],
  isLoading: false,
});

UnifiedMenuContext.displayName = 'UnifiedMenuContext';

export const useUnifiedMenuContext = (): IUnifiedMenuContext => {
  const context = useContext(UnifiedMenuContext);
  if (!context) {
    throw new Error('useUnifiedMenuContext must be used within a UnifiedMenuProvider');
  }
  return context;
};

export const UnifiedMenuProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const isEnabled = useEnableUnifiedMenu();
  const { serviceMode } = useServiceModeContext();
  const { language } = useLocale();
  const { store } = useStoreContext();
  const [isLoading, setIsLoading] = useState(true);

  const menu = useMemo(() => maybeCreateMenu(store?.number, isEnabled), [store?.number, isEnabled]);

  const indexedMenu = useMemo(() => (menu ? createIndexedMenu(menu) : IndexedMenu.EMPTY), [menu]);
  const mainMenu = useMemo(
    () => maybeCreateMainMenu(menu, indexedMenu, serviceMode, store, language),
    [indexedMenu, menu, serviceMode, store, language]
  );

  const value: IUnifiedMenuContext = useMemoAll({
    mainMenu: mainMenu?.children ?? [],
    indexedMenu,
    isLoading,
  });

  useEffectOnce(() => {
    // TODO: @jlopez10-rbi Implement fetch menu data from API
    setIsLoading(false);
  });

  if (!isEnabled) {
    // if it is not enabled, don't bother with the provider
    return children;
  }
  return <UnifiedMenuContext.Provider value={value}>{children}</UnifiedMenuContext.Provider>;
};

function maybeCreateMenu(storeId: string | undefined | null, isEnabled: boolean): Menu | undefined {
  if (isEnabled && storeId) {
    return createMenu(storeId);
  }
  return undefined;
}

function maybeCreateMainMenu(
  menu: Menu | undefined,
  indexedMenu: IndexedMenu | undefined,
  serviceMode: ServiceMode | null,
  store: IRestaurant | undefined,
  language: keyof LocalizedText
): IMainMenuNode | undefined {
  if (indexedMenu && menu && serviceMode && store) {
    const now = store.timezone ? getDateInTimezone(store.timezone) : new Date();
    const activeDayPartIds = findActiveDayPartIds(menu.dayParts, now);
    return createMainMenuNode(indexedMenu, menu.rootMenu.id, {
      language,
      posServiceMode: getPosServiceMode(serviceMode),
      dayPartIds: activeDayPartIds,
    });
  }
  return undefined;
}
