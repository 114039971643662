import { useCallback, useMemo } from 'react';

import { orderBy } from 'lodash-es';

import { IRestaurant } from '@rbi-ctg/store';
import { useConfigValue } from 'hooks/configs/use-config-value';
import { useServiceModeStatusGenerator } from 'hooks/use-service-mode-status';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';

import { isMobileOrderingAvailable } from '.';

type SortOrderType = 'asc' | 'desc';

/**
 * Custom hook that provides a function to sort restaurants based on their availability for mobile ordering.
 * @returns A memoized function that sorts an array of restaurants by availability, either in ascending ('asc') or descending ('desc') order.
 */
export const useSortRestaurants = () => {
  const { generateServiceModeStatusForStore } = useServiceModeStatusGenerator();

  const isAlphaBetaStoreOrderingEnabled = useFlag(
    LaunchDarklyFlag.ENABLE_ALPHA_BETA_STORE_ORDERING
  );

  // Retrieve configuration values for restaurants, specifically the list of valid environments for mobile ordering.
  const { validMobileOrderingEnvs = [] } = useConfigValue({ key: 'restaurants', defaultValue: {} });

  /**
   * Determines if a restaurant is currently open and available for mobile ordering.
   *
   * @param restaurant The restaurant object to evaluate.
   * @returns True if the restaurant is open and available for mobile ordering, false otherwise.
   */
  const isOpenAndAvailable = useCallback(
    (restaurant: IRestaurant): boolean => {
      const hasMobileOrdering = isMobileOrderingAvailable(
        restaurant,
        isAlphaBetaStoreOrderingEnabled,
        validMobileOrderingEnvs
      );

      const hasHeartBeat = !!restaurant.available || !!restaurant.isAvailable;

      // If either of the above conditions fails, the restaurant is not considered available.
      if (!hasMobileOrdering || !hasHeartBeat) {
        return false;
      }

      const serviceModeAvailability = generateServiceModeStatusForStore(restaurant);

      // Check if any service mode is available:
      return Object.values(serviceModeAvailability).some(
        serviceMode =>
          serviceMode.capable && serviceMode.open && !serviceMode.disabled && serviceMode.available
      );
    },
    [generateServiceModeStatusForStore, isAlphaBetaStoreOrderingEnabled, validMobileOrderingEnvs]
  );

  /**
   * Sorts restaurants by availability.
   * @param restaurants The array of restaurants to sort.
   * @param order The sorting order ('asc' or 'desc').
   */
  const sortRestaurantByAvailability = useCallback(
    (restaurants: IRestaurant[], sortOrder: SortOrderType = 'desc') =>
      orderBy(restaurants, restaurant => isOpenAndAvailable(restaurant), [sortOrder]),
    [isOpenAndAvailable]
  );

  return useMemo(() => sortRestaurantByAvailability, [sortRestaurantByAvailability]);
};
