import { Svg, SvgImage, SvgProps, registerSVG } from '../../../components/svg';

export function ApplePayMark(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 -25 120 120">
      <SvgImage href={require('./ApplePayMark.png')} />
    </Svg>
  );
}

registerSVG('ApplePayMark', ApplePayMark);
