import { ContentCard } from '@braze/react-native-sdk';

import LocalStorage, { StorageKeys } from 'utils/local-storage';

import { CONTENT_CARD_FEED, ContentCardTypes, WebContentCard } from '../types';

export const parseWebContentCards = (webcards: WebContentCard[]): ContentCard[] => {
  return webcards
    .map(webcard => {
      const sharedProps = {
        id: webcard.id,
        url: webcard.url || '',
        image: webcard.imageUrl || '',
        created: new Date(webcard.created).getTime(),
        expiresAt: new Date(webcard.expiresAt).getTime(),
        pinned: webcard.pinned,
        dismissible: webcard.dismissible,
        dismissed: webcard.dismissed,
        clicked: webcard.clicked,
        viewed: webcard.viewed,
        isControl: webcard.isControl,
        extras: webcard.extras,
        openURLInWebView: false,
      };

      const cardTypeMap: Record<string, Partial<ContentCard>> = {
        'ab-captioned-image': {
          type: ContentCardTypes.CAPTIONED,
          title: webcard.title || '',
          cardDescription: webcard.description || '',
          domain: webcard.linkText || '',
          imageAspectRatio: webcard.aspectRatio,
        },
        'ab-banner ab-image-only': {
          type: ContentCardTypes.IMAGE_ONLY,
          imageAspectRatio: webcard.aspectRatio,
        },
        'ab-classic-card': {
          type: ContentCardTypes.CLASSIC,
          title: webcard.title || '',
          cardDescription: webcard.description || '',
          domain: webcard.linkText || '',
        },
      };

      const cardClassType = webcard.ic;
      if (!cardTypeMap[cardClassType] && !webcard.isControl) {
        return null;
      }

      return {
        ...sharedProps,
        ...cardTypeMap[cardClassType],
      } as ContentCard;
    })
    .filter((card): card is ContentCard => card !== null);
};

/**
 * Returns the array of Braze Content Cards that have been read by the user.
 * @returns an array of Read Content Cards IDs.
 */
export const getReadContentCardsFromLocalStorage = (): string[] => {
  return LocalStorage.getItem(StorageKeys.READ_CONTENT_CARDS) || [];
};

/**
 * Updates the Read Content Cards Array in Local Storage.
 * @param ids Array of Content Cards IDs to be marked as read.
 */
export const setReadContentCardsInLocalStorage = (ids: string[]) => {
  const readContentCards = getReadContentCardsFromLocalStorage();
  const uniqueReadContentCards = new Set([...readContentCards, ...ids]);

  LocalStorage.setItem(StorageKeys.READ_CONTENT_CARDS, [...uniqueReadContentCards]);
};

/**
 * Compares the current (non-expired) content cards with the ones stored in Local Storage.
 * @param validContentCardIds Array of IDs of non-expired Braze content cards.
 */
export const removeExpiredContentCardsFromLocalStorage = (validContentCardIds: string[]) => {
  const readContentCards = getReadContentCardsFromLocalStorage();
  const nonExpiredContentCards = readContentCards.filter(id => validContentCardIds.includes(id));

  LocalStorage.setItem(StorageKeys.READ_CONTENT_CARDS, nonExpiredContentCards);
};

// Cards without the feed property will be visible in both feeds. For the News feed specifically,
// we need to add this property to calculate unread messages and display the badge on the bell icon.
export const filterContentCardsByFeed = <T extends ContentCard | WebContentCard>({
  cards,
  isNewsFeedEnabled = false,
  isHomePageFeedEnabled = false,
}: {
  cards: T[];
  isNewsFeedEnabled: boolean;
  isHomePageFeedEnabled: boolean;
}): T[] => {
  let currentFeed = '';

  if (isNewsFeedEnabled) {
    currentFeed = CONTENT_CARD_FEED.NEWS;
  } else if (isHomePageFeedEnabled) {
    currentFeed = CONTENT_CARD_FEED.HOME_PAGE;
  }

  return cards
    .filter(({ extras }) => !extras.feed || extras.feed === currentFeed)
    .map(card => ({
      ...card,
      extras: {
        ...card.extras,
        ...(!card.extras.feed && currentFeed && { feed: currentFeed }),
      },
    }));
};
