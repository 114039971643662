import { useCallback, useState } from 'react';

import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';

import {
  ISocialLoginInput,
  IsoCountryCode,
  ProviderType,
  useCreateOtpIfUserExistsMutation,
  useCreateOtpMutation,
  useSignUpMutation,
  useSocialLoginMutation,
  useValidateAuthJwtMutation,
  useValidateOtpMutation,
} from 'generated/graphql-gateway';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { ModalCb } from 'hooks/use-error-modal';
import {
  signOut as cognitoSignOut,
  signUp as cognitoSignUp,
  validateLogin as cognitoValidateLogin,
} from 'remote/auth/cognito';
import { logRBIEvent, signInEvent, signOutEvent, signUpEvent } from 'state/amplitude';
import { EventTypes, SignInPhases } from 'state/amplitude/constants';
import { AuthenticationPath } from 'state/amplitude/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import AuthStorage from 'utils/cognito/storage';
import { platform, welcomeEmailDomain } from 'utils/environment';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { OTPAuthDeliveryMethod, isOTPSMSEnabled } from 'utils/otp';
import { routes } from 'utils/routing';
import SessionStorage from 'utils/session-storage';

import { SIGN_IN_FAIL } from '../constants';
import { JwtValidationError, OtpValidationError, UserNotFoundError } from '../errors';
import { ModalAuthScreen, ModalAuthState } from '../types';

import { useThirdPartyAuthentication } from './use-third-party-authentication';

const NON_SESSION_SPECIFIC_STORAGE_KEYS = [
  StorageKeys.LANGUAGE,
  StorageKeys.REGION,
  StorageKeys.LAST_TIME_COOKIES_ACCEPTED,
];

export interface IUseAccountAuthentication {
  refreshCurrentUser(): Promise<void>;
  openErrorDialog: ModalCb;
  setCurrentUser(session: null | CognitoUserSession): Promise<void>;
  setModalAuthState(modalAuthState: Partial<ModalAuthState>): void;
  modalAuthIsOpen: boolean;
}

interface INavigateOnSuccessParams {
  email: string;
}

interface ISignInNavigation {
  navigateOnSuccess?: boolean;
  navigateOnSuccessParams?: INavigateOnSuccessParams;
}

interface ISignInUserParams {
  email?: string;
  phoneNumber?: string;
  authenticationMethod?: string;
  socialAuthenticationType?: ProviderType;
  showSignUpIfUserNotExists?: boolean;
}

export interface ISignInResult {
  isSignUpFlow?: boolean;
  navigationCallback?: (navigateOnSuccessParams?: INavigateOnSuccessParams) => void;
}

export type ISignIn = ISignInNavigation & ISignInUserParams;

interface IFavoriteStore {
  storeId?: string;
  storeNumber: string;
}

interface ISignUp {
  email: string;
  name: string;
  dob?: string;
  phoneNumber: string;
  country: string;
  wantsPromotionalEmails: boolean;
  zipcode?: string;
  favoriteStores?: IFavoriteStore[];
  crmAttributes?: { source: string };
}

export interface ISignUpResult {
  jwt: string | null | undefined;
}

interface IValidateLogin {
  jwt: string;
  username: string;
}

interface IGetSesionIdAndChallengeCodeOtp {
  email?: string;
  phoneNumber?: string;
  otpCode: string;
  sessionId: string;
}

type IStoreOtpCredentials = { sessionId: string } & ISignInUserParams;

export const getStoredOtpCredentials = () => LocalStorage.getItem(StorageKeys.OTP);
export const storeOtpCredentials = (data: IStoreOtpCredentials) =>
  LocalStorage.setItem(StorageKeys.OTP, data);

export const useAccountAuthentication = ({
  refreshCurrentUser,
  openErrorDialog,
  setCurrentUser,
  setModalAuthState,
  modalAuthIsOpen,
}: IUseAccountAuthentication) => {
  const { formatMessage } = useIntl();

  const { logUserOutOfThirdPartyServices } = useThirdPartyAuthentication();
  const enableSignUpInBE = useFlag(LaunchDarklyFlag.ENABLE_COGNITO_SIGNUP_IN_BE);
  const preloaded = LocalStorage.getItem(StorageKeys.AUTH_REDIRECT) || {};
  const [originLocation, setOriginLoc] = useState<null | string>(preloaded.callbackUrl || null);
  const { navigate } = useNavigation();
  const { params: routeParams } = useRoute();
  const [validateLoginIsLoading, setValidateLoginIsLoading] = useState<boolean>(false);
  const authenticationPath = modalAuthIsOpen
    ? AuthenticationPath.CONTEXTUAL
    : AuthenticationPath.CONVENTIONAL;

  const [socialLoginMutation, { loading: socialLoginMutationLoading }] = useSocialLoginMutation();
  const [
    validateAuthJwtMutation,
    { loading: validateAuthMutationLoading },
  ] = useValidateAuthJwtMutation();
  const [createOtpMutation, { loading: createOtpMutationLoading }] = useCreateOtpMutation();
  const [
    createOtpIfUserExistsMutation,
    { loading: createOtpIfUserExistsMutationLoading },
  ] = useCreateOtpIfUserExistsMutation();
  const [validateOtpMutation, { loading: validateOtpMutationLoading }] = useValidateOtpMutation();
  const [signUpMutation] = useSignUpMutation();

  const getSessionIdAndChallengeCodeOtp = useCallback(
    async ({ email, phoneNumber, otpCode, sessionId }: IGetSesionIdAndChallengeCodeOtp) => {
      const { data } = await validateOtpMutation({
        variables: {
          input: {
            code: otpCode,
            email,
            phoneNumber,
            sessionId,
          },
        },
      });

      const { sessionId: validatedSessionId, challengeCode, email: validatedEmail } =
        data?.exchangeOTPCodeForCognitoCredentials ?? {};

      if (!validatedSessionId || !challengeCode) {
        throw new OtpValidationError('GraphQL validation failed');
      }

      return { sessionId: validatedSessionId, code: challengeCode, email: validatedEmail };
    },
    [validateOtpMutation]
  );

  const getSessionIdAndChallengeCode = useCallback(
    async (jwt: string) => {
      try {
        const { data } = await validateAuthJwtMutation({
          variables: {
            input: { jwt },
          },
        });
        const { sessionId, challengeCode } = data?.validateAuthJwt ?? {};
        if (!sessionId || !challengeCode) {
          throw new JwtValidationError();
        }

        return { sessionId, code: challengeCode };
      } catch (error) {
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        if (error?.originalError?.[0]?.message?.toLowerCase() === 'email not registered') {
          throw new UserNotFoundError();
        }
        throw error;
      }
    },
    [validateAuthJwtMutation]
  );

  const signOut = useCallback(
    async (isGlobalSignOut?: boolean) => {
      try {
        await cognitoSignOut(isGlobalSignOut);
        await setCurrentUser(null);

        signOutEvent(true);
        logUserOutOfThirdPartyServices();
        // Wipe SessionStorage
        SessionStorage.clearAll();
        // Once we have namespaced auth storage for all platforms we can remove the excluded
        // keys from here but for now we need to make sure we don't wipe all LocalStorage
        LocalStorage.clear({ excludeKeys: NON_SESSION_SPECIFIC_STORAGE_KEYS });
      } catch (error) {
        signOutEvent(false, (error as any).message);
        refreshCurrentUser();

        throw error;
      }
    },
    [setCurrentUser, logUserOutOfThirdPartyServices, refreshCurrentUser]
  );

  const signInWithOtp = useCallback(
    async (
      param: { email: string } | { phoneNumber: string },
      showSignUpIfUserNotExists: boolean = false
    ): Promise<ISignInResult> => {
      const sessionId = uuid();

      const input = {
        ...param,
        platform: platform(),
        sessionId,
      };

      if (showSignUpIfUserNotExists) {
        const { data } = await createOtpIfUserExistsMutation({
          variables: {
            input,
          },
        });
        // If user don't exists we move to the Sign Up flow
        if (!data?.createOTPIfUserExists.success) {
          return {
            isSignUpFlow: true,
          };
        }
      } else {
        // Legacy flow in case we don't want to show Sign Up if user doesn't exist
        await createOtpMutation({
          variables: {
            input,
          },
        });
      }
      // At this point we have created an OTP and we can show the OTP form
      // We also need to store the sessionId so that we can validate the OTP
      // If the user is on the modal auth flow we need to show the OTP form instead of navigating
      storeOtpCredentials({ ...param, sessionId });

      if (modalAuthIsOpen) {
        setModalAuthState({
          screen: ModalAuthScreen.OTP,
        });
      }

      return {
        isSignUpFlow: false,
        navigationCallback: (navigateOnSuccessParams?: INavigateOnSuccessParams) => {
          navigate(routes.signUp, {
            state: {
              ...routeParams,
              ...navigateOnSuccessParams,
              showOtpForm: true,
              activeRouteIsSignIn: true,
            },
            replace: true,
          });
        },
      };
    },
    [
      modalAuthIsOpen,
      createOtpIfUserExistsMutation,
      createOtpMutation,
      setModalAuthState,
      navigate,
      routeParams,
    ]
  );

  const signInUsingEnabledMethod = useCallback(
    async ({
      email,
      phoneNumber,
      otpMethod,
      showSignUpIfUserNotExists,
    }: Pick<ISignIn, 'email' | 'phoneNumber' | 'showSignUpIfUserNotExists'> & {
      otpMethod: OTPAuthDeliveryMethod;
    }): Promise<ISignInResult> => {
      const isSMSEnabled = isOTPSMSEnabled(otpMethod);

      if (isSMSEnabled && phoneNumber) {
        return signInWithOtp({ phoneNumber }, showSignUpIfUserNotExists);
      }

      if (!email) {
        throw new Error('No email provided and SMS login disabled');
      }

      return signInWithOtp({ email }, showSignUpIfUserNotExists);
    },
    [signInWithOtp]
  );

  const signIn = useCallback(
    async ({
      email,
      phoneNumber,
      navigateOnSuccess = true,
      navigateOnSuccessParams,
      authenticationMethod,
      socialAuthenticationType,
      showSignUpIfUserNotExists,
    }: ISignIn): Promise<void> => {
      const otpMethod: OTPAuthDeliveryMethod = OTPAuthDeliveryMethod.Email;

      try {
        const signInResult = await signInUsingEnabledMethod({
          email,
          phoneNumber,
          otpMethod,
          showSignUpIfUserNotExists,
        });

        if (signInResult.isSignUpFlow) {
          // User doesn't exist and is trying to Sign Up
          if (modalAuthIsOpen) {
            // Sign Up through the auth modal
            setModalAuthState({
              screen: ModalAuthScreen.SIGN_UP,
              shouldDisplaySignInSuccessMessage: false,
              user: { email },
            });
          } else {
            // Sign Up through the page
            navigate(routes.signUp, { state: { email } });
          }
        } else {
          // User already exists and is trying to Sign In
          signInEvent({
            phase: SignInPhases.START,
            success: true,
            otpMethod,
            authenticationMethod,
            socialAuthenticationType,
            authenticationPath,
          });
          // Log request
          logRBIEvent({
            name: 'Sign in with OTP Request',
            type: EventTypes.Other,
            attributes: {
              signUpType: email ? 'Email' : 'Phone Number',
            },
          });
          if (navigateOnSuccess && signInResult.navigationCallback) {
            signInResult.navigationCallback(navigateOnSuccessParams);
          }
        }
      } catch (error) {
        signInEvent({
          phase: SignInPhases.START,
          success: false,
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          message: error.message,
          otpMethod,
          authenticationMethod,
          socialAuthenticationType,
          authenticationPath,
        });
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        error.code = SIGN_IN_FAIL;
        throw error;
      }
    },
    [authenticationPath, modalAuthIsOpen, navigate, setModalAuthState, signInUsingEnabledMethod]
  );

  const signUp = useCallback(
    async (
      {
        email,
        name,
        dob,
        phoneNumber,
        country,
        wantsPromotionalEmails,
        zipcode,
        favoriteStores,
        crmAttributes,
      }: ISignUp,
      signInOverride: (args: ISignIn) => Promise<void> = signIn,
      authenticationMethod?: string,
      socialAuthenticationType?: ProviderType
    ): Promise<ISignUpResult> => {
      let jwt;
      try {
        if (enableSignUpInBE) {
          const { data } = await signUpMutation({
            variables: {
              input: {
                country: country as IsoCountryCode,
                dob,
                name,
                phoneNumber,
                platform: platform(),
                providerType: socialAuthenticationType,
                stage: welcomeEmailDomain(),
                userName: email,
                wantsPromotionalEmails,
                zipcode,
                favoriteStores,
                ...(crmAttributes && { crmAttributes }),
              },
            },
          });
          jwt = data?.signUp;
        } else {
          await cognitoSignUp({
            name,
            phoneNumber,
            username: email,
            country,
            wantsPromotionalEmails,
            dob,
          });
        }
      } catch (error) {
        signUpEvent({
          success: false,
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          message: error?.message,
          authenticationMethod,
          socialAuthenticationType,
          authenticationPath,
        });

        throw error;
      }

      signUpEvent({
        success: true,
        authenticationMethod,
        socialAuthenticationType,
        authenticationPath,
      });

      await signInOverride({
        email,
        phoneNumber,
      });

      return { jwt };
    },
    [authenticationPath, enableSignUpInBE, signIn, signUpMutation]
  );

  const validateLoginOtp = useCallback(
    async ({ otpCode }: { otpCode: string }) => {
      try {
        let { email } = getStoredOtpCredentials() ?? {};
        const { phoneNumber, sessionId: storedSessionId } = getStoredOtpCredentials() ?? {};

        if (!storedSessionId) {
          throw new OtpValidationError('Missing sessionId');
        }

        const validateOtpResponse = await getSessionIdAndChallengeCodeOtp({
          email,
          phoneNumber,
          otpCode,
          sessionId: storedSessionId,
        });

        if (!email) {
          email = validateOtpResponse.email;
        }

        const { sessionId, code } = validateOtpResponse;

        const session = await cognitoValidateLogin({ username: email, code, sessionId });
        // This must be set __BEFORE__ the `setCurrentUser` state update.
        // we use this local storage value to know if we should skip fetching the user data from sanity.
        // by setting this ahead of time, we know that the user is valid and we can fetch user data.
        AuthStorage.setItem(
          StorageKeys.USER_AUTH_TOKEN,
          session?.getAccessToken().payload.username
        );

        signInEvent({
          phase: SignInPhases.COMPLETE,
          success: true,
          otpMethod: OTPAuthDeliveryMethod.Email,
          authenticationMethod: 'OTP',
          socialAuthenticationType: undefined,
          authenticationPath,
        });

        await setCurrentUser(session);
      } catch (error) {
        signInEvent({
          phase: SignInPhases.COMPLETE,
          success: false,
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          message: error.message,
          otpMethod: OTPAuthDeliveryMethod.Email,
          authenticationMethod: 'OTP',
          socialAuthenticationType: undefined,
          authenticationPath,
        });

        throw error;
      }
    },
    [getSessionIdAndChallengeCodeOtp, authenticationPath, setCurrentUser]
  );

  const validateLogin = useCallback(
    async ({ jwt, username }: IValidateLogin) => {
      setValidateLoginIsLoading(true);
      try {
        const { sessionId, code } = await getSessionIdAndChallengeCode(jwt);
        const session = await cognitoValidateLogin({ username, code, sessionId });
        signInEvent({ phase: SignInPhases.COMPLETE, success: true });

        // This must be set __BEFORE__ the `setCurrentUser` state update.
        // we use this local storage value to know if we should skip fetching the user data from sanity.
        // by setting this ahead of time, we know that the user is valid and we can fetch user data.
        AuthStorage.setItem(
          StorageKeys.USER_AUTH_TOKEN,
          session?.getAccessToken().payload.username
        );
        await setCurrentUser(session);
        // Add a marker when a user is successfull login to track unexpected sign outs
        LocalStorage.setItem(StorageKeys.USER_SIGNED_IN_SUCCESSFULLY, true);
        setValidateLoginIsLoading(false);
      } catch (error) {
        setValidateLoginIsLoading(false);

        signInEvent({
          phase: SignInPhases.COMPLETE,
          success: false,
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          message: error.message,
        });
        openErrorDialog({
          // @ts-expect-error TS(2322) FIXME: Type 'unknown' is not assignable to type 'Error | ... Remove this comment to see the full error message
          error,
          message: formatMessage({ id: 'authError' }),
          modalAppearanceEventMessage: 'Error: JWT Validation Failure',
        });
        throw error;
      }
    },
    [formatMessage, getSessionIdAndChallengeCode, openErrorDialog, setCurrentUser]
  );

  const socialLogin = useCallback(
    async (input: ISocialLoginInput) => {
      try {
        const response = await socialLoginMutation({
          variables: {
            input,
          },
        });

        const session = await cognitoValidateLogin({
          username: response.data?.socialLogin?.email || '',
          code: response.data?.socialLogin?.challengeCode || '',
          sessionId: response.data?.socialLogin?.sessionId || '',
        });

        signInEvent({
          phase: SignInPhases.COMPLETE,
          success: true,
          authenticationMethod: 'Social',
          socialAuthenticationType: input.providerType,
          authenticationPath,
        });

        // This must be set __BEFORE__ the `setCurrentUser` state update.
        // we use this local storage value to know if we should skip fetching the user data from sanity.
        // by setting this ahead of time, we know that the user is valid and we can fetch user data.
        AuthStorage.setItem(
          StorageKeys.USER_AUTH_TOKEN,
          session?.getAccessToken().payload.username
        );

        await setCurrentUser(session);

        // Add a marker when a user is successfull login to track unexpected sign outs
        LocalStorage.setItem(StorageKeys.USER_SIGNED_IN_SUCCESSFULLY, true);
      } catch (error) {
        signInEvent({
          phase: SignInPhases.COMPLETE,
          success: false,
          // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
          message: error.message,
          authenticationMethod: 'Social',
          socialAuthenticationType: input.providerType,
          authenticationPath,
        });

        throw error;
      }
    },
    [authenticationPath, setCurrentUser, socialLoginMutation]
  );

  return {
    authLoading:
      validateAuthMutationLoading ||
      createOtpMutationLoading ||
      createOtpIfUserExistsMutationLoading ||
      validateOtpMutationLoading ||
      socialLoginMutationLoading ||
      validateLoginIsLoading,
    originLocation,
    setOriginLoc,
    signIn,
    signOut,
    signUp,
    socialLogin,
    validateLogin,
    validateLoginOtp,
  };
};
