import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { useIntl } from 'react-intl';

import { IRestaurant } from '@rbi-ctg/store';
import { OrderEtaContainer, OrderEtaDescription } from 'components/sanity-store-order-eta/styled';
import { useEnableMapListExperiment } from 'experiments/use-enable-map-list-experiment';
import { useRoute } from 'hooks/navigation/use-route';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { primitive } from 'styles/constants/primitives';
import { routes } from 'utils/routing';

export const StoreSanityOrderEta = ({ store }: { store: IRestaurant }) => {
  const { formatMessage } = useIntl();
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const mapListExperimentEnabled = useEnableMapListExperiment();
  const { pathname } = useRoute();

  if (!store.isOrderEtaEnabled || !store.orderEtaValue) {
    return null;
  }

  return (
    <OrderEtaContainer
      flex={isMobileBreakpoint && !mapListExperimentEnabled ? 1 : undefined}
      ml={
        !isMobileBreakpoint && ![routes.cartStoreConfirmation, routes.cart].includes(pathname)
          ? '$4'
          : undefined
      }
    >
      <MaterialIcons name="access-time-filled" size={16} color={primitive.bk.$bbqBrown} />
      <OrderEtaDescription>
        {`${store.orderEtaValue} ${formatMessage({ id: 'eta' })}`}
      </OrderEtaDescription>
    </OrderEtaContainer>
  );
};
