import { ContentCard } from 'utils/braze';

type ToggleContentCards = <E extends Element>(p: E) => void;

export type WebContentCard = {
  id: string;
  title: string | null;
  description: string | null;
  url: string | null;
  aspectRatio: number;
  viewed: boolean;
  imageUrl: string | null;
  created: string;
  updated: string;
  categories: string[];
  expiresAt: string;
  linkText: string | null;
  extras: Record<string, string>;
  pinned: boolean;
  dismissible: boolean;
  dismissed: boolean;
  clicked: boolean;
  isControl: boolean;
  test: boolean;
  la: string | null;
  le: string | null;
  ic: string;
  lc: boolean;
};

export const ContentCardTypes = {
  CLASSIC: 'Classic',
  IMAGE_ONLY: 'ImageOnly',
  CAPTIONED: 'Captioned',
} as const;

export const CUSTOM_ATTRIBUTE = {
  UNREAD_CONTENT_CARDS: 'unread_content_cards',
};

export const CONTENT_CARD_FEED = {
  NEWS: 'news',
  HOME_PAGE: 'home-page',
};

export const CONTENT_CARD_SETTING = {
  // The place where the content cards will be shown. one of CONTENT_CARD_FEED
  feed: 'feed',

  // Colors
  // It is a Content Card for a Campaign - boolean string or undefined. eg 'true'
  campaign: 'campaign',
  // Hex color for the CTA button text
  buttonTextColor: 'button-text-color',
  // Hex color for the CTA button background
  buttonBackgroundColor: 'button-background-color',
  // Hex color for card background
  backgroundColor: 'background-color',
  // Hex color for card text
  textColor: 'text-color',

  // Content
  // Alternative text content for the Content Card image
  imageAlt: 'image-alt',

  // Terms
  // Terms content
  terms: 'terms',
  // Terms link text
  termsButtonActionText: 'terms-button-action-text',
  // Terms link url
  termsButtonActionUrl: 'terms-button-action-url',

  // Rules
  // Days of the week the Content Card will be shown. string comma separated. eg. 'sunday, monday, wednesday'
  daysOfWeek: 'days-of-week',
  // Start times and end times, separated by semicolons. eg. '10:30,23:59; 00:00,05:59'
  timeRanges: 'time-ranges',
};

export interface IBrazeContext {
  initBraze: () => void;
  getCachedContentCards(): Promise<ContentCard[]>;
  setUserId(userId: string | null): void;
  toggleContentCards: ToggleContentCards;
  isInAppMessageOpen: boolean;
  setIsInAppMessageOpen: (value: boolean) => void;
  logContentCardImpression(id: string): void;
  logContentCardClicked(card: ContentCard, logClickEvent?: boolean): void;
}
