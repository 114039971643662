import { Box, Icon, Image, Text } from '@rbilabs/universal-components';

import { ResizeMode } from 'components/picture/types';
import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';

export const CardsContainer = Box.withConfig({
  _web: {
    alignSelf: 'center',
    width: 'full',
    maxWidth: '912px',
    paddingX: '$4',
  },
});

export const StyledBackIcon = Icon.withConfig({
  color: theme.token('icon-header-contrast'),
  size: '5',
  ...hiddenAccessibilityPlatformProps,
});

export const UnreadSeparator = Box.withConfig({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingX: '$2',
  paddingY: '$1.5',
});

export const CenteredLine = Box.withConfig({
  flexGrow: 1,
  borderTopWidth: 1,
  borderTopStyle: 'solid',
  borderColor: primitive.$blackOpacity50,
  marginX: '$2',
});

export const UnreadSeparatorText = Text.withConfig({
  color: primitive.$blackOpacity50,
});

export const StyledPicture = Image.withConfig({
  width: 'full',
  height: '186px',
  resizeMode: ResizeMode.contain,
  marginTop: '$12',
});

export const EmptyInboxTitle = Text.withConfig({
  variant: 'headerTwo',
  textAlign: 'center',
  marginTop: '$4',
  marginBottom: '$4',
  color: primitive.bk.$bbqBrown,
});

export const EmptyInboxCaption = Text.withConfig({
  alignSelf: 'center',
  width: '270px',
  textAlign: 'center',
  color: primitive.bk.$bbqBrown,
});
