import { platform } from 'os';

import React, { useEffect } from 'react';

import ErrorNetwork from 'components/error-network';
import LanguageSelectorModal from 'components/language-selector-modal';
import { useLayoutLanguageSelectorModal } from 'components/layout/use-layout-language-selector-modal';
import { useAppStateChange } from 'hooks/app-state-change/use-app-state-change';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useResetStoreResetCartResetServiceModeTimeout } from 'hooks/reset-store-timeout';
import useEffectOnce from 'hooks/use-effect-once';
import { useForter } from 'hooks/use-forter';
import useBranch from 'state/branch/hooks/use-branch';
import { checkForOSPrompts } from 'state/location/routes';
import { brand, isLocalDev } from 'utils/environment';
import { DeeplinkEventArgs, EventName, addEventListener } from 'utils/event-hub';

import { AppContainerInner } from './app-container-inner';
import { Wrapper } from './wrapper';

// Create start time before trying to render the app for true performance numbers
const LOADING_START_TIME = performance.now?.() ?? 0;
/**
 * Once the React Navigate is fully tested and in PROD, we can deprecate the ENABLE_REACT_NATIVE_NAVIGATION feature flag.
 * At which point this file will have its web counterpart instead of app-container-rn and app-container-web.
 */
export const AppContainer: React.FC = React.memo(() => {
  const { showLanguageSelectorModal, handleModalDismiss } = useLayoutLanguageSelectorModal();
  const { navigate } = useNavigation();
  const { initBranch } = useBranch();

  useEffect(() => {
    if (!isLocalDev) {
      const renderSuccessTime = performance.now?.();
      const durationTillRenderSuccess = renderSuccessTime - LOADING_START_TIME;
      (window.dataLayer || []).push({
        event: 'APP_REACT_RENDER_SUCCESS',
        brand: brand(),
        timestamp: renderSuccessTime,
        duration: durationTillRenderSuccess,
        platform: platform(),
      });
    }
  }, []);

  useResetStoreResetCartResetServiceModeTimeout();
  useForter();
  useAppStateChange();

  // Initialize Deeplink listeners
  useEffectOnce(() => {
    addEventListener<DeeplinkEventArgs>(EventName.DEEPLINK, deeplinkArgs => {
      // Ensure removing deeplink extra slash for RN
      const sanitizeRegex = /[/]+/;
      const deepLinkPath = deeplinkArgs.route.replace(sanitizeRegex, '/');

      if (!checkForOSPrompts(deepLinkPath)) {
        navigate(deepLinkPath);
      }
    });

    // Ensure deep link listener singleton is instantiated
    // TODO: RN-WEB - Implement Branch deeplinks
    //DeeplinksListener.getInstance();

    if (window && !window.branch) {
      initBranch();
    }
  });

  return (
    <Wrapper>
      <AppContainerInner />
      {/* Stuff rendered outside the typical flow */}
      {showLanguageSelectorModal ? (
        <LanguageSelectorModal onModalDismiss={handleModalDismiss} />
      ) : null}
      <ErrorNetwork />
    </Wrapper>
  );
});
