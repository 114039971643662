import { useMemo } from 'react';

import { Divider, HStack, Icon, Pressable, Text } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { ActionsheetWrapper } from 'components/action-sheet';
import { InnerLayout, LayoutContainer } from 'components/layout/styled';
import SignUpHeadline from 'pages/authentication/components/sign-up-headline/sign-up-headline';
import ConfirmOtp from 'pages/authentication/confirm-otp';
import { SignInForm } from 'pages/authentication/sign-in/sign-in-form';
import SignUp from 'pages/authentication/sign-up';
import { useAuthContext } from 'state/auth';
import { ModalAuthScreen } from 'state/auth/types';
import { theme } from 'styles/configure-theme';

import { ModalAuthActionSheetContent } from './components';

export const ModalAuth = () => {
  const { modalAuthState, modalAuthIsOpen, closeModalAuth, openSignInModal } = useAuthContext();
  const { formatMessage } = useIntl();
  const getModalAuthComponent = () =>
    ModalAuthComponents[modalAuthState.screen || ModalAuthScreen.CLOSED];

  const isSignInPage = modalAuthState.screen === ModalAuthScreen.SIGN_IN;

  const handleBackNavigation = () => (isSignInPage ? closeModalAuth() : openSignInModal());

  const ModalAuthComponents = useMemo(
    () => ({
      [ModalAuthScreen.SIGN_IN]: <SignInForm />,
      [ModalAuthScreen.SIGN_UP]: <SignUp skipRedirect />,
      [ModalAuthScreen.OTP]: <ConfirmOtp />,
      [ModalAuthScreen.CLOSED]: null,
    }),
    []
  );

  if (!modalAuthIsOpen) {
    return null;
  }

  return (
    <ActionsheetWrapper
      isOpen
      onOpenEventMessage="Open modal authentication"
      onClose={closeModalAuth}
    >
      <ModalAuthActionSheetContent>
        <HStack height="$11" padding="$2">
          {!isSignInPage && (
            <Pressable justifyContent="center" onPress={handleBackNavigation}>
              <Icon
                name="custom:Back"
                color={theme.token('icon-default')}
                title={formatMessage({ id: 'back' })}
                size={5}
              />
            </Pressable>
          )}
          <Pressable onPress={closeModalAuth} justifyContent="center" marginLeft="auto">
            <Icon
              variant="cancel"
              color={theme.token('icon-default')}
              title={formatMessage({ id: 'cancel' })}
            />
          </Pressable>
        </HStack>

        <LayoutContainer isModal width="full">
          <InnerLayout>
            <SignUpHeadline disableLink={modalAuthState.shouldDisableHeadlineLinks} />
            <Divider background="blackOpacity.30" marginTop="$6" marginBottom="$6" />
            {modalAuthState.heading && <Text marginBottom="$6">{modalAuthState.heading}</Text>}
            {getModalAuthComponent()}
          </InnerLayout>
        </LayoutContainer>
      </ModalAuthActionSheetContent>
    </ActionsheetWrapper>
  );
};
