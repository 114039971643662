import { Box, Text } from '@rbilabs/universal-components';

import { primitive } from 'styles/constants/primitives';

export const PictureContainer = Box.withConfig({
  backgroundColor: primitive.$white,
  borderWidth: 1,
  borderColor: primitive.$blackOpacity30,
  borderRadius: Styles.borderRadius,
  shadow: '1',
  padding: '$2',
  marginRight: '$4',
  width: {
    base: '20%',
    md: '10%',
  },
  justifyContent: 'center',
  alignItems: 'center',
});

export const TextMessageContainer = Box.withConfig({
  width: {
    base: '70%',
    md: '80%',
  },
});

export const TextMessage = Text.withConfig({
  variant: 'copyOne',
  fontWeight: 'bold',
  width: 'full',
});

export const AddToCartContainer = Box.withConfig({
  width: 'full',
  flexDirection: 'row',
  alignItems: 'center',
});

export const IconContainer = Box.withConfig({ position: 'absolute', top: -10, right: -10 });
