// all "boolean" type fields will be strings with one of the following two values
export const TRUE = 'True';
export const FALSE = 'False';

// inconsistent values for boolean string; could be any of the three strings
export const TRUE_VALS = ['Y', 'true', TRUE];
export const FALSE_VALS = ['N', 'false', FALSE];

// Defines keys to be used for amplitude reporting
// The keys will be used to group messages so it's important to be consistent
// when calling an error or custom event
//
// Add new actions here
export enum CustomEventNames {
  ACTION_SHEET_SUBMISSION = 'Action Sheet Submission',
  ACTION_SHEET_VIEW = 'Action Sheet View',
  ACTIVATE_WALMART_PLUS_ACCOUNT = 'Activate Walmart Plus Account',
  AUTH_DRAWER_VIEW = 'Auth Drawer View',
  FOUNDATION_DONATIONS_VIEW = 'Foundation Donations View',
  ADD_FAVORITE_TO_CART = 'Add Favorite to Cart',
  ADD_ITEM_TO_FAVORITES = 'Add Item To Favorites',
  ADD_ITEMS_TO_FAVORITE = 'Add Items to Favorite',
  ADD_RECENT_ITEM_TO_CART = 'Add Recent Item To Cart',
  ADD_TO_CART = 'Add to Cart',
  APP_ERROR = 'Application Error',
  APP_ERROR_RELOAD = 'Application Error Reload Click',
  APP_INITIALIZED = 'Application Initialized',
  APP_REVIEW_POP_UP = 'App Review Pop Up',
  APP_TRACKING_REQUEST_PERMISSION = 'App Tracking Request Permission',
  AUTO_SIGN_IN = 'Auto Sign In',
  AUTO_SIGN_IN_COMPLETE = 'Auto Sign In Complete',
  AUTO_SIGN_IN_START = 'Auto Sign In Start',
  BOTTOM_SERVICE_CART = 'Bottom Service Cart',
  BUTTON_CLICK = 'Button Click',
  BUTTON_CLICK_ORDER_HERE = 'Order Here',
  BUTTON_CLICK_DUPLICATE_ITEM = 'Duplicate item',
  BUTTON_CLICK_CART_ITEM_REMOVED = 'Cart item removed',
  BUTTON_CLICK_SERVICE_MODE_WIDGET = 'Service Mode widget',
  BUTTON_CLICK_KEEP_CURRENT_LOCATION = 'Keep Current Location',
  BUTTON_CLICK_ACCOUNT_DELETE = 'Account Delete',
  BUTTON_CLICK_ACCOUNT_DELETE_ONE_TRUST = 'Account Delete One Trust',
  BUTTON_CLICK_ACCOUNT_INFO = 'Account Info',
  BUTTON_CLICK_ADD_PAYMENT_METHOD = 'Add New Payment Method',
  BUTTON_CLICK_BK_REWARDS = 'BK Rewards',
  BUTTON_CLICK_CHANGE_DELIVERY_ADDRESS = 'Change delivery address',
  BUTTON_CLICK_COMMUNICATION_PREFERENCES = 'Communication Preferences',
  BUTTON_CLICK_DELIVER_HERE = 'Deliver Here',
  BUTTON_CLICK_EMAIL_PROFILE_INFO = 'Email Me My Profile Info',
  BUTTON_CLICK_MENU = 'MENU',
  BUTTON_CLICK_MENU_WIZARD_CONTINUE = 'Menu Wizard Continue',
  BUTTON_CLICK_NO_DELIVERY_OFFER_FIND_RESTAURANTS = 'Find Restaurants',
  BUTTON_CLICK_NO_DELIVERY_OFFER_SHOW_DEALS = 'Show me Deals',
  BUTTON_CLICK_ORDER = 'Order',
  BUTTON_CLICK_ORDER_MISSING_ITEMS_SUBMIT = 'Item(s) Never Arrived',
  BUTTON_CLICK_ORDER_NEVER_ARRIVED_SUBMIT = 'Order Never Arrived',
  BUTTON_CLICK_OTHER_LOCATION = 'Click Change Store Button',
  BUTTON_CLICK_PARTNERSHIPS = 'Partnerships',
  BUTTON_CLICK_PAYMENT_METHODS = 'Payment Methods',
  BUTTON_CLICK_PICKUP = 'Pick Up Mode Selected',
  BUTTON_CLICK_PLACE_ORDER = 'Place Order',
  BUTTON_CLICK_RECENT_ORDER_NEED_HELP = 'Need Help',
  BUTTON_CLICK_RECENT_ORDERS = 'Recent Orders',
  BUTTON_CLICK_REDEEM_NOW = 'Redeem Now',
  BUTTON_CLICK_REQUEST_MY_INFORMATION = 'Request My Information',
  BUTTON_CLICK_SERVICE_MODE_TOGGLE = 'Service Mode Toggle Click',
  BUTTON_CLICK_SIGN_IN = 'Sign In',
  BUTTON_CLICK_SIGN_OUT = 'Sign Out',
  BUTTON_CLICK_SIGN_UP = 'Sign Up',
  BUTTON_CLICK_STATIC_PAGES = 'Static Pages',
  BUTTON_CLICK_STORE_BUTTON = 'Store Button Clicked',
  BUTTON_CLICK_STORE_LOCATOR_TAB = 'Store Locator Tab',
  BUTTON_CLICK_STORE_LOCATOR_VIEW = 'Store Locator View',
  BUTTON_CLICK_STORE_SELECTED = 'Store Selected',
  BUTTON_CLICK_TIMS_CARD_RELOAD = 'Tims Card Reload',
  CHECKOUT = 'Checkout',
  CHECKOUT_DELIVERY_MINIMUM_NOT_REACHED = 'Checkout delivery minimum not reached',
  CHECKOUT_LIMIT_REACHED = 'Checkout limit reached',
  CHECKOUT_MOBILE = 'Mobile Checkout',
  CLICK_EVENT = 'Click',
  COMPLETE_REGISTRATION = 'Complete Registration',
  DELETE_PAYMENT_METHOD = 'Delete Payment Method',
  DELIVERY_ADDRESS_SUBMITTED = 'Delivery Address Submitted',
  DELIVERY_MINIMUM_NOT_REACHED = 'Delivery Minimum Not Reached',
  DELIVERY_STORE_ASSIGNED = 'Delivery Store Assigned',
  E_COMMERCE_ADD_TO_CART = 'eCommerce - AddToCart',
  E_COMMERCE_CHECKOUT = 'eCommerce - Checkout',
  E_COMMERCE_PURCHASE = 'eCommerce - Purchase',
  E_COMMERCE_PURCHASE_ITEM = 'eCommerce - Purchase - Item',
  E_COMMERCE_REMOVE_FROM_CART = 'eCommerce - RemoveFromCart',
  E_COMMERCE_VIEW_DETAIL = 'eCommerce - ViewDetail',
  EDIT_CART = 'Edit Cart',
  EDIT_FAVORITE = 'Edit Favorite',
  FIRST_DELIVERY_QUOTE = 'First Delivery Quote',
  FLAGS_EVALUATED = 'Flags Evaluated',
  GIFT_CARD_BALANCE_TRANSFER = 'Gift Card Balance Transfer',
  HERO_COMPLEMENTARY_CTA_CLICK = 'Hero Complementary CTA Click',
  HERO_PRIMARY_CTA_CLICK = 'Hero Primary CTA Click',
  ITEM_QUANTITY_MODIFIED = 'Item Quantity Modified',
  LEGACY_OFFER_ADDED_IN_STORE_ORDER = 'Legacy Offer Added to In Store Order',
  LOAD_MORE = 'Load More',
  LOCALE_SELECTOR_MODAL_SHOWN = 'Locale Selector Modal Shown',
  LOGIN = 'Login',
  LOYALTY_ACKNOWLEDGEMENT = 'Loyalty Acknowledgement',
  LOYALTY_CODE_SUBMITTED = 'Loyalty Code Submitted',
  MANUAL_LOCATION_SEARCH = 'Manual location search',
  MARKETING_TILE_CLICK = 'Marketing Tile Click',
  MENU_CARD_DISPLAYED = 'Menu Card Displayed',
  MODAL_APPEARANCE = 'Modal Appearance',
  NAV_CLICK = 'Mobile Navigation Link Click',
  NETWORK_ERROR = 'Network Error Modal',
  NETWORK_ERROR_CUSTOMER_INITIATED_REFUND = 'Network Error Customer Initiated Refund',
  NEW_PAYMENT_METHOD_SAVED = 'New Payment Method Saved',
  OFFER_ACTIVATED = 'Offer activated',
  OFFER_ADDED = 'Offers added to cart',
  OFFER_ADDED_TO_ORDER = 'Offer Added to Order',
  OFFER_DETAILS_VIEWED = 'Offer Details Viewed',
  OFFER_SELECTED = 'Offer Selected',
  OFFERS_DISPLAYED = 'Offers Displayed',
  ONBOARDING_DATA = 'Onboarding Data',
  ORDER_HERE_STORE_CONFIRMATION_FLOW = 'Order Here Store Confirmation Flow Initiated',
  OTP_SIGN_IN_START = 'One Time Password Sign In Start',
  OTP_SIGN_IN_VALIDATION = 'One Time Password Code Validation',
  OTP_SIGN_UP = 'OTP Sign Up Complete',
  PAGE_NOT_FOUND = 'Page not found',
  PAGE_VIEW = 'Page View',
  PAYMENT_METHOD_SELECTED = 'Payment Method Selected',
  PRODUCT_FAVORITE_BUTTON_CLICKED = 'Product Favorite Button Clicked',
  PROMO_CODE_APPLIED = 'Promo Code Applied',
  PURCHASE = 'Purchase',
  PURCHASE_DELIVERY = 'Purchase Delivery',
  PURCHASE_PICK_UP = 'Purchase Pick Up',
  PUSH_NOTIFICATIONS_RESPONSE = 'Push Notifications Response',
  QUEST_DETAILS_VIEW = 'Quest Details Viewed',
  REFUND_ELIGIBILITY_CHECK = 'Refund Eligibility Check',
  RESTAURANTS_FOUND = 'Restaurants Found',
  REWARD_ADDED_TO_ORDER = 'Reward Added to Order',
  REWARD_DETAILS_VIEWED = 'Reward Details Viewed',
  REWARD_SELECTED = 'Reward Selected',
  SAVE_NEW_PAYMENT_METHOD = 'Save New Payment Method',
  SECTION_SELECTED = 'Section Selected',
  SELECT_DELIVERY_SAVED_ADDRESS = 'Select Delivery Saved Address',
  SELECT_SERVICE_MODE = 'Select Service Mode',
  SELECT_TIMED_FIRE_OPTION = 'Select Timed Fire Option',
  SESSION_RESET_FROM_INACTIVITY = 'Session Reset From Inactivity',
  SIGN_IN_COMPLETE = 'Sign In Complete',
  SIGN_IN_OTP_ATTEMPT = 'Sign in with OTP Attempt',
  SIGN_IN_OTP_RE_REQUEST = 'Sign in with OTP Re-Request',
  SIGN_IN_OTP_REQUEST = 'Sign in with OTP Request',
  SIGN_IN_START = 'Sign In Start',
  SIGN_OUT = 'Sign Out Complete',
  SIGN_UP = 'Sign Up Complete',
  SIGN_UP_FLOW_SUCCESSFUL = 'Sign Up Flow Successful',
  SIGN_UP_SUBMITTED = 'Sign Up Submitted',
  SIGN_UP_SUCCESSFUL = 'Sign Up Successful',
  SNAP_QR_CODE = 'Snap QR Code',
  STORE_DELIVERY_INVALID_HOO = 'Store Invalid Hours of Operation for Delivery',
  STORE_FAVORITE_BUTTON_CLICKED = 'Store Favorite Button Clicked',
  STORE_MAP_GET_DIRECTIONS = 'Order Detail Page Get Directions Link Click',
  SUBMIT_PAYMENT = 'Submit Payment',
  SUPPORT_FORM_NAVIGATION_DECISION = 'Support Form Navigation Decision',
  SUPPORT_FORM_SUBMIT = 'Submit Support Form',
  TABBED_HEADER = 'Tabbed Header Click',
  TOP_SERVICE_MODE = 'Top Service Mode',
  UNEXPECTED_SIGN_OUTS = 'Unexpected Sign Outs',
  REFRESH_TOKEN_EXPIRED = 'Refresh Token Expired',
  UPDATE_USER_ATTRIBUTES = 'Update User Attributes',
  UPSELL_ADDED = 'Upsell Added',
  UPSELL_DISPLAYED = 'Upsell Displayed',
  UPSELL_REMOVED = 'Upsell Removed',
  UPSIZE_DISPLAYED = 'Upsize Displayed',
  UPSIZE_SELECTION = 'Upsize Selection',
  ITEM_DETAIL_REORDER = 'Item Detail Reorder',
  LAST_ORDER_REORDER = 'Last Order Reorder',
  LAST_ORDER_DETAILS = 'Last Order Details',
  LAST_ORDER_HOME_VIEW = 'Last Order Home View',
  ORDER_STATUS_CARD_ORDER_DETAIL_CLICK = 'Homepage Tracker Order Detail',
  LAST_ITEM_REORDER_CARD_VIEW = 'Last Item Reorder Card View',
  SOCIAL_LOGIN_APPLE_CLICK = 'Social Login Apple Click',
  SOCIAL_LOGIN_APPLE_SUCCESS = 'Social Login Apple Success',
  SOCIAL_LOGIN_APPLE_COMPLETE = 'Social Login Apple Complete',
  SOCIAL_LOGIN_APPLE_ERROR = 'Social Login Apple Error',
  SOCIAL_LOGIN_FACEBOOK_CLICK = 'Social Login Facebook Click',
  SOCIAL_LOGIN_FACEBOOK_SUCCESS = 'Social Login Facebook Success',
  SOCIAL_LOGIN_FACEBOOK_COMPLETE = 'Social Login Facebook Complete',
  SOCIAL_LOGIN_FACEBOOK_ERROR = 'Social Login Facebook Error',
  SOCIAL_LOGIN_GOOGLE_CLICK = 'Social Login Google Click',
  SOCIAL_LOGIN_GOOGLE_SUCCESS = 'Social Login Google Success',
  SOCIAL_LOGIN_GOOGLE_ERROR = 'Social Login Google Error',
  SOCIAL_LOGIN_GOOGLE_COMPLETE = 'Social Login Google Complete',
  LIVE_ACTIVITY_TRACKER_VIEW = 'Live Activity Tracker View',
  APP_FOREGROUND = 'App Foreground',
  STORE_AUTO_SELECTED = 'Store Auto Selected',
  LOCATE_ME = 'Locate me',
}

export enum ClickEventComponentNames {
  STORE_LOCATOR_MAP_MARKER = 'Store Locator Map Marker',
  MARKETING_TILE = 'Marketing Tile',
  NAV_BAR = 'Nav Bar',
  BUTTON = 'button',
  PRODUCT_MODIFIER_CUSTOMIZATION = 'Product Modifier Customization',
  PRODUCT_SELECTION_MODIFICATION = 'Product Selection Modification',
  PRODUCT_SECTION_TILES = 'Product Sections Tiles',
  PRODUCT_SECTION_TABS = 'Product Sections Tabs',
  PRODUCTS = 'Products',
  PROMO_CODE_TILE = 'Promo Code Tile',
  LIVE_ACTIVITY_DEEPLINK = 'Live Activity Deeplink',
}

export enum SignInPhases {
  START = 'start',
  COMPLETE = 'complete',
}

// We can share EventTypes enum between web and RN because the enum values are the same
// between RN and web for amplitude SDK
export enum EventTypes {
  Navigation = 1,
  Location = 2,
  Search = 3,
  Transaction = 4,
  UserContent = 5,
  UserPreference = 6,
  Social = 7,
  Other = 8,
}

// We can share ProductActionTypes enum between web and RN because the enum values are the same
// between RN and web for amplitude SDK
export enum ProductActionTypes {
  AddToCart = 1,
  RemoveFromCart = 2,
  Checkout = 3,
  CheckoutOption = 4,
  Click = 5,
  ViewDetail = 6,
  Purchase = 7,
  Refund = 8,
  AddToWishlist = 9,
  RemoveFromWishlist = 10,
}
