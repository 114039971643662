import React, { useCallback, useEffect, useState } from 'react';

import { Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { GeolocationDeniedModal } from 'components/geolocation-denied-modal';
import { ResetLocationAnimation } from 'components/lottie-animations/reset-location/reset-location';
import { useGeolocation } from 'state/geolocation';
import { isExpoLocationPromptOpen } from 'utils/geolocation';

import { Button } from './reset-location-button.styled';
import { IResetLocationButtonProps } from './types';

export const ResetLocationButton: React.FC<React.PropsWithChildren<IResetLocationButtonProps>> = ({
  onButtonPress,
  loading,
}) => {
  const [status, setStatus] = useState<'idle' | 'transitioning'>('idle');
  const { formatMessage } = useIntl();

  const {
    isPermissionGranted,
    isPermissionPrompt,
    isPermissionDenied,
    setPermissionChecking,
    loadingUserCoordinates,
  } = useGeolocation();

  const [geolocationDeniedModalOpen, setGeolocationDeniedModalOpen] = useState(false);

  const dismissModal = () => setGeolocationDeniedModalOpen(false);

  const handlePress = useCallback(() => {
    setStatus('transitioning');
    if (isPermissionDenied) {
      setGeolocationDeniedModalOpen(true);
    } else if (isPermissionPrompt) {
      setPermissionChecking();
    }
  }, [isPermissionDenied, isPermissionPrompt, onButtonPress, setPermissionChecking]);

  const getStartIcon = () => {
    if (loading || loadingUserCoordinates) {
      return <ResetLocationAnimation />;
    }

    if (status === 'transitioning') {
      return (
        <ResetLocationAnimation
          onComplete={() => {
            onButtonPress();
            setStatus('idle');
          }}
        />
      );
    }

    return <Icon variant="location" />;
  };

  useEffect(() => {
    (async () => {
      const nativePromptOpen = await isExpoLocationPromptOpen();
      const shouldOpenModal = !nativePromptOpen && isPermissionDenied;
      setGeolocationDeniedModalOpen(shouldOpenModal);
    })();
  }, [isPermissionDenied]);

  useEffect(() => {
    if (isPermissionGranted) {
      setGeolocationDeniedModalOpen(false);
    }
  }, [isPermissionGranted]);

  return (
    <>
      <GeolocationDeniedModal isOpen={geolocationDeniedModalOpen} dismissModal={dismissModal} />
      <Button
        variant="unstyled"
        size="md"
        accessibilityLabel={formatMessage({ id: 'resetLocationButtonAria' })}
        testID="reset-location-button"
        onPress={handlePress}
        isDisabled={loading}
        icon={getStartIcon()}
      />
    </>
  );
};
