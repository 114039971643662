import { IncentiveSource, LoyaltyOffer, LoyaltyOfferGroup } from 'state/loyalty/types';
import { maybeLocaleImages } from 'utils/graphql';
import { blockContentToPlainText } from 'utils/sanity';

import { AnimatedOfferItem } from '../loyalty-incentives-components/animated-offer-item';
import { parseIncentiveData } from '../loyalty-incentives-components/incentive-card/parse-incentive-data';
import { LoyaltyOfferGroupItem } from '../loyalty-incentives-components/loyalty-offer-group-item';
import { LoyaltyOffersItem } from '../loyalty-incentives-components/loyalty-offers-item';

export const renderOfferItem = ({
  item,
  handlePress,
  skipAnimation,
}: {
  item: LoyaltyOffer;
  handlePress: (offer: LoyaltyOffer) => void;
  skipAnimation: boolean;
}) => {
  const incentiveData = parseIncentiveData(item);
  const isPromoCode = (incentiveData.metadata || []).some(
    data => data?.value === IncentiveSource.PROMOTION_CODE
  );
  const _renderOfferItem = () => (
    <LoyaltyOffersItem handlePress={() => handlePress(item)} incentiveData={incentiveData} />
  );
  // Animate only if skipAnimation is disabled and offer source is Promo Code campaing
  return !skipAnimation && isPromoCode ? (
    <AnimatedOfferItem setSkipAnimation={(skip: boolean) => (skipAnimation = skip)}>
      {_renderOfferItem()}
    </AnimatedOfferItem>
  ) : (
    _renderOfferItem()
  );
};

const renderOfferGroupItem = ({
  item,
  handlePress,
}: {
  item: LoyaltyOfferGroup;
  handlePress: (group: LoyaltyOfferGroup) => void;
}) => {
  if (!item.groupId) {
    return null;
  }

  const image = maybeLocaleImages(item?.localizedImage);
  const name = blockContentToPlainText(item?.name?.localeRaw);
  const description = blockContentToPlainText(item?.description?.localeRaw);

  // Collect all unique end dates from the offers in the group
  const offersEndDates = new Set<string>();
  item.offers?.forEach(offer => {
    if (offer?.endDate) {
      offersEndDates.add(offer.endDate);
    }
  });

  // Only show the end date if all offers in the group have the same end date
  let endDate: string | undefined;
  if (offersEndDates.size === 1 && item.offers.length) {
    const [commonEndDate] = offersEndDates;
    if (commonEndDate && commonEndDate.slice(0, 4) !== '9999') {
      endDate = commonEndDate;
    }
  }

  return (
    <LoyaltyOfferGroupItem
      groupId={item.groupId}
      name={name}
      description={description}
      image={image}
      imageDescription={description}
      handlePress={() => handlePress(item)}
      endDate={endDate}
    />
  );
};

export const LoyaltyListItem = ({
  item,
  handleOfferPress,
  handleGroupPress,
  skipAnimation,
}: {
  item: LoyaltyOffer | LoyaltyOfferGroup;
  handleOfferPress: (offer: LoyaltyOffer) => void;
  handleGroupPress: (group: LoyaltyOfferGroup) => void;
  skipAnimation: boolean;
}) => {
  if ('loyaltyEngineId' in item) {
    return renderOfferItem({ item, handlePress: handleOfferPress, skipAnimation });
  } else if ('groupId' in item) {
    return renderOfferGroupItem({ item, handlePress: handleGroupPress });
  }
  return null;
};
