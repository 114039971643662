import { ToastProvider } from 'react-native-toast-notifications';

import { IBaseProps } from '@rbi-ctg/frontend';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';

import { MENU_TOAST_DESKTOP_OFFSET, MENU_TOAST_MOBILE_OFFSET } from './constants';
import { AddToCartTemplate } from './render-types/add-to-cart';
import { MenuToastEventTypes } from './types';

export const MenuToastProvider = ({ children }: IBaseProps) => {
  const isMobile = useIsMobileBreakpoint();
  return (
    <ToastProvider
      swipeEnabled
      offset={isMobile ? MENU_TOAST_MOBILE_OFFSET : MENU_TOAST_DESKTOP_OFFSET}
      renderType={{
        [MenuToastEventTypes.ADD_TO_CART]: toast => <AddToCartTemplate toast={toast} />,
      }}
    >
      {children}
    </ToastProvider>
  );
};
