import { ContentCard } from '@braze/react-native-sdk';
import { PayloadAction } from '@reduxjs/toolkit';
import * as Notifications from 'expo-notifications';

import {
  getReadContentCardsFromLocalStorage,
  parseWebContentCards,
  removeExpiredContentCardsFromLocalStorage,
} from 'state/braze/hooks/utils';
import { CONTENT_CARD_FEED, WebContentCard } from 'state/braze/types';
import { createAppSlice } from 'state/global-state/utils';

import { IContentCardsState } from './content-cards.types';

export const initialState: IContentCardsState = {
  contentCards: [],
  newsFeedContentCards: [],
  homePageFeedContentCards: [],
  webContentCards: [],
  unreadContentCards: 0,
  openInbox: false,
};

const calculateUnreadContentCards = (payload: ContentCard[]): number => {
  const readContentCards = getReadContentCardsFromLocalStorage();
  return payload.filter(
    ({ id, viewed, isControl, extras }) =>
      !viewed &&
      !readContentCards.includes(id) &&
      !isControl &&
      extras.feed === CONTENT_CARD_FEED.NEWS
  ).length;
};

const updateContentCardsState = (state: IContentCardsState, payload: ContentCard[]) => {
  // Remove expired Content Cards from Local Storage
  const contentCardIds = payload.filter(({ viewed }) => !viewed).map(({ id }) => id);
  removeExpiredContentCardsFromLocalStorage(contentCardIds);

  // Set current content cards on the state
  state.contentCards = payload;

  const newsFeedContentCards = payload.filter(
    ({ extras }) => extras.feed === CONTENT_CARD_FEED.NEWS
  );
  const homePageFeedContentCards = payload.filter(
    ({ extras }) => extras.feed === CONTENT_CARD_FEED.HOME_PAGE
  );

  state.newsFeedContentCards = newsFeedContentCards;
  state.homePageFeedContentCards = homePageFeedContentCards;

  /**
   * Since Content Cards cant take some time to be marked as read in Braze, we rely on the IDs
   * from Local Storage. It’s possible that we might receive the unread status from Braze,
   * even though the user has already read them.
   */
  const unreadContentCards = calculateUnreadContentCards(payload);

  // Set the app icon's notification badge count.
  Notifications.setBadgeCountAsync(unreadContentCards);

  state.unreadContentCards = unreadContentCards;
};

export const contentCardsSlice = createAppSlice({
  name: 'content-cards',
  initialState,
  reducers: {
    setWebContentCards: (state, { payload }: PayloadAction<WebContentCard[]>) => {
      state.webContentCards = payload;

      const parsedContentCards = parseWebContentCards(payload);
      updateContentCardsState(state, parsedContentCards);
    },
    setContentCards: (state, { payload }: PayloadAction<ContentCard[]>) => {
      updateContentCardsState(state, payload);
    },
    refreshUnreadContentCards: state => {
      state.unreadContentCards = calculateUnreadContentCards(state.contentCards);
    },
    openInbox: state => {
      state.openInbox = true;
    },
    closeInbox: state => {
      state.openInbox = false;
    },
  },
});
