import { Svg, SvgPath, SvgProps, SvgRect, registerSVG } from '../../../components/svg';

export function CashAppWhiteBorder(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 33 20">
      <SvgRect x="0.333008" width="32" height="20" rx="4" fill="white" />
      <SvgRect x="7" y="3.77783" width="18.6667" height="12.4444" rx="1.5" fill="#00D64F" />
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9431 7.98039C17.5332 7.98039 18.0987 8.21931 18.4682 8.54728C18.5616 8.63024 18.7015 8.62974 18.7893 8.54085L19.2289 8.09495C19.3217 8.00084 19.3183 7.84674 19.2181 7.76074C18.9231 7.50711 18.527 7.25772 18.0395 7.09804L18.1775 6.44143C18.2073 6.29832 18.0984 6.16382 17.952 6.16382H17.1025C16.9938 6.16382 16.8998 6.23986 16.8772 6.34614L16.753 6.93009C15.6237 6.98652 14.6665 7.55003 14.6665 8.70628C14.6665 9.70724 15.4584 10.1363 16.2943 10.4331C17.0863 10.73 17.5041 10.8402 17.5041 11.258C17.5041 11.6871 17.0863 11.94 16.4702 11.94C15.9085 11.94 15.3202 11.7546 14.8636 11.3045C14.7741 11.2161 14.6308 11.2155 14.5412 11.3038L14.0688 11.7698C13.9764 11.8609 13.9772 12.0106 14.0703 12.1008C14.4387 12.4584 14.905 12.7173 15.4373 12.8622L15.3077 13.4705C15.2775 13.6131 15.3854 13.7476 15.5314 13.7488L16.3823 13.7552C16.4918 13.756 16.5869 13.6795 16.6093 13.5722L16.7321 12.9874C18.0843 12.9039 18.9119 12.1689 18.9119 11.0931C18.9119 10.1033 18.0872 9.68528 17.0863 9.3443C16.5143 9.13512 16.0192 8.99234 16.0192 8.5635C16.0192 8.14564 16.4814 7.98039 16.9431 7.98039Z"
        fill="white"
      />
    </Svg>
  );
}

registerSVG('CashAppWhiteBorder', CashAppWhiteBorder);
