import React from 'react';

import { Box, Header as UCLHeader } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import ActionButton from 'components/action-button';
import { logUserErrorMessage } from 'hooks/log-user-error-message';
import useEffectOnce from 'hooks/use-effect-once';
import { CustomEventNames, EventTypes, logEvent } from 'state/amplitude';
import { handleFatalErrorReload } from 'utils/fatal-error-handler';

import IconError from './icon';
import { useReload } from './use-reload';

const Container = Box.withConfig({
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: Styles.color.grey.five,
});

const Inner = Box.withConfig({
  maxWidth: '380px',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: '8',
});

// These styles are taken from the `cart-error` component,
// they should be extracted into a common component
const Header = UCLHeader.withConfig({
  textTransform: Styles.textTransform.headlines,
  textAlign: 'center',
  marginY: '5',

  maxWidth: {
    base: '3/4',
    md: '500px',
  },
});

const Subheading = Box.withConfig({
  textAlign: 'center',
  fontSize: 'sm',
  marginBottom: '$4',
});

function ErrorBoundary() {
  const { formatMessage } = useIntl();
  const localeTitle = formatMessage({ id: 'errorBoundaryTitle' });
  const localeBody = formatMessage({ id: 'errorBoundaryBody' });
  const onReload = useReload(handleFatalErrorReload);

  useEffectOnce(() => {
    logEvent(CustomEventNames.APP_ERROR, EventTypes.Other);
    logUserErrorMessage({ message: localeTitle });
  });

  return (
    <Container testID="error-boundary">
      <Inner>
        <IconError />
        <Header>{localeTitle}</Header>
        <Subheading>{localeBody}</Subheading>

        <ActionButton onPress={onReload} testID="error-boundary-reload-btn">
          {formatMessage({ id: 'reload' })}
        </ActionButton>
      </Inner>
    </Container>
  );
}

export default ErrorBoundary;
