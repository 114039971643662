import { Box, Header, Text } from '@rbilabs/universal-components';

import { InnerLayout } from 'components/layout/styled';
import BlockRenderer from 'components/sanity-block-renderer';
import { primitive } from 'styles/constants/primitives';

import { LoyaltyLoader } from '../loyalty-loader';

export const StyledBlockRenderer = BlockRenderer.withConfig({
  margin: 0,
  fontSize: 'xs',
});

export const LoyaltyIncentivesContainer = InnerLayout.withConfig({
  paddingY: '$0',
  margin: '$4',
});

export const OffersItemHeader = Header.withConfig({
  alignSelf: 'center',
  marginTop: '$4',
  variant: 'headerTwo',
});

export const StyledLoyaltyLoader = LoyaltyLoader;

export const OffersCooldownContainer = Box.withConfig({
  backgroundColor: primitive.bk.$warningLight,
  paddingY: '$2',
});

export const CooldownMessageContainer = Box.withConfig({
  flexDirection: 'column',
});

export const OffersCooldownTitle = Text.withConfig({
  variant: 'headerFour',
  fontSize: 'xs',
  marginBottom: '$0',
});

export const OffersCooldownMessage = Text.withConfig({
  variant: 'copyTwo',
  fontSize: 'xs',
});
