import { LOYALTY_PHYSICAL_CARD_NUMBER_LENGTH } from 'utils/constants';

/**
 * For documentation on masks, visit:
 *
 * https://github.com/text-mask/text-mask/blob/master/componentDocumentation.md#readme
 */

const nDigits = (n: number) => new Array(n).fill(/\d/);

/**
 * TODO: Use country code to determine how many digits are necessary for
 * beginning of input mask. Or better yet, split country code into it's
 * own input field
 */
export const phoneNumberMask = [
  '+',
  /\d/,
  ' ',
  ...nDigits(3),
  '-',
  ...nDigits(3),
  '-',
  ...nDigits(4),
];

export const creditCardNumberMask = (cardNumber: string) => {
  /**
   * You may be wondering why we're using the unicode representation of a
   * whitespace here. Well, I'll tell you. It's because of a bug in the
   * react-text-mask library (see: https://github.com/text-mask/text-mask/issues/483)
   * that breaks backspace functionality. Not great. By using unicode characters
   * for the placeholders here, in combination with showing the guide and setting
   * the `placeholderChar` on the mask component to a literal whitespace, we're
   * able to get around this problem.
   */

  /**
   * Amex card numbers are formatted differently from other cards
   * They begin with either '34' or '37'
   */
  if (/^(3[47])/.test(cardNumber)) {
    return [...nDigits(4), '\u2000', ...nDigits(6), '\u2000', ...nDigits(5)];
  }
  return [...nDigits(4), '\u2000', ...nDigits(4), '\u2000', ...nDigits(4), '\u2000', ...nDigits(4)];
};

export const expiryMask = [/\d/, /\d/, '/', /\d/, /\d/];

export const loyaltyPhysicalCardNumberMask = nDigits(LOYALTY_PHYSICAL_CARD_NUMBER_LENGTH);

export const loyaltyCrownCardNumberMask = [
  ...nDigits(4),
  '\u2000',
  ...nDigits(4),
  '\u2000',
  ...nDigits(4),
  '\u2000',
  ...nDigits(4),
];

export const getCursorPosition = (isBackspaceKey: boolean, prevPosition: number) => {
  if (isBackspaceKey) {
    return prevPosition - 1;
  }

  let nextPosition = prevPosition + 1;
  if (prevPosition === 4 || prevPosition === 9 || prevPosition === 14) {
    nextPosition += 1;
  }
  return nextPosition;
};
