import { Svg, SvgPath, SvgProps, SvgRect, registerSVG } from '../../../components/svg';

export function SmallGiftCardWhite(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 30 20" fill="none">
      <SvgRect x="0.666992" width="29.3333" height="20" rx="4" fill="white" />
      <SvgRect x="6" y="3.77783" width="18.6667" height="12.4444" rx="1.5" fill="white" />
      <SvgRect
        x="6.375"
        y="4.15283"
        width="17.9167"
        height="11.6944"
        rx="1.125"
        stroke="black"
        strokeOpacity="0.3"
        strokeWidth="0.75"
      />
      <SvgPath
        d="M18.0053 8.53057L17.5101 8.87286L17.0149 9.21515C16.9399 9.26613 16.8526 9.28694 16.7683 9.27654C16.684 9.26717 16.6029 9.22764 16.5415 9.16105L16.1004 8.68039L15.6592 8.19973C15.5718 8.10401 15.4522 8.05615 15.3325 8.05615C15.2129 8.05615 15.0932 8.10401 15.0059 8.19973L14.5647 8.68039L14.1236 9.16105C14.0622 9.22764 13.9811 9.26717 13.8968 9.27654C13.8125 9.2859 13.7251 9.26613 13.6502 9.21515L13.155 8.87286L12.6598 8.53057C12.5328 8.44318 12.383 8.44942 12.2696 8.51705C12.1562 8.58467 12.0782 8.7116 12.0938 8.86558L12.2374 10.2909L12.3809 11.7163C12.3903 11.8089 12.434 11.89 12.4985 11.9483C12.563 12.0065 12.6483 12.0419 12.742 12.0419H15.3325H17.9231C18.0157 12.0419 18.1021 12.0065 18.1666 11.9483C18.2311 11.89 18.2748 11.8089 18.2841 11.7163L18.4277 10.2909L18.5713 8.86558C18.5869 8.7116 18.5089 8.58467 18.3955 8.51705C18.281 8.44942 18.1322 8.44318 18.0053 8.53057Z"
        fill="#FFAA00"
      />
    </Svg>
  );
}

registerSVG('SmallGiftCardWhite', SmallGiftCardWhite);
