// eslint-disable-next-line prettier/prettier
import { Box, HStack, Header, Text, VStack, addWithConfig } from '@rbilabs/universal-components';

import ActionButton, { ActionButtonVariants } from 'components/action-button';
import { theme } from 'styles/configure-theme';
import { primitive } from 'styles/constants/primitives';

export const Item = VStack.withConfig({
  backgroundColor: Styles.color.cardBackground,
  borderRadius: Styles.borderRadius,
  marginTop: '$4',
  borderBottomRadius: Styles.borderRadius,
  borderTopRadius: Styles.borderRadius,
});

export const ItemOffer = Box.withConfig<{ $isApplied?: boolean; isLoading?: boolean }>(p => ({
  backgroundColor: p.isLoading
    ? primitive.$blackOpacity10
    : p.$isApplied
    ? primitive.bk.$successLight
    : primitive.bk.$errorLight,
  borderRadius: 8,
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
  paddingX: '$4',
  paddingY: '$3',
}));

export const ItemOfferFeedback = HStack.withConfig({
  focusable: true,
});

export const OfferFeedbackMessage = Text.withConfig({
  fontFamily: 'body',
  fontSize: '2xs',
  color: primitive.bk.$bbqBrown,
  focusable: true,
  lineHeight: 'sm',
  flex: 1,
});

export const CartItemEditButtonContainer = HStack.withConfig({
  flex: 1,
  alignItems: 'center',
});

export const CartItemDescription = HStack.withConfig({
  paddingX: '$4',
  paddingY: '$2',
  alignItems: 'center',
  borderBottomWidth: 1,
  borderBottomColor: primitive.$blackOpacity10,
});

export const IncentiveName = Header.withConfig({
  margin: '$0',
  variant: 'headerTwo',
  focusable: true,
  fontSize: 'sm',
  lineHeight: 'sm',
});

export const CartItemInfoContainer = VStack.withConfig({
  flex: 1,
});

export const CartItemEditContainer = HStack.withConfig({
  justifyContent: 'space-between',
  alignItems: 'center',
  borderTopWidth: 1,
  borderColor: theme.token('background-default'),
  paddingY: '$2',
  paddingX: '$4',
});

export const CartItemButton = addWithConfig(ActionButton).withConfig({
  variant: ActionButtonVariants.TEXT_ONLY,
  padding: 0,
  margin: 0,
});

export const CartItemButtonText = Text.withConfig({
  fontFamily: 'heading',
  lineHeight: '2xs',
  padding: '$2',
});

export const CartItemName = Header.withConfig({
  fontFamily: 'heading',
  fontSize: 'sm',
  margin: 0,
  focusable: true,
  numberOfLines: 2,
});

export const CartItemNameHeaderContainer = HStack.withConfig({
  marginRight: '$4',
  paddingY: '$3',
  paddingX: '$4',
  borderBottomWidth: 1,
  borderBottomColor: 'blackOpacity.10',
});
