import { useRoute } from 'hooks/navigation/use-route';
import { RunningExperiments, useExperiments } from 'state/experiments';
import { isItemPickerOrComboRoute } from 'utils/routing';

export const useEnableProductDetailsPageRedesign = (logExposure = false) => {
  const { variant } = useExperiments();
  const { pathname } = useRoute();

  const skipExposureLogging = !logExposure || !isItemPickerOrComboRoute(pathname);

  return !!variant(RunningExperiments.MenuProductDetailPageRedesign, 'treatment', {
    skipExposureLogging,
  });
};
