// Proxy functions to window.rbiBraze if it's initialized.
// Don't want to deal with TS generics because there are a small discrete number of methods

import { IBraze, IBrazeUser, IDisplayModule } from 'braze-web-sdk';

export type { ContentCard } from '@braze/react-native-sdk';

/**
 * Returns the RbiApbboy and BrazeUser instances stored on the window object.
 *
 * If the instances are not initialized yet, it triggers a second init attempt using the values set
 * by use-braze.web hook
 */
const getRbiBrazeInstances = (): {
  rbiBraze: IBraze | undefined;
  brazeUser: IBrazeUser | undefined;
} => {
  const getInstancesFromWindow = () => {
    try {
      const rbiBraze = window.rbiBraze;
      return { rbiBraze, brazeUser: rbiBraze?.getUser() };
    } catch (error) {
      return { rbiBraze: undefined, brazeUser: undefined };
    }
  };

  // First attempt
  const { rbiBraze, brazeUser } = getInstancesFromWindow();
  if (rbiBraze && brazeUser) {
    return { rbiBraze, brazeUser };
  }

  // If rbiBraze fails to return the window instances the first time, retry after calling init again
  window.rbiBrazeInit?.();

  // Second attempt
  return getInstancesFromWindow();
};

export const changeUser: IBraze['changeUser'] = (...args) => {
  const { rbiBraze } = getRbiBrazeInstances();
  return rbiBraze ? rbiBraze.changeUser(...args) : false;
};

export const getContentCards: IBraze['getCachedContentCards'] = (...args) => {
  const { rbiBraze } = getRbiBrazeInstances();
  return rbiBraze
    ? rbiBraze.getCachedContentCards(...args)
    : {
        cards: [],
        lastUpdated: '',
      };
};

export const logCustomEvent: IBraze['logCustomEvent'] = (...args) => {
  const { rbiBraze } = getRbiBrazeInstances();
  return rbiBraze ? rbiBraze.logCustomEvent(...args) : false;
};

export const logPurchase: IBraze['logPurchase'] = (...args) => {
  const { rbiBraze } = getRbiBrazeInstances();
  return rbiBraze ? rbiBraze.logPurchase(...args) : false;
};

export const requestContentCardsRefresh: IBraze['requestContentCardsRefresh'] = () => {
  const { rbiBraze } = getRbiBrazeInstances();
  return rbiBraze ? rbiBraze.requestContentCardsRefresh() : false;
};

export const requestImmediateDataFlush: IBraze['requestImmediateDataFlush'] = () => {
  const { rbiBraze } = getRbiBrazeInstances();
  return rbiBraze ? rbiBraze.requestImmediateDataFlush() : false;
};

export const disableSDK: IBraze['disableSDK'] = () => {
  const { rbiBraze } = getRbiBrazeInstances();
  return rbiBraze ? rbiBraze.disableSDK() : false;
};

export const enableSDK: IBraze['enableSDK'] = () => {
  const { rbiBraze } = getRbiBrazeInstances();
  return rbiBraze ? rbiBraze.enableSDK() : false;
};

export const setEmail: IBrazeUser['setEmail'] = (...args) => {
  const { brazeUser } = getRbiBrazeInstances();
  return brazeUser ? brazeUser.setEmail(...args) : false;
};

export const setFirstName: IBrazeUser['setFirstName'] = (...args) => {
  const { brazeUser } = getRbiBrazeInstances();
  return brazeUser ? brazeUser.setFirstName(...args) : false;
};

export const setLastName: IBrazeUser['setLastName'] = (...args) => {
  const { brazeUser } = getRbiBrazeInstances();
  return brazeUser ? brazeUser.setLastName(...args) : false;
};

export const setHomeCity: IBrazeUser['setHomeCity'] = (...args) => {
  const { brazeUser } = getRbiBrazeInstances();
  return brazeUser ? brazeUser.setHomeCity(...args) : false;
};

export const setPhoneNumber: IBrazeUser['setPhoneNumber'] = (...args) => {
  const { brazeUser } = getRbiBrazeInstances();
  return brazeUser ? brazeUser.setPhoneNumber(...args) : false;
};

export const setCustomUserAttribute: IBrazeUser['setCustomUserAttribute'] = (...args) => {
  const { brazeUser } = getRbiBrazeInstances();
  return brazeUser ? brazeUser.setCustomUserAttribute(...args) : false;
};

export const logContentCardImpression: IBraze['logCardImpressions'] = (...args) => {
  const cards = args[0];
  const { rbiBraze } = getRbiBrazeInstances();
  return rbiBraze ? rbiBraze.logCardImpressions(cards, true) : false;
};

const logContentCardClicked: IBraze['logCardClick'] = (...args) => {
  const card = args[0];
  const { rbiBraze } = getRbiBrazeInstances();
  return rbiBraze ? rbiBraze.logCardClick(card, true) : false;
};

// Name might look confusing, but that's how its called on the Native SDK
const launchContentCards: IDisplayModule['toggleContentCards'] = (...args) => {
  const { rbiBraze } = getRbiBrazeInstances();
  return rbiBraze?.display.toggleContentCards(...args);
};

export default {
  // Proxy functions on rbiBraze
  changeUser,
  getContentCards,
  logCustomEvent,
  logPurchase,
  requestContentCardsRefresh,
  requestImmediateDataFlush,
  disableSDK,
  enableSDK,

  // Proxy functions on brazeUser
  setCustomUserAttribute,
  setEmail,
  setFirstName,
  setHomeCity,
  setLastName,
  setPhoneNumber,

  // Proxy functions to display module
  launchContentCards,
  logContentCardImpression,
  logContentCardClicked,
};
