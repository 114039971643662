import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';

import { IBaseProps } from '@rbi-ctg/frontend';
import { IEncryptionResult } from 'components/payments/integrations/orbital/components/encryption/types';
import {
  IGetEncryptionDetailsMutation,
  IMergePrepaidInput,
  IMergePrepaidPayload,
  UserAccountsDocument,
  useAddCreditAccountMutation,
  useGetEncryptionDetailsMutation,
} from 'generated/graphql-gateway';
import { useRoute } from 'hooks/navigation/use-route';
import useEffectOnce from 'hooks/use-effect-once';
import useErrorModal from 'hooks/use-error-modal';
import { useAuthContext } from 'state/auth';
import { useLocale } from 'state/intl';
import { PaymentFieldVariations } from 'state/launchdarkly/variations';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { IPaymentPayload, IPaymentState, initialPaymentState } from 'utils/payment';
import { routes } from 'utils/routing';
import { useMemoAll } from 'utils/use-memo-all';

import usePayment from './hooks/use-payment';
import {
  CardTypes,
  IAddPaymentMethodOptions,
  IPaymentDetails,
  IPaymentMethod,
  IReloadPrepaidCard,
} from './types';

export interface IPaymentContext {
  addPaymentMethod: (
    method: IPaymentPayload,
    options?: IAddPaymentMethodOptions,
    encryptionResult?: IEncryptionResult
  ) => Promise<string>;
  checkoutPaymentMethod: IPaymentMethod | undefined;
  checkoutPaymentMethodId: string;
  canUseApplePay: boolean;
  canUseGooglePay: boolean;
  clearPaymentDetails(): void;
  defaultPaymentMethodId: string;
  defaultReloadPaymentMethodId: string;
  deletePaymentMethod: (accountId: string) => Promise<void>;
  encryptionResult: IEncryptionResult | undefined;
  getBalanceFromPaymentMethods: (prepaidPaymentMethod: IPaymentMethod) => number;
  getCounter?: () => Promise<void>;
  getEncryptionDetails: () => Promise<
    Pick<
      IGetEncryptionDetailsMutation['encryptionDetails'],
      'fdPublicKey' | 'fdApiKey' | 'fdAccessToken' | 'fdCustomerId' | 'algorithm'
    >
  >;
  getPaymentMethods: () => Promise<void>;
  getPrepaidPaymentMethod: () => IPaymentMethod | null;
  getPrepaidCardNumber: () => string | null;
  hasGetPaymentMethodsError: boolean;
  isFirstData: boolean;
  isOrbital: boolean;
  loading: boolean;
  mergePrepaidCardBalances: (input: IMergePrepaidInput) => Promise<IMergePrepaidPayload>;
  paymentMethods: IPaymentMethod[];
  paymentDetails: IPaymentDetails;
  paymentProcessor: string | null | undefined;
  prepaidReloadPaymentMethodId: string;
  reloadPaymentValues: IPaymentState;
  reloadPrepaidCard: (input: IReloadPrepaidCard) => Promise<number | undefined>;
  setCheckoutPaymentMethodId: (accountId: string) => void;
  setEncryptionResult: (encryptionResult: IEncryptionResult | undefined) => void;
  setDefaultPaymentMethodId: (accountId: string) => void;
  setDefaultReloadPaymentMethodId: (accountId: string) => void;
  setPrepaidReloadPaymentMethodId: (accountId: string) => void;
  setLastCommitOrderErrorDate: Dispatch<SetStateAction<number | null>>;
  lastCommitOrderErrorDate: number | null;
  setSelected?: (fdAccountId: string) => void;
  setReloadPaymentValues: Dispatch<SetStateAction<IPaymentState>>;
  storePaymentDetails: (data: IPaymentDetails) => void;
  transformPaymentValues: ({
    paymentValues,
    paymentFieldVariations,
  }: {
    paymentValues: IPaymentState;
    paymentFieldVariations: PaymentFieldVariations;
  }) => IPaymentPayload;
  setLoading: (input: boolean) => void;
  isQuickPayMethodSelected: boolean;
  setIsQuickPayMethodSelected: Dispatch<SetStateAction<boolean>>;
  isAddPaymentMethodSuccess: boolean;
  setIsAddPaymentMethodSuccess: (input: boolean) => void;
  orderGrandTotal: number;
  setOSPaymentMethod: (input: number) => void;
}

export const PaymentContext = createContext<IPaymentContext>({} as IPaymentContext);
export const usePaymentContext = () => useContext(PaymentContext);

const clearPersistedPaymentDetails = (): void => {
  LocalStorage.setItem(StorageKeys.PAYMENT, {});
};

const persistPaymentDetails = (paymentDetails: IPaymentDetails): void => {
  LocalStorage.setItem(StorageKeys.PAYMENT, paymentDetails);
};

const retrievePersistedPaymentDetails = (): IPaymentDetails | null => {
  return LocalStorage.getItem(StorageKeys.PAYMENT);
};

export function PaymentProvider(props: IBaseProps) {
  const { feCountryCode } = useLocale();
  const { updateUserInfo, user, isReAuthenticating } = useAuthContext();
  const [getEncryptionDetailsMutation] = useGetEncryptionDetailsMutation();
  const [addCreditAccountMutation] = useAddCreditAccountMutation({
    awaitRefetchQueries: true,
    refetchQueries: [{ query: UserAccountsDocument, variables: { feCountryCode } }],
  });

  const [paymentDetails, setPaymentDetails] = useState({});
  const [isQuickPayMethodSelected, setIsQuickPayMethodSelected] = useState(false);
  const [isAddPaymentMethodSuccess, setIsAddPaymentMethodSuccess] = useState(false);
  const [orderGrandTotal, setOrderGrandTotal] = useState(0);
  const { pathname } = useRoute();
  const isCartPayment = pathname === routes.cartPayment;
  const [lastCommitOrderErrorDate, setLastCommitOrderErrorDate] = useState<number | null>(null);

  const [ErrorDialog, openErrorDialog] = useErrorModal({
    modalAppearanceEventMessage: 'Error: Payment Error',
  });

  const [reloadPaymentValues, setReloadPaymentValues] = useState(
    initialPaymentState({ billingCountry: feCountryCode, userDetailsName: user?.details?.name })
  );

  const storePaymentDetails = useCallback((data: IPaymentDetails): void => {
    setPaymentDetails(data);
    persistPaymentDetails(data);
  }, []);

  const clearPaymentDetails = useCallback(() => {
    setPaymentDetails({});
    clearPersistedPaymentDetails();
  }, []);

  useEffectOnce(() => {
    const values = retrievePersistedPaymentDetails();
    if (values) {
      setPaymentDetails(values);
    }
  });

  const {
    addPaymentMethod,
    canUseApplePay,
    canUseGooglePay,
    checkoutPaymentMethod,
    checkoutPaymentMethodId,
    defaultPaymentMethodId,
    defaultReloadPaymentMethodId,
    deletePaymentMethod,
    encryptionResult,
    setEncryptionResult,
    getEncryptionDetails,
    getBalanceFromPaymentMethods,
    getPaymentMethods,
    getPrepaidCardNumber,
    getPrepaidPaymentMethod,
    hasGetPaymentMethodsError,
    isFirstData,
    isOrbital,
    loading,
    mergePrepaidCardBalances,
    paymentMethods,
    paymentProcessor,
    prepaidReloadPaymentMethodId,
    reloadPrepaidCard,
    setCheckoutPaymentMethodId,
    setDefaultPaymentMethodId,
    setDefaultReloadPaymentMethodId,
    setPrepaidReloadPaymentMethodId,
    transformPaymentValues,
    setLoading,
  } = usePayment({
    getEncryptionDetailsMutation,
    openErrorDialog,
    user,
    isReAuthenticating,
    updateUserInfo,
    addCreditAccountMutation,
    paymentDetails,
  });

  const setOSPaymentMethod = useCallback(
    (orderGrandTotal: number) => {
      setOrderGrandTotal(orderGrandTotal);

      if (isCartPayment && orderGrandTotal < 100 && checkoutPaymentMethodId === CardTypes.CASHAPP) {
        if (canUseApplePay) {
          setCheckoutPaymentMethodId(CardTypes.APPLE_PAY || '');
          setDefaultPaymentMethodId(CardTypes.APPLE_PAY || '');
        } else if (canUseGooglePay) {
          setCheckoutPaymentMethodId(CardTypes.GOOGLE_PAY || '');
          setDefaultPaymentMethodId(CardTypes.GOOGLE_PAY || '');
        } else {
          setCheckoutPaymentMethodId(CardTypes.GIFT_CARD || '');
          setDefaultPaymentMethodId(CardTypes.GIFT_CARD || '');
        }
      }
    },
    [
      canUseApplePay,
      canUseGooglePay,
      checkoutPaymentMethodId,
      isCartPayment,
      setCheckoutPaymentMethodId,
      setDefaultPaymentMethodId,
    ]
  );

  const value = useMemoAll({
    addPaymentMethod,
    canUseApplePay,
    canUseGooglePay,
    checkoutPaymentMethod,
    checkoutPaymentMethodId,
    clearPaymentDetails,
    encryptionResult,
    setEncryptionResult,
    defaultPaymentMethodId,
    defaultReloadPaymentMethodId,
    deletePaymentMethod,
    getPaymentMethods,
    getBalanceFromPaymentMethods,
    getPrepaidPaymentMethod,
    getPrepaidCardNumber,
    getEncryptionDetails,
    hasGetPaymentMethodsError,
    isFirstData,
    isOrbital,
    loading,
    lastCommitOrderErrorDate,
    mergePrepaidCardBalances,
    paymentMethods,
    paymentProcessor,
    paymentDetails,
    prepaidReloadPaymentMethodId,
    reloadPaymentValues,
    reloadPrepaidCard,
    setCheckoutPaymentMethodId,
    setDefaultPaymentMethodId,
    setDefaultReloadPaymentMethodId,
    setLastCommitOrderErrorDate,
    setPrepaidReloadPaymentMethodId,
    storePaymentDetails,
    setReloadPaymentValues,
    transformPaymentValues,
    setLoading,
    isQuickPayMethodSelected,
    setIsQuickPayMethodSelected,
    isAddPaymentMethodSuccess,
    setIsAddPaymentMethodSuccess,
    orderGrandTotal,
    setOSPaymentMethod,
  });

  return (
    <PaymentContext.Provider value={value}>
      {props.children}
      <ErrorDialog />
    </PaymentContext.Provider>
  );
}

export default PaymentContext.Consumer;
