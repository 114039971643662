import { useSimpleHomePagesQuery } from 'generated/sanity-graphql';

export const useSimpleHomePage = (ids?: string[]) => {
  const simpleHomePageId = ids ?? [];
  const { data, loading } = useSimpleHomePagesQuery({
    variables: {
      where: {
        _id_in: ids,
      },
    },
    skip: !simpleHomePageId,
  });

  const simpleHomePage = data?.allSimpleHomePages;

  return {
    simpleHomePage,
    simpleHomePageLoading: loading,
  };
};
