import { propIs } from 'utils';

import { Refinement } from '@rbi-ctg/frontend';
import { ICombo, IItem, IItemOption, IPicker, ISection, MenuObject } from '@rbi-ctg/menu';
import { MenuObjectTypes } from 'enums/menu';

type IsMenuType<MenuType extends { _type: MenuObjectTypes }> = Refinement<object, MenuType>;
const typeKey = '_type';
const typeIs = (isType: string) => propIs<MenuObject>(typeKey, isType);

export const isSection = typeIs(MenuObjectTypes.SECTION) as IsMenuType<ISection>;

export const isItem = typeIs(MenuObjectTypes.ITEM) as IsMenuType<IItem>;

export const isCombo = typeIs(MenuObjectTypes.COMBO) as IsMenuType<ICombo>;

export const isPicker = typeIs(MenuObjectTypes.PICKER) as IsMenuType<IPicker>;

export const isItemOption = typeIs(MenuObjectTypes.ITEM_OPTION) as IsMenuType<IItemOption>;
