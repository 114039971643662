import { isBefore, isValid, parse } from 'date-fns';

import { ISanityBlockContent } from '@rbi-ctg/menu';

export const validateHexColor = (color: string): string | undefined => {
  const hexColorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  return hexColorRegex.test(color) ? color : undefined;
};

export const termsToSanityBlockContent = (text: string = ''): ISanityBlockContent[] => [
  {
    children: [
      {
        _key: 'terms-content',
        _type: 'span',
        text,
        marks: [],
      },
    ],
    markDefs: [],
    style: 'normal',
    _key: 'terms',
    _type: 'block',
  },
];

/**
 * Converts a comma-separated string of days into an array of day names.
 *
 * @param {string} daysOfWeekStr - A string representing days of the week, separated by commas (e.g., 'monday,wednesday,friday').
 * @returns {string[] | undefined} An array of day names extracted from the input string.
 */
export const parseDaysOfWeek = (daysOfWeekStr: string = ''): string[] | undefined => {
  const weekDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
  const splitDays = daysOfWeekStr.split(',').map(day => day.trim().toLocaleLowerCase());
  const daysOfWeek = splitDays.filter(day => weekDays.includes(day));

  return daysOfWeek.length > 0 ? daysOfWeek : undefined;
};

/**
 * Parses a string of time ranges into an array of valid time range objects.
 *
 * The input string should consist of time ranges separated by semicolons (`;`),
 * with each range represented by two times separated by a comma (`,`).
 * Times must be in the `HH:mm` format.
 *
 * Example input: `'08:00,12:00;13:00,18:00'`
 *
 * @param {string} [timeRangesStr=''] - A string representing time ranges (e.g., '08:00,12:00;13:00,18:00').
 * @returns {{ startTime: string, endTime: string }[] | undefined} An array of valid time ranges.
 * Each range includes `startTime` and `endTime` in `HH:mm` format. Invalid or malformed ranges are ignored.
 */
export const parseTimeRanges = (
  timeRangesStr: string = ''
): { startTime: string; endTime: string }[] | undefined => {
  const splitRanges = timeRangesStr.split(';').map(range => range.trim());
  const validRanges = [];

  for (const range of splitRanges) {
    const [startTime, endTime] = range.split(',').map(time => time.trim());

    if (!startTime || !endTime) {
      continue;
    }

    const startTimeDate = parse(startTime, 'HH:mm', new Date());
    const endTimeDate = parse(endTime, 'HH:mm', new Date());

    if (isValid(startTimeDate) && isValid(endTimeDate) && isBefore(startTimeDate, endTimeDate)) {
      validRanges.push({ startTime, endTime });
    }
  }

  return validRanges.length > 0 ? validRanges : undefined;
};
