import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import isEqual from 'lodash-es/isEqual';
import { useIntl } from 'react-intl';

import { ICartEntry } from '@rbi-ctg/menu';
import { usePriceOrder } from 'hooks/price-order';
import { useCart } from 'hooks/use-cart';
import useDialogModal from 'hooks/use-dialog-modal';
import { useAuthContext } from 'state/auth';
import { mapEntryTypeToOfferType } from 'state/cart/helpers';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';
import { useLocale } from 'state/intl';
import { LaunchDarklyFlag, useFlag, useLDContext } from 'state/launchdarkly';
import { isDiscountOffer } from 'state/loyalty/utils';
import { useMenuContext } from 'state/menu';
import { ServiceMode, useOrderContext } from 'state/order';
import { useCartTip } from 'state/order/hooks/use-cart-tip';
import { useUnavailableCartEntries } from 'state/order/hooks/use-unavailable-cart-entries';
import { useStoreContext } from 'state/store';
import { createCartEntry } from 'utils/cart';
import { EventName, emitEvent } from 'utils/event-hub';
import { isPhoneNumberValidSimple } from 'utils/form';
import LocalStorage, { StorageKeys } from 'utils/local-storage';
import { AttributeName } from 'utils/performance';
import { isDelivery } from 'utils/service-mode';
import { useMemoAll } from 'utils/use-memo-all';

import { useSanitizedRewards } from './hooks/use-sanitized-rewards';
import type {
  ICartContext,
  ICartContextArgs,
  ITryPricingOverrides,
  IVehicleDescription,
  IVehicleDescriptionChange,
} from './types';

const CartContext = createContext<ICartContext>({} as ICartContext);

export const CartProvider: React.FC<React.PropsWithChildren<ICartContextArgs>> = ({ children }) => {
  const [cardSuccessfullyAdded, setCardSuccessfullyAdded] = useState(false);

  const [shouldSkipPriceOrder, setShouldSkipPriceOrder] = useState(false);

  const [isTipLocked, setIsTipLocked] = useState(false);

  const { updateLDUser } = useLDContext();
  const {
    deliveryAddress,
    deliveryInstructions,
    orderPhoneNumber,
    serviceMode: orderServiceMode,
    selectServiceMode: selectOrderServiceMode,
    shouldEmptyCart,
  } = useOrderContext();
  const {
    cartEntries: globalCartEntries,
    calculateCartTotalWithDiscount,
    emptyCart,
    removeFromCart,
    repriceCartEntries,
    logCartEntryRemovedFromCart,
  } = useCart();

  const {
    tipAmount,
    setTipAmount,
    tipSelection,
    setTipSelection,
    updateTipAmount,
    shouldShowTipPercentage,
  } = useCartTip();

  const [vehicleDescription, _setVehicleDescription] = useState<IVehicleDescription>({
    value: LocalStorage.getItem(StorageKeys.VEHICLE_DESCRIPTION) ?? '',
    hasError: false,
  });

  const setVehicleDescription = useCallback(
    ({ hasError = false, value }: IVehicleDescriptionChange) => {
      _setVehicleDescription((prevVehicleDescription: IVehicleDescription) => {
        const newVehicleDescription = {
          hasError,
          value,
        };

        return isEqual(prevVehicleDescription, newVehicleDescription)
          ? prevVehicleDescription
          : newVehicleDescription;
      });
    },
    [_setVehicleDescription]
  );

  const saveVehicleDescription = useCallback((description: string) => {
    LocalStorage.setItem(StorageKeys.VEHICLE_DESCRIPTION, description);
  }, []);

  const isFastLaneEnabled = useFlag(LaunchDarklyFlag.ENABLE_FAST_LANE_DRIVE_THRU);
  const isDriveThruHidden = isFastLaneEnabled && orderServiceMode === ServiceMode.DRIVE_THRU;
  const initialServiceMode = isDriveThruHidden ? ServiceMode.TAKEOUT : orderServiceMode;
  const [serviceMode, setServiceMode] = useState<ServiceMode>(initialServiceMode as ServiceMode);
  // Keep local ref of the serviceMode to properly update OrderContext on unmount
  // The ref update is synchronous, while the state update will only occur after re-render
  const contextServiceModeRef = useRef(initialServiceMode);

  const selectServiceMode = useCallback(
    (selectedServiceMode: ServiceMode) => {
      setServiceMode(selectedServiceMode);
      // LD Context needs to be updated since there are LD Rules that depends of the current service mode
      updateLDUser({ serviceMode: selectedServiceMode });
      contextServiceModeRef.current = selectedServiceMode;
    },
    [updateLDUser]
  );

  const { user, refreshCurrentUser } = useAuthContext();
  const { isStoreOpenAndAvailable, store, serviceModeStatus } = useStoreContext();
  const { formatMessage } = useIntl();

  const appliedOffers = useAppSelector(selectors.loyalty.selectAppliedOffers);
  const loyaltyAppliedDiscountOffer = useAppSelector(
    selectors.loyalty.selectDiscountAppliedCmsOffer
  );
  const isPricingRewardApplication = useAppSelector(
    selectors.loyalty.selectIsPricingRewardApplication
  );

  const { locale: customerLocale } = useLocale();
  const dispatch = useAppDispatch();

  useEffect(() => {
    // TODO: having a deps array will trigger this cleanup function every time that the deps changes
    // Check if this is intentional or this should only be called on unmount
    return () => {
      if (deliveryAddress?.shouldSave) {
        refreshCurrentUser();
      }
    };
  }, [deliveryAddress, refreshCurrentUser]);

  useEffect(() => {
    // Update OrderContext on unmount since Cart components should use serviceMode from CartContext
    // This significantly improves perceived performance when switching between serviceMode options
    return () => {
      if (contextServiceModeRef.current !== orderServiceMode) {
        selectOrderServiceMode(contextServiceModeRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Ensure the service mode is being reset if it's Delivery
    // This happens in React Nav when changing stores from the Cart between Pick Up/Delivery that may result in a stale state
    const isDeliveryOrder =
      isDelivery(orderServiceMode) || isDelivery(contextServiceModeRef.current);
    if (isDeliveryOrder && contextServiceModeRef.current !== orderServiceMode) {
      selectServiceMode(orderServiceMode as ServiceMode);
    }
  }, [orderServiceMode, selectServiceMode]);

  const appliedOffersMap = useMemo(
    () =>
      appliedOffers.reduce((acc, offer) => {
        acc[offer.cartId || ''] = offer;
        return acc;
      }, {}),
    [appliedOffers]
  );

  const [isCartTotalNegative, setIsCartTotalNegative] = useState<boolean>();
  const [requestedAmountCents, setRequestedAmountCents] = useState<number | undefined>();
  const cartEntries = useMemo(() => {
    // Create the cart entry from the selected discount offer
    // This offer will be available if the cart is not empty
    const selectedOffer = loyaltyAppliedDiscountOffer;

    // @ts-expect-error TS(2322) FIXME: ICartEnty does not support empty vendorConfigs
    const offerDiscountCartEntry: ICartEntry[] =
      globalCartEntries.length && selectedOffer && isDiscountOffer(selectedOffer)
        ? [
            {
              ...createCartEntry({ item: selectedOffer }),
              cartId: 'discount-offer',
              vendorConfigs: undefined,
              offerVendorConfigs: selectedOffer.vendorConfigs,
            },
          ]
        : [];

    // Updating entry type if its part of an applied offer
    const updatedCartEntries = globalCartEntries.map((entry: ICartEntry) =>
      appliedOffersMap[entry.cartId]
        ? { ...entry, type: mapEntryTypeToOfferType(entry.type) }
        : entry
    );

    // Cart entries with offers
    const repricedCartEntries = repriceCartEntries(
      updatedCartEntries.concat(offerDiscountCartEntry)
    );
    const { cartTotal, isCartTotalNegative } = calculateCartTotalWithDiscount(repricedCartEntries);
    setIsCartTotalNegative(isCartTotalNegative);
    setRequestedAmountCents(Math.max(0, cartTotal));

    return repricedCartEntries;
  }, [
    appliedOffersMap,
    calculateCartTotalWithDiscount,
    globalCartEntries,
    loyaltyAppliedDiscountOffer,
    repriceCartEntries,
  ]);

  const rewardsApplied = useSanitizedRewards({ cartEntries });

  const {
    rbiOrderId,
    setRbiOrderId,
    priceOrder,
    loading: loadingPriceOrder,
    orderStatus,
    priceOrderFailure,
    priceOrderSuccess,
    priceResult: { error: priceMutationError },
    serverOrder,
  } = usePriceOrder({
    cartEntries,
    updateTipAmount,
  });

  // TODO: BKPE-1956 - unavailableCartEntries
  const { unavailableCartEntries, setUnavailableCartEntries } = useUnavailableCartEntries({
    cartEntries,
    serverOrder,
  });

  const onConfirmRemoveItemDialog = useCallback(
    (entry: ICartEntry | null | undefined) => {
      if (!entry) {
        return;
      }
      emitEvent(EventName.CART_CONTENT_UPDATE, {
        attributes: [
          {
            name: AttributeName.CART_UPDATE_SOURCE,
            value: 'remove_item',
          },
        ],
      });
      if (shouldEmptyCart(entry)) {
        const cartEntryToRemove = cartEntries.find(
          (cartEntry: ICartEntry) => cartEntry.cartId === entry.cartId
        );
        if (cartEntryToRemove) {
          logCartEntryRemovedFromCart(cartEntryToRemove);
        }

        return emptyCart();
      }
      return removeFromCart(entry);
    },
    [shouldEmptyCart, removeFromCart, cartEntries, emptyCart, logCartEntryRemovedFromCart]
  );

  const [RemoveItemDialog, openRemoveItemDialog, itemToRm] = useDialogModal<ICartEntry>({
    onConfirm: onConfirmRemoveItemDialog,
    showCancel: true,
    modalAppearanceEventMessage: 'Confirmation: Remove item from cart',
  });

  // for confirming item removal
  const confirmRemoveItemFromCart = useCallback(
    (cartId: string) => {
      const entryToRemove = globalCartEntries.find((item: ICartEntry) => cartId === item.cartId);
      if (entryToRemove) {
        openRemoveItemDialog(entryToRemove);
      }
    },
    [globalCartEntries, openRemoveItemDialog]
  );

  const customerName =
    isFastLaneEnabled && serviceMode === ServiceMode.TAKEOUT ? `FL-${user?.details?.name}` : null;

  const { allPricingDependenciesAreSettled } = useMenuContext();

  // NOTE: This extra validation is being added due to multiple Datadog reports
  // indicating errors originating from this flow. Users with incorrect phone numbers
  // are likely seeing pricing error modal. To mitigate this, we should avoid calling
  // the price order mutation until the user resolves their phone number issue.
  // issue: https://rbictg.atlassian.net/browse/BKPE-7474
  const shouldAvoidTryPricing =
    isDelivery(serviceMode) && !isPhoneNumberValidSimple(orderPhoneNumber);

  const isTryPricingEnabled =
    !shouldAvoidTryPricing &&
    isStoreOpenAndAvailable &&
    user &&
    !isCartTotalNegative &&
    serviceMode &&
    cartEntries?.length > 0 &&
    // RequestedAmountCents may not have been calculated yet if the pricing deps has not been settled
    requestedAmountCents !== undefined &&
    allPricingDependenciesAreSettled;

  const validAppliedOffers = useMemo(() => {
    return appliedOffers.map(({ id, cartId, type, cmsId }) => ({
      id,
      cartId,
      type,
      sanityId: cmsId,
    }));
  }, [appliedOffers]);

  const priceOrderParams = useMemo(
    () => ({
      rewardsApplied,
      customerLocale,
      customerName,
      deliveryAddress: deliveryAddress
        ? {
            ...deliveryAddress,
            phoneNumber: orderPhoneNumber,
          }
        : null,
      orderPhoneNumber,
      deliveryInstructions,
      appliedOffers: validAppliedOffers,
      requestedAmountCents: requestedAmountCents ?? 0,
      serviceMode,
      store,
      isStoreOpenAndAvailable,
    }),
    [
      customerLocale,
      customerName,
      deliveryAddress,
      deliveryInstructions,
      isStoreOpenAndAvailable,
      orderPhoneNumber,
      requestedAmountCents,
      rewardsApplied,
      serviceMode,
      store,
      validAppliedOffers,
    ]
  );

  const tryPricing = useCallback(
    (overrideOrderParams?: ITryPricingOverrides) => {
      if (!isTryPricingEnabled) {
        setShouldSkipPriceOrder(true);
        return;
      }

      void priceOrder(
        {
          ...priceOrderParams,
          ...overrideOrderParams,
        },
        user
      );
      if (isPricingRewardApplication) {
        dispatch(actions.loyalty.setIsPricingRewardApplication(false));
      }
    },
    [dispatch, isPricingRewardApplication, isTryPricingEnabled, priceOrder, priceOrderParams, user]
  );

  const removeItemMessage = (item: ICartEntry | null) => {
    return item
      ? formatMessage(
          { id: 'removeItemFromCart' },
          {
            item: item.name,
            offerText: '',
          }
        )
      : '';
  };

  const pricedAndAvailable = useMemo(() => {
    return priceOrderSuccess && !unavailableCartEntries.length && !priceMutationError;
  }, [priceMutationError, priceOrderSuccess, unavailableCartEntries.length]);

  const selectedDonationCartEntry = useMemo(
    () => cartEntries.find((cartEntry: ICartEntry) => cartEntry.isDonation),
    [cartEntries]
  );

  const value = useMemoAll({
    rbiOrderId,
    setRbiOrderId,
    confirmRemoveItemFromCart,
    serverOrder,
    tryPricing,
    loadingTryPricing: loadingPriceOrder,
    cardSuccessfullyAdded,
    setCardSuccessfullyAdded,
    orderStatus,
    priceOrderFailure,
    priceOrderSuccess,
    priceMutationError,
    pricedAndAvailable,
    unavailableCartEntries,
    setUnavailableCartEntries,
    serviceMode,
    selectServiceMode,
    serviceModeStatus,
    shouldSkipPriceOrder,
    setShouldSkipPriceOrder,
    selectedDonationCartEntry,
    requestedAmountCents: requestedAmountCents ?? 0,
    tipAmount,
    setTipAmount,
    isTipLocked,
    setIsTipLocked,
    vehicleDescription,
    setVehicleDescription,
    saveVehicleDescription,
    tipSelection,
    setTipSelection,
    updateTipAmount,
    shouldShowTipPercentage,
  });

  return (
    <CartContext.Provider value={value}>
      {children}

      <RemoveItemDialog
        body={removeItemMessage(itemToRm)}
        heading={formatMessage({ id: 'removeItem' })}
      />
    </CartContext.Provider>
  );
};

export const useCartContext = () => useContext(CartContext);
