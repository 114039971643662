function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { Parser } from "../Parser.js";
import { numericPatterns } from "../constants.js";
import { parseNumericPattern, parseNDigits } from "../utils.js";
export class MinuteParser extends Parser {
  constructor() {
    super(...arguments);

    _defineProperty(this, "priority", 60);

    _defineProperty(this, "incompatibleTokens", ['t', 'T']);
  }

  parse(dateString, token, match) {
    switch (token) {
      case 'm':
        return parseNumericPattern(numericPatterns.minute, dateString);

      case 'mo':
        return match.ordinalNumber(dateString, {
          unit: 'minute'
        });

      default:
        return parseNDigits(token.length, dateString);
    }
  }

  validate(_date, value) {
    return value >= 0 && value <= 59;
  }

  set(date, _flags, value) {
    date.setUTCMinutes(value, 0, 0);
    return date;
  }

}