import React, { useState } from 'react';

import { useIntl } from 'react-intl';

import { TextInputMask } from 'components/ucl';
import { primitive } from 'styles/constants/primitives';
import { isNative } from 'utils/environment';
import { getCursorPosition, loyaltyCrownCardNumberMask } from 'utils/masks';
import noop from 'utils/noop';

type AddGiftCardInputProps = {
  label?: string;
  initialValue?: string;
  onChange?: (value: string) => void;
  errorMessage?: string;
  onFocus?: () => void;
  testID?: string;
  isRedesign?: boolean;
};

function AddGiftCardInput({
  initialValue = '',
  label = '',
  onChange = noop,
  onFocus = noop,
  errorMessage = '',
  testID: dataTestId,
  isRedesign = false,
}: AddGiftCardInputProps) {
  const [value, setValue] = useState(initialValue);
  const { formatMessage } = useIntl();

  const [cursorPosition, setCursorPosition] = useState(0);
  const [isBackspaceKey, setIsBackspaceKey] = useState(false);

  const handleChangeText = (changedValue: string) => {
    if (isRedesign && !isNative) {
      // Adjusting Cursor position
      setCursorPosition(prevPosition => getCursorPosition(isBackspaceKey, prevPosition));
    }
    setValue(changedValue);
    onChange(changedValue);
  };

  const handleKeyPress = (event: any) => {
    const key = event.nativeEvent.key;

    if (key === 'Backspace') {
      setIsBackspaceKey(true);
    } else {
      setIsBackspaceKey(false);
    }
  };

  return (
    <TextInputMask
      aria-label={formatMessage({ id: 'crownCardNumber' })}
      label={label}
      value={value}
      onChangeText={handleChangeText}
      errorMessage={errorMessage}
      onFocus={onFocus}
      placeholderTextColor={primitive.$blackOpacity50}
      required
      {...(isRedesign && { placeholder: '0000 0000 0000 0000' })}
      {...(isRedesign &&
        !isNative && {
          onSelectionChange: event => {
            const { selection } = event.nativeEvent;
            setCursorPosition(selection.start);
          },
          onKeyPress: handleKeyPress,
          selection: { start: cursorPosition, end: cursorPosition },
        })}
      testID={dataTestId}
      keyboardType="numeric"
      mask={loyaltyCrownCardNumberMask}
    />
  );
}

export default AddGiftCardInput;
