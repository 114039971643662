import React from 'react';

import { HStack, Icon, Text, VStack } from '@rbilabs/universal-components';

import { primitive } from 'styles/constants/primitives';

interface StoreCardMessagesProps {
  errorMessage?: string;
  accessInstructions?: string;
}

export const StoreCardMessages: React.FC<StoreCardMessagesProps> = ({
  errorMessage,
  accessInstructions,
}) => {
  if (!errorMessage && !accessInstructions) {
    return null;
  }

  return (
    <HStack marginTop={'$1.5'}>
      <Icon
        size="xs"
        marginRight="$1"
        marginTop={{ base: '$0', md: '$0.5' }}
        color={primitive.$blackOpacity50}
        variant="info"
      />
      <VStack focusable>
        {accessInstructions && (
          <Text variant="copyTwo" color={primitive.$blackOpacity50}>
            {accessInstructions}
          </Text>
        )}
        {errorMessage && (
          <Text variant="copyTwo" color={primitive.$blackOpacity50}>
            {errorMessage}
          </Text>
        )}
      </VStack>
    </HStack>
  );
};
