import { Menu, MenuEntry } from './menu-api-aliases';
import * as sample from './menu-sample.json';

export function createMenu(_storeId: string): Menu {
  const json = sample as any;
  const rootMenu = json.data.entries.find(
    (entry: any) => entry.id === json.data.rootMenuEntryId
  ) as MenuEntry;
  return {
    meta: json.data.meta,
    configOffers: json.data.configOffers,
    dayParts: json.data.dayParts,
    rewards: json.data.rewards,
    systemWideOffers: json.data.systemWideOffers,
    entries: json.data.entries,
    rootMenu,
  };
}
