import { Circle, ClipPath, Defs, G, Rect } from 'react-native-svg';

import { Svg, SvgPath, SvgProps, registerSVG } from '../../../components/svg';

export function CheckMarkGreen(props: SvgProps) {
  return (
    <Svg width="19" height="19" viewBox="0 0 19 19" fill="none" {...props}>
      <G clipPath="url(#clip0_13756_611">
        <Circle cx="10" cy="10" r="9" fill="white" />
        <SvgPath
          fill="#198737"
          d="M9.5 0C4.256 0 0 4.256 0 9.5C0 14.744 4.256 19 9.5 19C14.744 19 19 14.744 19 9.5C19 4.256 14.744 0 9.5 0ZM7.6 14.25L2.85 9.5L4.1895 8.1605L7.6 11.5615L14.8105 4.351L16.15 5.7L7.6 14.25Z"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_13756_611">
          <Rect width="19" height="19" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

registerSVG('CheckMarkGreen', CheckMarkGreen);
