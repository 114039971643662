import { useRoute } from 'hooks/navigation/use-route';
import { RunningExperiments, useExperiments } from 'state/experiments';
import { routes } from 'utils/routing';

export const useEnableMapListExperiment = () => {
  const { variant } = useExperiments();
  const { pathname } = useRoute();

  return variant(RunningExperiments.MapList, 'treatment', {
    skipExposureLogging: pathname !== routes.storeLocator,
  });
};
