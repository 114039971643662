import { useMemo } from 'react';

import { RunningExperiments, useExperiments } from 'state/experiments';
import { isNative } from 'utils/environment';

export enum ContentCardNotificationsExperimentVariants {
  CONTROL = 'control',
  TREATMENT = 'treatment',
}

// The experiment is available only for iOS and Android
export const useContentCardsNotificationsExperiment = () => {
  const contentCardsExperiment = RunningExperiments.ContentCardsNotifications;
  const { getVariantValue } = useExperiments();

  const { value: experimentValue } = useMemo(() => {
    return getVariantValue(contentCardsExperiment) || {};
  }, [getVariantValue, contentCardsExperiment]);

  const isNewsFeedEnabled = useMemo(
    () => isNative && experimentValue === ContentCardNotificationsExperimentVariants.TREATMENT,
    [experimentValue]
  );

  const isHomePageFeedEnabled = useMemo(
    () => isNative && experimentValue === ContentCardNotificationsExperimentVariants.CONTROL,
    [experimentValue]
  );

  return {
    isNewsFeedEnabled,
    isHomePageFeedEnabled,
    isExperimentEnabled: isNewsFeedEnabled || isHomePageFeedEnabled,
  };
};
