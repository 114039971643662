import { Box } from '@rbilabs/universal-components';
import { useWindowDimensions } from 'react-native';

import { getIsMobileWeb, useIsDesktopLargeBreakpoint } from 'hooks/use-media-query';
import {
  WEB_APP_MAX_WIDTH,
  WEB_APP_MAX_WIDTH_DESKTOP_LARGE,
  WEB_APP_MODAL_CONTENT_MAX_WIDTH,
} from 'utils/constants';

const DEFAULT_PADDING_X_MOBILE = 4; // using design system units 4 = 16px
const DEFAULT_PADDING_X_WEB_MOBILE = 16; // using design system units 16 = 64px

export const LayoutContainer = Box.withConfig(
  ({
    isModal,
    isFullContainer = false,
    backgroundColor,
    maxWidthDesktopLarge,
  }: {
    isModal?: boolean;
    isFullContainer?: boolean;
    backgroundColor?: string;
    maxWidthDesktopLarge?: number;
  }) => {
    const dimension = useWindowDimensions();
    const isDesktop = useIsDesktopLargeBreakpoint();
    const isWebMobile = getIsMobileWeb();

    const maxWidth = isDesktop
      ? isModal
        ? WEB_APP_MODAL_CONTENT_MAX_WIDTH
        : WEB_APP_MAX_WIDTH_DESKTOP_LARGE
      : WEB_APP_MAX_WIDTH;

    const totalPaddingX = (dimension.width - (maxWidthDesktopLarge || maxWidth)) / 2;

    const getDefaultPadding = () =>
      isWebMobile ? DEFAULT_PADDING_X_WEB_MOBILE : DEFAULT_PADDING_X_MOBILE;

    const paddingX = isFullContainer ? 0 : totalPaddingX > 0 ? totalPaddingX : getDefaultPadding();

    return {
      _web: {
        flex: 1,
        paddingX: `${paddingX}px`,
      },
      paddingX,
      flexGrow: 1,
      backgroundColor,
    };
  }
);

export const InnerLayout = Box.withConfig({
  _web: {
    flex: 1,
  },
  paddingX: {
    md: '$16',
    lg: '$32',
    base: '0',
  },
  paddingY: '$4',
  flexGrow: 1,
});
