import React, { ComponentProps, ReactNode, useRef } from 'react';

import { Button } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { Omit } from '@rbi-ctg/frontend';
import ActionButton, { ActionButtonVariants } from 'components/action-button';
import Dialog from 'components/dialog';
import { IDialogProps } from 'components/dialog/types';

export type IConfirmProps = Omit<IDialogProps, 'actions'> &
  ComponentProps<typeof Dialog> & {
    onConfirm: () => void;
    onCancel: () => void;
    confirmLabel?: string | ReactNode;
    cancelLabel?: string | ReactNode;
    modalAppearanceEventMessage: string;
  };

const ConfirmDialog = ({
  // @ts-ignore TS(2590) FIXME: Expression produces a union type that is too complex to represent.
  heading,
  body,
  bodyComponent,
  onDismiss,
  onConfirm,
  onCancel,
  image,
  confirmLabel,
  cancelLabel,
  showDialog,
  testID: dataTestId,
  /**
   * A small string sent to amplitude describing the purpose of the modal.
   */
  modalAppearanceEventMessage,
  ...props
}: IConfirmProps) => {
  const { formatMessage } = useIntl();
  const confirmText = confirmLabel || formatMessage({ id: 'okay' });
  const cancelText = cancelLabel || formatMessage({ id: 'cancel' });
  const confirmButtonRef = useRef<typeof Button>(null);

  return (
    <Dialog
      showDialog={showDialog}
      testID={dataTestId}
      body={body}
      bodyComponent={bodyComponent}
      heading={heading}
      image={image}
      onDismiss={onDismiss}
      initialFocusRef={confirmButtonRef}
      actions={
        <Button.Group>
          <ActionButton
            ref={confirmButtonRef}
            fullWidth
            onPress={onConfirm}
            testID="dialog-confirm-btn"
            skipLogEvent
          >
            {confirmText}
          </ActionButton>
          <ActionButton
            fullWidth
            onPress={onCancel}
            testID="dialog-cancel-btn"
            skipLogEvent
            variant={ActionButtonVariants.TEXT_ONLY}
          >
            {cancelText}
          </ActionButton>
        </Button.Group>
      }
      modalAppearanceEventMessage={modalAppearanceEventMessage}
      {...props}
    />
  );
};

export default ConfirmDialog;
