import React, { forwardRef } from 'react';

import { Alert, Box, FormControl, Input, addWithConfig } from '@rbilabs/universal-components';
import { TextInput as RNTextInput } from 'react-native';

import { useLogUserErrorMessage } from 'hooks/log-user-error-message';

import { AlertContainer, AlertText } from './styled';
import { TextInputProps } from './types';

const EndIconWrapper = Box.withConfig({
  position: 'absolute',
  height: 'full',
  justifyContent: 'center',
  right: '$3',
});

const TextInput = forwardRef<RNTextInput, TextInputProps>(
  (
    {
      alertMessage,
      label,
      errorMessage,
      required,
      hintMessage,
      endIcon,
      labelProps,
      logUserErrorSeverity,
      isDisabled,
      noMargin,
      ...rest
    },
    ref
  ) => {
    useLogUserErrorMessage({
      message: errorMessage,
      severity: logUserErrorSeverity,
      context: {
        // don't log value as it could be sensitive
        label,
        required,
      },
    });

    return (
      <FormControl
        isInvalid={!!errorMessage}
        {...(noMargin && { margin: '$0' })}
        isRequired={required}
      >
        {label && <FormControl.Label {...labelProps}>{label}</FormControl.Label>}
        <Box position="relative">
          <Input {...rest} isDisabled={isDisabled} isReadOnly={isDisabled} ref={ref} />
          {!!endIcon && <EndIconWrapper>{endIcon}</EndIconWrapper>}
        </Box>
        {hintMessage && !errorMessage && (
          <FormControl.HelperText margin="0" p="0">
            {hintMessage}
          </FormControl.HelperText>
        )}
        {errorMessage && (
          <FormControl.ErrorMessage margin="0" p="0">
            {errorMessage}
          </FormControl.ErrorMessage>
        )}
        {!!alertMessage && (
          <AlertContainer>
            <Alert.Icon marginRight="$2" />
            <AlertText>{alertMessage}</AlertText>
          </AlertContainer>
        )}
      </FormControl>
    );
  }
);

export default addWithConfig(TextInput);
