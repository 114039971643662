import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import Dialog from 'components/dialog';
import { CustomEventNames, EventTypes, logEvent } from 'state/amplitude';
import { useNetworkContext } from 'state/network';
import logger from 'utils/logger';

function ErrorNetwork() {
  const { formatMessage } = useIntl();
  const { isNetworkConnected, isInternetReachable } = useNetworkContext();

  // don't spam both types of network issues if one already showed up
  const [hasBeenNotified, setHasBeenNotified] = useState(false);

  // no network connection
  const [isNoNetworkOpen, setIsNoNetworkOpen] = useState(false);
  useEffect(() => {
    if (!isNetworkConnected && !hasBeenNotified) {
      logger.info({
        message: '[BadNetwork] Showing no network connection dialog',
        isNoNetworkConnectionDialog: true,
        isBadNetwork: true,
      });
      logEvent(CustomEventNames.NETWORK_ERROR, EventTypes.Other);
      setHasBeenNotified(true);
      setIsNoNetworkOpen(true);
    }
  }, [hasBeenNotified, isNetworkConnected]);

  // bad network connection
  const [isBadNetworkOpen, setIsBadNetworkOpen] = useState(false);
  useEffect(() => {
    if (!isInternetReachable && !hasBeenNotified) {
      logger.info({
        message: '[BadNetwork] Showing bad internet dialog',
        isBadInternetDialog: true,
        isBadNetwork: true,
      });
      logEvent(CustomEventNames.NETWORK_ERROR, EventTypes.Other);
      setHasBeenNotified(true);
      setIsBadNetworkOpen(true);
    }
  }, [hasBeenNotified, isInternetReachable]);

  if (isNoNetworkOpen) {
    return (
      <Dialog
        showDialog
        modalAppearanceEventMessage="Network Error"
        testID="error-network-dialog"
        heading={formatMessage({ id: 'lostNetworkConnectivity' })}
        body={formatMessage({ id: 'checkNetworkSettings' })}
        accessibilityLabel={formatMessage({ id: 'lostNetworkConnectivity' })}
        isErrorModal
        showCloseButton
        onDismiss={() => {
          setIsNoNetworkOpen(false);
        }}
      />
    );
  }

  if (isBadNetworkOpen) {
    const heading = formatMessage({ id: 'lostNetworkConnectivity' });
    return (
      <Dialog
        showDialog
        modalAppearanceEventMessage={heading}
        testID="error-bad-network-dialog"
        heading={heading}
        body={formatMessage({ id: 'badNetworkDialogBody' })}
        accessibilityLabel={heading}
        isErrorModal
        showCloseButton
        onDismiss={() => {
          setIsBadNetworkOpen(false);
        }}
      />
    );
  }

  return null;
}

export default ErrorNetwork;
