import { useCallback, useMemo } from 'react';

import { useRoute } from 'hooks/navigation/use-route';
import { useIsMobileBreakpoint } from 'hooks/use-media-query';
import { RunningExperiments, useExperiments } from 'state/experiments';
import { useStoreContext } from 'state/store';
import { routes } from 'utils/routing';

interface IUseMenuTopServiceMode {
  evaluateMenuTopServiceModeExperiment: () => boolean;
  isTreatmentEnabled: boolean;
}
export enum MenuTopServiceModeExperimentVariants {
  CONTROL = 'control',
  TREATMENT = 'treatment',
}

export const useMenuTopServiceMode = (): IUseMenuTopServiceMode => {
  const menuTopServiceModeExperiment = RunningExperiments.MenuTopServiceMode;

  const isMobile = useIsMobileBreakpoint();
  const { pathname } = useRoute();
  const { variant, getVariantValue } = useExperiments();
  const { noStoreSelected } = useStoreContext();

  const isBaseRoute = pathname === routes.base;
  const isMenuPage = pathname.includes(routes.menu);

  const { value: experimentValue } = useMemo(() => {
    return getVariantValue(menuTopServiceModeExperiment) || {};
  }, [getVariantValue, menuTopServiceModeExperiment]);

  const isControl = experimentValue === MenuTopServiceModeExperimentVariants.CONTROL;
  const isTreatment = experimentValue === MenuTopServiceModeExperimentVariants.TREATMENT;

  const evaluateMenuTopServiceModeExperiment = useCallback(() => {
    if (noStoreSelected || !isMobile) {
      return false;
    }

    // If the user is not assigned to any variant, show the control experience, i.e.,
    // show the service mode header on the homepage
    if (!experimentValue && isBaseRoute) {
      return true;
    }

    // Control and treatment variants should be logged for exposure on the homepage.
    if (experimentValue && isBaseRoute) {
      variant(menuTopServiceModeExperiment, experimentValue);

      // Control variant should show the top service mode header on the homepage.
      if (isControl) {
        return true;
      }

      // Treatment variant should not show the top service mode header on the homepage.
      if (isTreatment) {
        return false;
      }
    }

    // Treatment variant should show the top service mode header on the menu page, but not log exposure.
    if (isMenuPage) {
      return experimentValue === MenuTopServiceModeExperimentVariants.TREATMENT;
    }

    return false;
  }, [
    experimentValue,
    isBaseRoute,
    isControl,
    isMenuPage,
    isMobile,
    isTreatment,
    menuTopServiceModeExperiment,
    noStoreSelected,
    variant,
  ]);

  return {
    evaluateMenuTopServiceModeExperiment,
    isTreatmentEnabled: experimentValue === MenuTopServiceModeExperimentVariants.TREATMENT,
  };
};
