import { Box, Button, Image, Text } from '@rbilabs/universal-components';

import Link from 'components/link';
import { primitive } from 'styles/constants/primitives';

export const ContentCardContainer = Box.withConfig({
  position: 'relative',
  shadow: '1',
  borderRadius: 12,
  margin: '$2.5',
  background: 'white',
});

export const ClassicCardContainer = Box.withConfig({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  height: '136px',
});

export const ClassicCardImage = Image.withConfig({
  height: 'auto',
  borderTopLeftRadius: 12,
  borderBottomLeftRadius: 12,
  _web: {
    height: 'full',
  },
});

export const ClassicCardContent = Box.withConfig({
  flex: 1,
  paddingX: '$4',
  paddingY: '$2.5',
});

export const CardTitle = Text.withConfig({
  variant: 'headerThree',
  fontSize: 'md',
  numberOfLines: 2,
  ellipsizeMode: 'tail',
  marginBottom: '$1',
});

export const CardDescription = Text.withConfig({
  fontSize: 'xs',
  lineHeight: 'xs',
  numberOfLines: 2,
  ellipsizeMode: 'tail',
});

export const ButtonContainer = Box.withConfig({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  paddingTop: '$4',
});

type CardButtonProps = {
  isChallenge?: boolean;
};

export const CardButton = Button.withConfig(({ isChallenge }: CardButtonProps) => ({
  variant: 'outline',
  ...(isChallenge && {
    variant: 'solid',
    backgroundColor: primitive.bk.$meltyYellow,
    textDecorationColor: primitive.bk.$meltyYellow,
    _hover: {
      backgroundColor: primitive.bk.$meltyYellowDark45,
    },
    _pressed: {
      backgroundColor: primitive.bk.$meltyYellowDark40,
    },
  }),
  height: '$7.5',
  size: 'sm',
  _text: {
    variant: 'copyTwo',
    fontSize: 'xs',
    lineHeight: 'xs',
    ...(isChallenge && { color: primitive.bk.$bbqBrown }),
  },
}));

export const CaptionedCardContainer = Box.withConfig({
  display: 'flex',
  flexDirection: 'column',
});

export const CaptionedCardImage = Image.withConfig({
  width: 'full',
  borderTopRadius: 12,
});

export const CaptionedCardContent = ClassicCardContent;

export const ImageOnlyCardImage = Image.withConfig({
  width: 'full',
  borderRadius: 12,
});

export const StyledLink = Link.withConfig({
  _web: { cursor: 'pointer' },
  marginBottom: '$1',
  _text: { textDecoration: 'underline' },
});
