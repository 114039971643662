import { PropsWithChildren, createElement, useMemo } from 'react';

import { KeyboardProvider } from 'react-native-keyboard-controller';

import { ConfettiFriesAnimationProvider } from 'components/lottie-animations/confetti-fries';
import { AttPermissionProvider } from 'state/app-tracking-transparency-permissions';
import { AuthProvider } from 'state/auth';
import { BrazeProvider } from 'state/braze';
import { ErrorsProvider } from 'state/errors';
import { ExperimentsProvider } from 'state/experiments';
import { FavoritesProvider } from 'state/favorites';
import { FlyBuyProvider } from 'state/flybuy';
import { GeolocationProvider } from 'state/geolocation';
import { GlobalStateProvider } from 'state/global-state/provider';
import { GraphQLProvider } from 'state/graphql';
import { HelmetProvider } from 'state/helmet';
import { IntlProvider } from 'state/intl';
import { LDProvider } from 'state/launchdarkly';
import { LocationProvider } from 'state/location';
import { LoyaltyProvider } from 'state/loyalty';
import { MenuProvider } from 'state/menu';
import { MenuToastProvider } from 'state/menu/toast-menu';
import { MobileFooterProvider } from 'state/mobile-footer';
import { MobileHeaderNavProvider } from 'state/mobile-header-nav';
import { NetworkProvider } from 'state/network';
import { OrderProvider } from 'state/order';
import { PaymentProvider } from 'state/payment';
// import { PaymentProvider as PaymentProviderV2 } from 'state/payments';
import { PushPermissionProvider } from 'state/push-permissions';
import { ReversedUIProvider } from 'state/reversed-ui';
import { ScrollProvider } from 'state/scroll';
import { ServiceModeProvider } from 'state/service-mode';
import { PageManagerProvider } from 'state/static-page-manager';
import { StoreProvider } from 'state/store';
import { UIProvider } from 'state/ui';
import { UnifiedMenuProvider } from 'state/unified-menu';
import { theme } from 'styles/theme';
import { HideSplashScreen } from 'utils/hide-splash-screen';

import { UclProvider } from './ucl';

const themeConfig = { theme };

const useProviders = () => {
  const UclProviderWithTheme = useMemo(
    () => ({ children }: PropsWithChildren<{}>) =>
      createElement(UclProvider, themeConfig, children),
    []
  );

  return [
    KeyboardProvider,
    UclProviderWithTheme,
    GlobalStateProvider,
    LDProvider,
    IntlProvider,
    LocationProvider,
    GraphQLProvider,
    BrazeProvider,
    ExperimentsProvider,
    ErrorsProvider,
    NetworkProvider,
    AuthProvider,
    UIProvider,
    MenuToastProvider,
    ServiceModeProvider,
    StoreProvider,
    LoyaltyProvider,
    MenuProvider,
    UnifiedMenuProvider,
    MobileHeaderNavProvider,
    MobileFooterProvider,
    PaymentProvider,
    PageManagerProvider,
    PushPermissionProvider,
    OrderProvider,
    FavoritesProvider,
    GeolocationProvider,
    HelmetProvider,
    ReversedUIProvider,
    ScrollProvider,
    FlyBuyProvider,
    ConfettiFriesAnimationProvider,
    AttPermissionProvider,

    // "clever" way to hide the splash screen when the app is ready to be displayed
    // this is not a provider "really" but keep this at the bottom of the provider tree to allow "lots of react stuff"
    // to run before hiding the home screen to avoid a blank screen between splash and app skeleton load
    HideSplashScreen,
  ];
};

export default useProviders;
