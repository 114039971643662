import React, { useCallback, useRef } from 'react';

import { Box, NbModal, NbModalProps, makeUclComponent } from '@rbilabs/universal-components';
import { StatusBar } from 'expo-status-bar';
import { isString, noop } from 'lodash-es';
import { Platform } from 'react-native';

import { IBaseProps } from '@rbi-ctg/frontend';
import CloseButton from 'components/close-button';
import { KeyboardAwareView } from 'components/ucl/keyboard-aware-view';
import usePreventBodyScroll from 'hooks/use-prevent-body-scroll';
import { CustomEventNames, EventTypes, logRBIEvent } from 'state/amplitude';
import { hiddenAccessibilityPlatformProps } from 'utils/accessibility';
import { isIOS } from 'utils/environment';
import logger from 'utils/logger';

import { SAFE_AREA_TOP_INSET } from './constants';
import { FocusableContainer } from './focusable-container';
import theme from './theme';
import { IModalProps } from './types';
import { findInnerHeaderText } from './utils';

export enum ModalSize {
  FULLSCREEN = 'FULLSCREEN',
  REGULAR = 'REGULAR',
  SMALL = 'SMALL',
  LANDSCAPE = 'LANDSCAPE',
}

// Returning `true` for iOS will set a default inset
export const SAFE_AREA_Y_VALUE = Platform.OS === 'web' || !isIOS() ? SAFE_AREA_TOP_INSET : true;

const ModalContainer = NbModal.withConfig<{
  $backgroundColor?: NbModalProps['backgroundColor'];
  $backgroundImage?: NbModalProps['bgImage'];
}>(p => ({
  backgroundColor: p.$backgroundColor,
  bgImage: p.$backgroundImage ? p.$backgroundImage : null,
  _web: {
    backgroundColor: 'transparent',
  },
}));

const CloseButtonWrapper = Box.withConfig(
  ({ isDismissButtonAbsolute }: { isDismissButtonAbsolute: boolean }) => {
    return {
      position: isDismissButtonAbsolute ? 'absolute' : 'unset',
      left: '$5',
      zIndex: 1,
      width: '$12',
      height: '$12',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '-12px',
      marginTop: '$1',
      _web: {
        borderRadius: '50%',
        safeAreaY: 0,
        marginLeft: 0,
      },
    };
  }
);

const Modal = ({
  allowsDismiss = true,
  isDismissButtonAbsolute = true,
  invertedCloseButton = false,
  children,
  onDismiss = noop,
  closeButton: CloseButtonCmp = CloseButton,
  amplitudeEventData: inEventData,
  height,
  skipLoggingHeaderAndMessage = false,
  backgroundColor = theme.modalContentBackground,
  backgroundImage,
  header = null,
  footer = null,
  body = null,
  // TODO: RN - check props and size against UCL Modal
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  size = ModalSize.REGULAR,
  contentStyle,
  headerStyle,
  isErrorModal,
  hideStatusBar = false,
  scrollViewBounces = true,
  closeButtonStyle,
  isFullHeightContent,
  withScroll = true,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ...props
}: IModalProps) => {
  const amplitudeEventData = useRef(inEventData);
  usePreventBodyScroll();

  const contentRef = useCallback(
    (node?: any) => {
      if (!node) {
        return;
      }
      const { modalHeader, modalMessage, modalAppearanceEventMessage } = amplitudeEventData.current;
      const header = modalHeader || findInnerHeaderText(node) || '';
      const message = modalMessage || node.innerText;

      const context = {
        ModalHeader: skipLoggingHeaderAndMessage ? '' : header,
        ModalMessage: skipLoggingHeaderAndMessage ? '' : isString(message) ? message : '',
        isErrorModal,
      };

      logRBIEvent({
        name: CustomEventNames.MODAL_APPEARANCE,
        type: EventTypes.Other,
        attributes: {
          ...context,
          Message: modalAppearanceEventMessage,
          bodyMessage: context.ModalMessage,
          modalHeader: context.ModalHeader,
        },
      });

      if (isErrorModal) {
        logger.error({
          message: modalAppearanceEventMessage,
          ...context,
        });
      }
    },
    [skipLoggingHeaderAndMessage, isErrorModal]
  );

  return (
    <ModalContainer
      isOpen
      size="full"
      onClose={onDismiss}
      ref={contentRef as any}
      $backgroundColor={backgroundColor}
      $backgroundImage={backgroundImage}
      // size={size}
      height={height}
    >
      <StatusBar hidden={hideStatusBar} backgroundColor={backgroundColor} />
      <NbModal.Content
        accessibilityRole="alert"
        accessibilityViewIsModal
        maxHeight="100%"
        backgroundColor={backgroundColor}
        safeAreaX={isIOS() || 0}
        safeAreaY={hideStatusBar || isFullHeightContent ? 0 : SAFE_AREA_Y_VALUE}
        flex={1}
        style={{ ...contentStyle }}
      >
        <NbModal.Header padding={0} zIndex={1} borderBottomWidth={0} {...headerStyle}>
          {
            // prevent content overlay with the close button
            allowsDismiss && (
              <CloseButtonWrapper
                isDismissButtonAbsolute={isDismissButtonAbsolute}
                safeAreaY={hideStatusBar || isFullHeightContent ? SAFE_AREA_Y_VALUE : undefined}
              >
                <CloseButtonCmp
                  style={{ ...closeButtonStyle }}
                  onClick={onDismiss}
                  testID="modal-close-button"
                  inverted={invertedCloseButton}
                  {...hiddenAccessibilityPlatformProps}
                />
              </CloseButtonWrapper>
            )
          }
          {header}
        </NbModal.Header>

        <KeyboardAwareView
          withScroll={withScroll}
          style={{ padding: 0 }}
          bounces={scrollViewBounces}
        >
          <FocusableContainer>{body || children}</FocusableContainer>
        </KeyboardAwareView>
        {footer && (
          <NbModal.Footer padding={0} backgroundColor="transparent">
            {footer}
          </NbModal.Footer>
        )}
      </NbModal.Content>
    </ModalContainer>
  );
};

export const ModalContent = makeUclComponent((props: IBaseProps) => {
  return (
    <Box paddingX="$4" paddingY="$6" {...props}>
      {props.children}
    </Box>
  );
});

export { default as ModalHeading } from 'components/modal/modal-heading';
export default Modal;
