import * as React from 'react';
import { FC, useMemo } from 'react';

import { ScrollView } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { useEnableMapListExperiment } from 'experiments/use-enable-map-list-experiment';
import { useAuthContext } from 'state/auth';
import { OrderLocatorTab } from 'state/launchdarkly/variations';
import { isWeb } from 'utils/environment';

import { DEFAULT_CONTENT, EMPTY_STATES_CONTENT } from './constants';
import { EmptyStateAction } from './empty-state-action';
import { BodyText, HeadingText, IconWrapper, Wrapper } from './store-locator-empty-states.styled';
import { EmptyStateCategories, IStoreLocatorEmptyStatesProps } from './types';

function tabCaresAboutAuthStatus(tab: OrderLocatorTab): boolean {
  if (tab === OrderLocatorTab.FAVORITE || tab === OrderLocatorTab.RECENT) {
    return true;
  }
  return false;
}
function tabCaresAboutLocationStatus(tab: OrderLocatorTab): boolean {
  if (tab === OrderLocatorTab.NEARBY) {
    return true;
  }
  return false;
}

/**
 * determineEmptyStateCategory
 *  logic for prioritization & determination
 *  of empty state category for specified tab
 *  Priority Levels:
 *    1. An error occurreed
 *    2. Unauthenticated
 *    3. Location Permissions Not Granted
 *    4. No Stores Available
 */
function determineEmptyStateCategory({
  tab,
  isAuthenticated,
  locationPermissionsGranted,
  currentTabError,
  coordinatesAvailable,
}: {
  tab: OrderLocatorTab;
  isAuthenticated: boolean;
  locationPermissionsGranted: boolean;
  currentTabError: boolean;
  coordinatesAvailable?: boolean;
}): EmptyStateCategories {
  let category = EmptyStateCategories.NO_STORES;
  if (currentTabError) {
    category = EmptyStateCategories.ERROR;
  } else if (tabCaresAboutAuthStatus(tab) && !isAuthenticated) {
    category = EmptyStateCategories.NO_AUTH;
  } else if (
    tabCaresAboutLocationStatus(tab) &&
    // if we have manual coords, we don't want the empty state to be no location
    !coordinatesAvailable &&
    !locationPermissionsGranted
  ) {
    category = EmptyStateCategories.NO_LOCATION;
  }
  return category;
}

/**
 *
 * StoreLocatorEmptyStates enables displaying different empty state messages for store locator tabs
 *
 */
export const StoreLocatorEmptyStates: FC<React.PropsWithChildren<
  IStoreLocatorEmptyStatesProps
>> = ({ tab, locationPermissionsGranted, currentTabError, coordinatesAvailable }) => {
  const { isAuthenticated } = useAuthContext();
  const { formatMessage } = useIntl();
  const enableMapListExperiment = useEnableMapListExperiment();

  const { icon: Icon, titleId, bodyId, action } = useMemo(() => {
    const category = determineEmptyStateCategory({
      tab,
      isAuthenticated,
      locationPermissionsGranted,
      currentTabError,
      coordinatesAvailable,
    });
    return EMPTY_STATES_CONTENT[tab]?.[category] || DEFAULT_CONTENT;
  }, [currentTabError, isAuthenticated, locationPermissionsGranted, tab, coordinatesAvailable]);

  return (
    <ScrollView>
      <Wrapper
        focusable
        paddingTop={enableMapListExperiment ? '$8' : undefined}
        paddingBottom={enableMapListExperiment ? '$2' : undefined}
      >
        {Icon && (
          <IconWrapper>
            <Icon />
          </IconWrapper>
        )}
        <HeadingText>{formatMessage({ id: titleId })}</HeadingText>
        {!(enableMapListExperiment && isWeb) && (
          <BodyText>{formatMessage({ id: bodyId })}</BodyText>
        )}
        {action && <EmptyStateAction action={action} />}
      </Wrapper>
    </ScrollView>
  );
};
