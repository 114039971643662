import { FC, PropsWithChildren, useCallback } from 'react';

import { ContentCard } from '@braze/react-native-sdk';

import { useNavigation } from 'hooks/navigation/use-navigation';
import useBrazeContext from 'state/braze/hooks/use-braze-context';
import { ContentCardTypes } from 'state/braze/types';
import { actions, useAppDispatch } from 'state/global-state';

import {
  ButtonContainer,
  CardButton,
  CardDescription,
  CardTitle,
  ClassicCardContainer,
  ClassicCardContent,
  ClassicCardImage,
  ContentCardContainer,
} from './styled';

type ClassicContentCardProps = {
  card: ContentCard & {
    type: typeof ContentCardTypes.CLASSIC;
  };
};

export const ClassicContentCard: FC<PropsWithChildren<ClassicContentCardProps>> = ({ card }) => {
  const { logContentCardClicked } = useBrazeContext();
  const dispatch = useAppDispatch();
  const { navigate } = useNavigation();

  const handleClickCta = useCallback(() => {
    logContentCardClicked(card);
    navigate(card.url || '');
    dispatch(actions.loyalty.closeInbox());
  }, [card, dispatch, logContentCardClicked, navigate]);

  return (
    <ContentCardContainer>
      <ClassicCardContainer>
        {card.image && (
          <ClassicCardImage
            alt={card.title}
            source={{ uri: card.image }}
            style={{ aspectRatio: 1 }}
          />
        )}
        <ClassicCardContent>
          <CardTitle>{card.title}</CardTitle>
          <CardDescription>{card.cardDescription}</CardDescription>
          <ButtonContainer>
            {!!card.url && <CardButton onPress={handleClickCta}>{card.domain}</CardButton>}
          </ButtonContainer>
        </ClassicCardContent>
      </ClassicCardContainer>
    </ContentCardContainer>
  );
};
