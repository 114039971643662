import { Svg, SvgPath, SvgProps, SvgRect, registerSVG } from '../../../components/svg';

export function SmallCreditCardWhite(props: SvgProps) {
  return (
    <Svg {...props} viewBox="0 0 30 20" fill="none">
      <SvgRect x="0.666992" width="29.3333" height="20" rx="4" fill="white" />
      <SvgPath
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.77778 5.33339C6.77778 4.90383 7.126 4.55561 7.55556 4.55561H23.1111C23.5407 4.55561 23.8889 4.90383 23.8889 5.33339V6.11145H6.77783V9.22256H23.8889V14.6667C23.8889 15.0963 23.5407 15.4445 23.1111 15.4445H7.55556C7.126 15.4445 6.77778 15.0963 6.77778 14.6667V5.33339ZM6 5.33339C6 4.47428 6.69645 3.77783 7.55556 3.77783H23.1111C23.9702 3.77783 24.6667 4.47428 24.6667 5.33339V14.6667C24.6667 15.5258 23.9702 16.2223 23.1111 16.2223H7.55556C6.69645 16.2223 6 15.5258 6 14.6667V5.33339Z"
        fill="#502314"
      />
    </Svg>
  );
}

registerSVG('SmallCreditCardWhite', SmallCreditCardWhite);
