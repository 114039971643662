import React from 'react';

import { View } from '@rbilabs/universal-components';

import { ModalAuth } from 'components/modal-auth';
import { RemoveUnavailableItemsModal } from 'components/remove-unavailable-items-modal';
import { CurbsideConfirmationModal } from 'components/top-service-mode/curbside-confirmation-modal';
import { DialogStaticPage } from 'pages/authentication/components/dialog-static-page';
import LoyaltyOptInModal from 'pages/loyalty/loyalty-opt-in-modal';
import { useAuthContext } from 'state/auth';
import { ModalAuthTrigger } from 'state/auth/types';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { OnboardingContent } from 'state/onboarding';
import { useOrderContext } from 'state/order';
import { useUIContext } from 'state/ui';

export function AppModals() {
  const { modalAuthState } = useAuthContext();

  const {
    isPreConfirmCurbside,
    recent: { unavailablePendingRecentItem, setUnavailablePendingRecentItem },
  } = useOrderContext();

  const {
    isUpdateModalOpen,
    shouldShowPreConfirmCurbsideModal,
    setShouldShowPreConfirmCurbsideModal,
  } = useUIContext();

  const onboardingEnabled = useFlag(LaunchDarklyFlag.ENABLE_FEATURE_ONBOARDING);

  const curbsideModalDismiss = () => {
    setShouldShowPreConfirmCurbsideModal(false);
  };

  return (
    <>
      <View testID="app-modals-layout">
        <LoyaltyOptInModal />
        {unavailablePendingRecentItem && (
          <RemoveUnavailableItemsModal
            unavailableCartEntries={[unavailablePendingRecentItem]}
            onConfirmCb={() => setUnavailablePendingRecentItem(null)}
          />
        )}
      </View>

      {modalAuthState.trigger === ModalAuthTrigger.Global && <ModalAuth />}
      {!isUpdateModalOpen && onboardingEnabled && <OnboardingContent />}
      {!isUpdateModalOpen && shouldShowPreConfirmCurbsideModal && isPreConfirmCurbside() && (
        <CurbsideConfirmationModal showDialog onDismiss={curbsideModalDismiss} />
      )}
      <DialogStaticPage />
    </>
  );
}
