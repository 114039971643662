import { HandlerType, StatusType, datadogLogs } from '@datadog/browser-logs';
import { isObject } from 'lodash-es';

import { initDatadogRum } from 'hooks/rum/init-datadog-rum';
import { getStaticLocationString } from 'state/location';
import { appVersion, webVersion } from 'utils/app-version-info';
import AuthStorage from 'utils/cognito/storage';
import { getDeviceId } from 'utils/device-id';
import { loadRegion } from 'utils/intl/region';
import { StorageKeys } from 'utils/local-storage';
import { LogLevel } from 'utils/logger';

import {
  RBIEnv,
  brand,
  checkRbiEnv,
  env,
  getConfigValue,
  getCurrentCommitHash,
  isLocalDev,
  platform,
} from '../environment';

import { makeBeforeSendCallback } from './make-before-send-callback';
import { IDatadogLogger, UNKNOWN_ERROR_MESSAGE } from './types';

export { StatusType } from '@datadog/browser-logs';

export function initDatadog() {
  initDatadogLogging();
  initDatadogRum();
}

// we are sampling web logs (unlike native!) because they are very spammy with "forwardErrorsToLogs: true," on
// potentially stop sampling if we turn this off.
const WEB_LOG_SAMPLE_RATE = isLocalDev || env() !== RBIEnv.PROD ? 100 : 1;

function initDatadogLogging(): void {
  let handler: HandlerType = HandlerType.http;

  if (checkRbiEnv(RBIEnv.TEST)) {
    handler = HandlerType.silent;
  }

  const dataDogConfig = getConfigValue({
    key: 'datadog',
    region: loadRegion(),
    defaultValue: { logs: {} },
  });
  const datacenter = dataDogConfig.logs.dataCenter ?? 'us';
  const clientToken = dataDogConfig.logs.clientToken;
  const currentEnv = env();

  datadogLogs.init({
    clientToken,
    datacenter,
    // TODO revisit this property. All console.errors go to datadog and it is sometimes duplicative with other logging we have.
    forwardErrorsToLogs: true,
    env: currentEnv,
    service: `${brand()}_${platform()}`,
    version: appVersion,
    beforeSend: makeBeforeSendCallback(WEB_LOG_SAMPLE_RATE),
  });

  datadogLogs.logger.setHandler(handler);
}

getDeviceId().then(deviceId => addContext('deviceId', deviceId));

export function dataDogLogger({ message, context = {}, status }: IDatadogLogger): void {
  const currentEnv = env();
  const ctx: any = {
    appVersionCode: appVersion,
    brand: brand(),
    platform: platform(),
    stage: currentEnv,
    userId: AuthStorage?.getItem(StorageKeys.USER_AUTH_TOKEN) ?? undefined,
    pageLocation: getStaticLocationString(),
    buildInfo: {
      commitHash: getCurrentCommitHash(),
      version: webVersion,
    },
    ...context,
  };

  const err = ctx.error;

  if (err && isObject(err) && (err as any)?.message) {
    // this is likely not a JS error object, so assign it to a different field or it wont show up in DD
    ctx.errorObj = err;
  }

  const strMessage: string = (() => {
    if (typeof message === 'string') {
      return message;
    }

    if (ctx?.error?.message) {
      return ctx?.error?.message;
    }

    if (ctx?.error) {
      return UNKNOWN_ERROR_MESSAGE;
    }

    return 'Unknown message';
  })();

  datadogLogs.logger.log(strMessage, ctx, getMappedLevel(status));
}

const getMappedLevel = (status: LogLevel): StatusType => {
  if (status === LogLevel.trace || status === LogLevel.debug) {
    return 'debug';
  }
  if (status === LogLevel.info) {
    return 'info';
  }
  if (status === LogLevel.warn) {
    return 'warn';
  }
  if (status === LogLevel.error || status === LogLevel.fatal) {
    return 'error';
  }
  return 'info';
};

export function addContext(key: string, value: string) {
  datadogLogs.logger.setContext({ ...datadogLogs.logger.getContext(), [key]: value });
}
