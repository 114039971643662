function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { ValueSetter } from "./Setter.js";
export class Parser {
  constructor() {
    _defineProperty(this, "incompatibleTokens", void 0);

    _defineProperty(this, "priority", void 0);

    _defineProperty(this, "subPriority", void 0);
  }

  run(dateString, token, match, options) {
    var result = this.parse(dateString, token, match, options);

    if (!result) {
      return null;
    }

    return {
      setter: new ValueSetter(result.value, this.validate, this.set, this.priority, this.subPriority),
      rest: result.rest
    };
  }

  validate(_utcDate, _value, _options) {
    return true;
  }

}