import { useMemo, useState } from 'react';

import { useIntl } from 'react-intl';

import ConfirmDialog from 'components/confirm-dialog';
import { useGetLoginRoute } from 'hooks/navigation/use-get-login-route';
import { useNavigation } from 'hooks/navigation/use-navigation';
import {
  CustomEventNames,
  EventTypes,
  logRBIEvent,
  updateAmplitudeUserAttributes,
} from 'state/amplitude';
import { useAuthContext } from 'state/auth';

import { ALLOW_REMOVE_LAST_FAVORITE_STORE } from './constants';

export function useFavoriteStores() {
  const { user } = useAuthContext();
  const existingFavoriteStores = useMemo(() => {
    const userFavoriteStores = user?.details?.favoriteStores || [];
    const trimmedFavoriteStores = userFavoriteStores.map(store => ({
      storeId: store.storeId,
      storeNumber: store.storeNumber || '',
    }));
    // We need to remove falsy ids that were previously saved from bad sanity data
    return trimmedFavoriteStores.filter(store => !!store.storeId);
  }, [user]);

  return {
    validFavStores: existingFavoriteStores,
  };
}
export const useFavoriteStore = (storeId: string, storeNumber: string) => {
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const { updateUserFavStores, isAuthenticated } = useAuthContext();
  const { validFavStores } = useFavoriteStores();
  const [errorDialogActive, setErrorDialogActive] = useState(false);

  const [favStoreLoading, setFavStoreLoading] = useState(false);
  const [hasErrorRemovingLastFavStore, setHasErrorRemovingLastFavStore] = useState(false);
  const loginRoute = useGetLoginRoute();

  const isFavorite = useMemo(() => !!validFavStores.find(store => store.storeId === storeId), [
    storeId,
    validFavStores,
  ]);

  const onDismissErrorRemovingLastFavStoreModal = () => {
    setHasErrorRemovingLastFavStore(false);
  };

  const toggleFavorite = async () => {
    if (!isAuthenticated) {
      setErrorDialogActive(true);
      return;
    }

    // Show error dialog when trying to remove the last favorite store if not allowed
    if (isFavorite && validFavStores.length === 1 && !ALLOW_REMOVE_LAST_FAVORITE_STORE) {
      setHasErrorRemovingLastFavStore(true);
      return;
    }

    const allFavoriteStores = isFavorite
      ? validFavStores.filter(store => store.storeId !== storeId)
      : [{ storeId, storeNumber }, ...validFavStores];
    // We need to remove falsy ids before we save to clean up bad sanity data
    const allValidFavoriteStores = allFavoriteStores.filter(store => !!store.storeId);
    setFavStoreLoading(true);
    await updateUserFavStores(allValidFavoriteStores);
    updateAmplitudeUserAttributes({
      favoriteStores: JSON.stringify(allValidFavoriteStores.map(store => store?.storeNumber)),
    });

    logRBIEvent(
      {
        name: CustomEventNames.STORE_FAVORITE_BUTTON_CLICKED,
        type: EventTypes.Other,
        attributes: {
          storeId,
          status: isFavorite ? 'unfavorited' : 'favorited',
        },
      },
      { skipLoggingToBraze: true }
    );

    setFavStoreLoading(false);
  };

  const errorFavoriteStoreDialog = errorDialogActive ? (
    <ConfirmDialog
      showDialog
      heading={formatMessage({ id: 'errorDialogFavoriteStoreTitle' })}
      body={formatMessage({ id: 'errorDialogFavoriteStoreMessage' })}
      confirmButtonLabel={formatMessage({ id: 'signUp' })}
      onConfirm={() => navigate(loginRoute)}
      onCancel={() => setErrorDialogActive(false)}
      modalAppearanceEventMessage="Error: Sign In to Set Favorite Stores"
      isErrorModal
    />
  ) : (
    <></>
  );

  return {
    toggleFavorite,
    isFavorite,
    errorFavoriteStoreDialog,
    favStoreLoading,
    hasErrorRemovingLastFavStore,
    onDismissErrorRemovingLastFavStoreModal,
  };
};
