import { useCallback } from 'react';

import { IRestaurant } from '@rbi-ctg/store';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useSelectRestaurant } from 'hooks/store-card';
import { CustomEventNames, EventTypes, logEvent, logRBIEvent } from 'state/amplitude';
import { actions, useAppDispatch } from 'state/global-state';
import { StoreCardButtonOptions } from 'state/launchdarkly/variations';
import { useStoreContext } from 'state/store';
import { EventName, emitEvent } from 'utils/event-hub';
import { AttributeName } from 'utils/performance';
import { routes } from 'utils/routing';

interface IUseStoreActions {
  restaurant: IRestaurant;
}

export const useStoreActions = ({ restaurant }: IUseStoreActions) => {
  const { selectRestaurant } = useSelectRestaurant({
    restaurant,
  });

  const { navigate } = useNavigation();
  const dispatch = useAppDispatch();
  const { store: currentStore } = useStoreContext();

  const logClick = useCallback(
    (name: StoreCardButtonOptions) => {
      logEvent(CustomEventNames.BUTTON_CLICK_STORE_BUTTON, EventTypes.Other, {
        Name: name,
        StoreID: restaurant.number,
      });
    },
    [restaurant]
  );

  const logRBIStoreSelected = useCallback(() => {
    logRBIEvent({
      name: 'Store Selected',
      type: EventTypes.Other,
      attributes: {
        restaurantId: restaurant._id || '',
        restaurantAddress: restaurant.physicalAddress?.address1 || '',
        restaurantCity: restaurant.physicalAddress?.city || '',
        restaurantZip: restaurant.physicalAddress?.postalCode || '',
        restaurantState: restaurant.physicalAddress?.stateProvince || '',
        restaurantCountry: restaurant.physicalAddress?.country || '',
        storeId: restaurant.storeId || '',
      },
    });
  }, [restaurant]);

  /**
   * DEFINE BUTTON CLICK HANDLERS
   */
  const onInfoPress = useCallback(() => {
    logClick(StoreCardButtonOptions.INFO);
    navigate(`${routes.store}/${restaurant._id}`);
  }, [logClick, navigate, restaurant._id]);

  const onOrderPress = useCallback(() => {
    selectRestaurant();

    logClick(StoreCardButtonOptions.ORDER);

    // Set shouldRefetchOffers to true on restaurant selection
    dispatch(actions.loyalty.setShouldRefetchOffers(true));

    emitEvent(EventName.RESTAURANT_SELECTED_ON_ORDER, {
      isStoreChange: currentStore?._id !== restaurant?._id,
      attributes: [
        {
          name: AttributeName.RESTAURANT_ID,
          value: restaurant.id || '',
        },
      ],
    });

    logRBIStoreSelected();
  }, [logClick, logRBIStoreSelected, dispatch, restaurant, currentStore, selectRestaurant]);

  return {
    onOrderPress,
    onInfoPress,
  };
};
