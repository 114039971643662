import React from 'react';

import {
  Svg,
  SvgCircle,
  SvgPath,
  SvgProps,
  SvgRect,
  registerSVG,
} from '@rbilabs/universal-components';

import { primitive } from 'styles/constants/primitives';

export function BkUpsellPlus(props: SvgProps) {
  return (
    <Svg width="44" height="44" viewBox="0 0 44 44" fill="none" {...props}>
      <SvgCircle cx="21.4496" cy="21.45" r="12" fill={primitive.bk.$fieryRed} />
      <SvgPath
        d="M27.05 22.2501H22.25V27.0501H20.65V22.2501H15.85V20.6501H20.65V15.8501H22.25V20.6501H27.05V22.2501Z"
        fill="white"
      />
    </Svg>
  );
}

export function BkUpsellPlusV2(props: SvgProps) {
  return (
    <Svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <SvgRect width={29} height={29} x={0.5} y={0.5} stroke={primitive.bk.$bbqBrown} rx={14.5} />
      <SvgPath
        fill={primitive.bk.$bbqBrown}
        d="M20.25 15.75h-4.5v4.5h-1.5v-4.5h-4.5v-1.5h4.5v-4.5h1.5v4.5h4.5v1.5Z"
      />
    </Svg>
  );
}

registerSVG('BkUpsellPlus', BkUpsellPlus);
registerSVG('BkUpsellPlusV2', BkUpsellPlusV2);
