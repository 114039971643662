import React, { useCallback, useEffect } from 'react';

import queryString from 'query-string';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { ActionButtonSizes, ActionButtonVariants } from 'components/action-button';
import { NotificationInbox } from 'components/notification-inbox';
import { SideActionPanel } from 'components/side-action-panel';
import useEffectOnce from 'hooks/use-effect-once';
import { RunningExperiments, useExperiments } from 'state/experiments';
import { actions, selectors, useAppDispatch, useAppSelector } from 'state/global-state';

import {
  BadgeText,
  StyledBellIcon,
  StyledNotificationButton,
  UnreadNotificationBadge,
} from './styled';

const MAX_NOTIFICATIONS_IN_BADGE = 9;

export const NotificationsButton = () => {
  const { logExposure } = useExperiments();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const unreadContentCards = useAppSelector(selectors.loyalty.selectUnreadContentCards);
  const openInbox = useAppSelector(selectors.loyalty.selectOpenInbox);

  const logExperimentExposure = useCallback(() => {
    logExposure(RunningExperiments.ContentCardsNotifications);
  }, [logExposure]);

  const onOpenPanel = useCallback(() => {
    dispatch(actions.loyalty.openInbox());
  }, [dispatch]);

  const onClosePanel = useCallback(() => {
    dispatch(actions.loyalty.closeInbox());
  }, [dispatch]);

  // Used for accessing the Inbox from the URL
  useEffect(() => {
    if (queryParams.news === 'true') {
      onOpenPanel();
    }
  }, [onOpenPanel, queryParams.news]);

  useEffectOnce(() => {
    logExperimentExposure();
  });

  return (
    <>
      <StyledNotificationButton
        variant={ActionButtonVariants.TEXT_ONLY}
        size={ActionButtonSizes.SMALL}
        testID="app-header-notification-cta"
        onPress={onOpenPanel}
        startIcon={<StyledBellIcon />}
        accessibilityLabel={formatMessage({ id: 'news' })}
      >
        {unreadContentCards > 0 && (
          <UnreadNotificationBadge>
            <BadgeText>
              {unreadContentCards > MAX_NOTIFICATIONS_IN_BADGE
                ? `${MAX_NOTIFICATIONS_IN_BADGE}+`
                : unreadContentCards}
            </BadgeText>
          </UnreadNotificationBadge>
        )}
      </StyledNotificationButton>

      <SideActionPanel
        title={formatMessage({ id: 'news' })}
        open={openInbox}
        onClose={onClosePanel}
      >
        <NotificationInbox />
      </SideActionPanel>
    </>
  );
};
