import React, { useCallback, useMemo } from 'react';

import { Box, Icon } from '@rbilabs/universal-components';
import { useIntl } from 'react-intl';

import { Fab } from 'components/fab';
import { useFeatureHomePage } from 'components/features/home-page/use-feature-home-page';
import { ISimpleHomePage } from 'generated/sanity-graphql';
import { useNavigation } from 'hooks/navigation/use-navigation';
import { useRoute } from 'hooks/navigation/use-route';
import { useCart } from 'hooks/use-cart';
import { useIsDesktopBreakpoint } from 'hooks/use-media-query';
import { logButtonClick } from 'state/amplitude';
import { ClickEventComponentNames } from 'state/amplitude/constants';
import { useAuthContext } from 'state/auth';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { isItemPickerOrComboRoute, routes } from 'utils/routing';

const Container = Box.withConfig({
  height: 0,
});

export const LayoutFabButton = () => {
  const { featureHomePage, featureHomePageLoading } = useFeatureHomePage();
  const { formatMessage } = useIntl();
  const { isAuthenticated, openSignInModal } = useAuthContext();
  const enableMoveSignUpAfterCart = useFlag(LaunchDarklyFlag.ENABLE_MOVE_SIGNUP_AFTER_CART);
  const { pathname } = useRoute();
  const isDesktop = useIsDesktopBreakpoint();
  const enableMainFabButton = useFlag(LaunchDarklyFlag.ENABLE_MAIN_FAB_BUTTON);
  const { numberOfItemsInCart } = useCart();
  const { navigate } = useNavigation();
  const isMenuPage = pathname.includes(routes.menu);
  const isRewardsPage = pathname === routes.rewards || pathname === routes.rewardsList;
  const isOfferPage = pathname === routes.rewards + routes.offers;
  const isBaseRoute = pathname === routes.base;
  const isRewardsHistory = pathname === routes.rewardsHistory;

  const onPress = useCallback(() => {
    logButtonClick(
      {
        attributes: {
          component: ClickEventComponentNames.BUTTON,
          name: 'View Cart',
        },
      },
      { logDuplicateClickEvent: true }
    );

    if (isAuthenticated || enableMoveSignUpAfterCart) {
      navigate(routes.cart);
    } else {
      openSignInModal({
        navigateOnSuccessRoute: routes.cart,
      });
    }
  }, [isAuthenticated, enableMoveSignUpAfterCart, navigate, openSignInModal]);

  const homePageHasSimpleHomePage = useMemo(
    () =>
      featureHomePage?.components?.some(
        (cmp): cmp is ISimpleHomePage => cmp?.__typename === 'SimpleHomePage'
      ) ?? false,
    [featureHomePage?.components]
  );

  const shouldHideFabButton = () => {
    if (isDesktop || !enableMainFabButton) {
      return true;
    }

    if (isMenuPage && isItemPickerOrComboRoute(pathname)) {
      return true;
    }

    if (!isMenuPage && !isBaseRoute && !isRewardsPage && !isOfferPage && !isRewardsHistory) {
      return true;
    }

    return isBaseRoute && (featureHomePageLoading || homePageHasSimpleHomePage);
  };

  if (numberOfItemsInCart < 1 || shouldHideFabButton()) {
    return null;
  }

  const fabCaption = formatMessage({ id: 'viewCart' });

  return (
    <Container>
      <Fab
        caption={fabCaption}
        badge={numberOfItemsInCart}
        testID={`nav-${fabCaption}`}
        onPress={onPress}
        leftIcon={<Icon variant={'cart'} size={'sm'} />}
      />
    </Container>
  );
};
