import * as React from 'react';
import { FC, memo } from 'react';

import { useIntl } from 'react-intl';

import { OfferListItem } from 'components/offer-list-item/offer-list-item';
import Picture from 'components/picture';
import { LaunchDarklyFlag, useFlag } from 'state/launchdarkly';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { isActiveWalmartUser, isWalmartOffer } from 'state/loyalty/hooks/utils/walmart';
import { IncentiveSource } from 'state/loyalty/types';

import { OffersServiceMode } from '../../loyalty-offers/types';
import { IncentiveCardDescription } from '../incentive-card/incentive-card.styled';
import { ParsedRewardData } from '../incentive-details/types';

export interface ILoyaltyIOffersItem {
  handlePress: () => void;
  incentiveData: ParsedRewardData;
}

export const LoyaltyOffersItem: FC<ILoyaltyIOffersItem> = memo(
  ({ handlePress, incentiveData }) => {
    const { formatMessage } = useIntl();
    const { loyaltyUser } = useLoyaltyUser();
    const enableWalmartPlusIntegration = useFlag(LaunchDarklyFlag.ENABLE_WALMART_PLUS_INTEGRATION);
    const isWalmartUser = enableWalmartPlusIntegration && isActiveWalmartUser(loyaltyUser);

    const {
      image,
      imageDescription,
      name: incentiveName,
      description,
      offerServiceMode,
      metadata,
      inlineAlertMessage,
      endDate,
    } = incentiveData;

    const showPromoCodeLabel = metadata?.some(
      data => data?.value === IncentiveSource.PROMOTION_CODE
    );

    const promoCodeLabel = showPromoCodeLabel
      ? formatMessage({ id: 'promoCodeBadgeLabel' })
      : undefined;

    return (
      <OfferListItem
        image={
          <Picture
            image={image}
            alt={imageDescription}
            height="97px"
            width="97px"
            objectFitContain
          />
        }
        header={incentiveName}
        description={description && <IncentiveCardDescription content={description} />}
        serviceModeStyle={
          offerServiceMode === OffersServiceMode.DELIVERY_ONLY ? 'default-outline' : 'default-solid'
        }
        promoCodeLabel={promoCodeLabel}
        onPress={handlePress}
        unavailable={!!inlineAlertMessage}
        unavailableMessage={inlineAlertMessage}
        unavailableIcon="infoOutline"
        isWalmartOffer={isWalmartOffer(incentiveData)}
        isWalmartUser={isWalmartUser}
        endDate={endDate}
      />
    );
  },
  (prev, next) => {
    return (
      prev.incentiveData.id === next.incentiveData.id &&
      prev.incentiveData.name === next.incentiveData.name
    );
  }
);
