import {
  Badge,
  Box,
  Button,
  HStack,
  Header,
  Image,
  InlineAlert,
  Pressable,
  VStack,
  addWithConfig,
} from '@rbilabs/universal-components';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';

import { OfferFeedbackMessage } from 'components/cart-item-experiment/styled';
import Picture from 'components/picture';
import { useLoyaltyWalmart } from 'pages/loyalty/loyalty-partners/walmart/use-loyalty-walmart';
import { selectors, useAppSelector } from 'state/global-state';
import { primitive } from 'styles/constants/primitives';

import { WalmartBadgeContainer } from './styled';
import type { OfferListItemProps } from './types';

const _OfferListItem = ({
  description,
  serviceModeStyle,
  serviceModeText,
  handleRemove,
  header,
  image,
  imageAlt,
  imageUri,
  offerApplied,
  offerText,
  onPress,
  promoCodeLabel,
  removeOfferText,
  unavailable,
  unavailableMessage,
  unavailableIcon,
  isWalmartOffer,
  isWalmartUser,
  endDate,
  ...buttonProps
}: OfferListItemProps) => {
  const { data: loyaltyWalmartData } = useLoyaltyWalmart();
  const { formatMessage } = useIntl();
  const loyaltyOffersInCooldown = useAppSelector(selectors.loyalty.selectLoyaltyOffersInCooldown);

  // Hide any Walmart offers if not a Walmart user
  if (!offerApplied && isWalmartOffer && !isWalmartUser) {
    return null;
  }

  let expires: string | null = null;
  if (!isWalmartOffer && typeof endDate === 'string' && endDate && endDate.slice(0, 4) !== '9999') {
    expires = formatMessage(
      { id: 'offerExpires' },
      { date: format(new Date(endDate), 'MM/dd/yy') }
    );
  }

  return (
    <Pressable
      accessibilityRole="button"
      dd-action-name={header}
      onPress={!loyaltyOffersInCooldown ? onPress : null}
      mb="$2.5"
      width="full"
      {...buttonProps}
    >
      {({ isHovered, isPressed, isFocused }) => {
        const pressed = isPressed && onPress;
        const offerIsApplied = offerApplied && offerText && handleRemove && removeOfferText;
        return (
          <Box
            bg={
              (unavailable && unavailableMessage) || loyaltyOffersInCooldown
                ? primitive.bk.$disabledNoOpacity
                : primitive.$white
            }
            rounded={10}
            borderWidth={offerApplied ? 2 : 0}
            borderColor={offerApplied ? 'token.border-color-offer-applied' : 'transparent'}
          >
            <VStack
              p={['$3', '$4']}
              pt={offerIsApplied ? '$1' : '$3'}
              borderTopRadius={10}
              borderBottomRadius={unavailable ? 0 : 10}
              bg={pressed || isFocused ? 'token.background-focus' : 'transparent'}
            >
              {offerIsApplied && (
                <HStack justifyContent="space-between" alignItems="center" pl="$2" mb="$2">
                  {!!offerText && (
                    <Badge variant="default-subtle" iconName="checkFill">
                      {offerText}
                    </Badge>
                  )}
                  <Button variant="ghost" onPress={handleRemove} size="md">
                    {removeOfferText}
                  </Button>
                </HStack>
              )}
              <HStack mr="$2">
                {image || (
                  <Image
                    accessibilityIgnoresInvertColors
                    resizeMode="contain"
                    source={{
                      uri: imageUri,
                    }}
                    alt={imageAlt}
                    height={['$24', '112']}
                    width={['$24', '112']}
                  />
                )}
                <VStack flexShrink={1} ml="$3" alignItems="flex-start" justifyContent="center">
                  <Header
                    variant="headerThree"
                    color={isHovered ? 'token.text-active' : 'token.text-default'}
                    ellipsizeMode="tail"
                    numberOfLines={2}
                  >
                    {header}
                  </Header>
                  {description}
                  {expires && <OfferFeedbackMessage>{expires}</OfferFeedbackMessage>}
                  <HStack>
                    {isWalmartOffer && (
                      <WalmartBadgeContainer mr="$2">
                        <Picture
                          height="$6"
                          image={loyaltyWalmartData?.badge?.locale}
                          alt={formatMessage({ id: 'walmartOfferBadgeAlt' })}
                        />
                      </WalmartBadgeContainer>
                    )}
                    {promoCodeLabel && (
                      <Badge variant="default-outline" iconName="offer" mr="$2">
                        {promoCodeLabel}
                      </Badge>
                    )}
                    {serviceModeText && (
                      <Badge variant={serviceModeStyle ?? 'default-solid'}>{serviceModeText}</Badge>
                    )}
                  </HStack>
                </VStack>
              </HStack>
            </VStack>
            {unavailable && !offerApplied && (
              <InlineAlert
                borderBottomRadius={10}
                borderTopRadius="0"
                width="full"
                status={unavailableIcon || 'info'}
                message={unavailableMessage}
                w="full"
              />
            )}
          </Box>
        );
      }}
    </Pressable>
  );
};

export const OfferListItem = addWithConfig(_OfferListItem);
