import { useEffect } from 'react';

import { useRoute } from 'hooks/navigation/use-route';
import { RunningExperiments, useExperiments } from 'state/experiments';
import { routes } from 'utils/routing';

export const useEnableCartV2Experiment = () => {
  const { variant, logExposure } = useExperiments();
  const { pathname } = useRoute();

  useEffect(() => {
    if (pathname === routes.cart) {
      logExposure(RunningExperiments.CartV2);
    }
  }, [pathname, logExposure]);

  return variant(RunningExperiments.CartV2, 'treatment', {
    skipExposureLogging: true,
  });
};
