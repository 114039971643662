// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
export var Framework;
(function (Framework) {
    // < 100 - Web frameworks
    Framework["WebUnknown"] = "0";
    Framework["React"] = "1";
    Framework["NextJs"] = "2";
    Framework["Angular"] = "3";
    Framework["VueJs"] = "4";
    Framework["Nuxt"] = "5";
    Framework["Svelte"] = "6";
    // 100s - Server side frameworks
    Framework["ServerSideUnknown"] = "100";
    Framework["ReactSSR"] = "101";
    Framework["NextJsSSR"] = "102";
    Framework["AngularSSR"] = "103";
    Framework["VueJsSSR"] = "104";
    Framework["NuxtSSR"] = "105";
    Framework["SvelteSSR"] = "106";
    // 200s - Mobile framework
    Framework["ReactNative"] = "201";
    Framework["Expo"] = "202";
})(Framework || (Framework = {}));
export var Category;
(function (Category) {
    Category["API"] = "api";
    Category["Auth"] = "auth";
    Category["Analytics"] = "analytics";
    Category["DataStore"] = "datastore";
    Category["Geo"] = "geo";
    Category["InAppMessaging"] = "inappmessaging";
    Category["Interactions"] = "interactions";
    Category["Predictions"] = "predictions";
    Category["PubSub"] = "pubsub";
    Category["PushNotification"] = "pushnotification";
    Category["Storage"] = "storage";
})(Category || (Category = {}));
export var AnalyticsAction;
(function (AnalyticsAction) {
    AnalyticsAction["Record"] = "1";
    AnalyticsAction["UpdateEndpoint"] = "2";
})(AnalyticsAction || (AnalyticsAction = {}));
export var ApiAction;
(function (ApiAction) {
    ApiAction["GraphQl"] = "1";
    ApiAction["Get"] = "2";
    ApiAction["Post"] = "3";
    ApiAction["Put"] = "4";
    ApiAction["Patch"] = "5";
    ApiAction["Del"] = "6";
    ApiAction["Head"] = "7";
})(ApiAction || (ApiAction = {}));
export var AuthAction;
(function (AuthAction) {
    // SignUp = '1',
    // ConfirmSignUp = '2',
    // ResendSignUp = '3',
    // SignIn = '4',
    // GetMFAOptions = '5',
    // GetPreferredMFA = '6',
    // SetPreferredMFA = '7',
    // DisableSMS = '8',
    // EnableSMS = '9',
    // SetupTOTP = '10',
    // VerifyTotpToken = '11',
    // ConfirmSignIn = '12',
    // CompleteNewPassword = '13',
    // SendCustomChallengeAnswer = '14',
    // DeleteUserAttributes = '15',
    // DeleteUser = '16',
    // UpdateUserAttributes = '17',
    // UserAttributes = '18',
    // CurrentUserPoolUser = '19',
    // CurrentAuthenticatedUser = '20',
    // CurrentSession = '21',
    // VerifyUserAttribute = '22',
    // VerifyUserAttributeSubmit = '23',
    // VerifyCurrentUserAttribute = '24',
    // VerifyCurrentUserAttributeSubmit = '25',
    // SignOut = '26',
    // ChangePassword = '27',
    // ForgotPassword = '28',
    // ForgotPasswordSubmit = '29',
    AuthAction["FederatedSignIn"] = "30";
    // CurrentUserInfo = '31',
    // RememberDevice = '32',
    // ForgetDevice = '33',
    // FetchDevices = '34',
})(AuthAction || (AuthAction = {}));
export var DataStoreAction;
(function (DataStoreAction) {
    DataStoreAction["Subscribe"] = "1";
    DataStoreAction["GraphQl"] = "2";
})(DataStoreAction || (DataStoreAction = {}));
export var GeoAction;
(function (GeoAction) {
    GeoAction["None"] = "0";
})(GeoAction || (GeoAction = {}));
export var InAppMessagingAction;
(function (InAppMessagingAction) {
    InAppMessagingAction["None"] = "0";
})(InAppMessagingAction || (InAppMessagingAction = {}));
export var InteractionsAction;
(function (InteractionsAction) {
    InteractionsAction["None"] = "0";
})(InteractionsAction || (InteractionsAction = {}));
export var PredictionsAction;
(function (PredictionsAction) {
    PredictionsAction["Convert"] = "1";
    PredictionsAction["Identify"] = "2";
    PredictionsAction["Interpret"] = "3";
})(PredictionsAction || (PredictionsAction = {}));
export var PubSubAction;
(function (PubSubAction) {
    PubSubAction["Subscribe"] = "1";
})(PubSubAction || (PubSubAction = {}));
export var PushNotificationAction;
(function (PushNotificationAction) {
    PushNotificationAction["None"] = "0";
})(PushNotificationAction || (PushNotificationAction = {}));
export var StorageAction;
(function (StorageAction) {
    StorageAction["Put"] = "1";
    StorageAction["Get"] = "2";
    StorageAction["List"] = "3";
    StorageAction["Copy"] = "4";
    StorageAction["Remove"] = "5";
    StorageAction["GetProperties"] = "6";
})(StorageAction || (StorageAction = {}));
