import { useMemo } from 'react';

import { Icon } from '@rbilabs/universal-components';
import { format, isValid } from 'date-fns';
import { useIntl } from 'react-intl';

import { LayoutContainer } from 'components/layout/styled';
import { useRoute } from 'hooks/navigation/use-route';
import { selectors, useAppSelector } from 'state/global-state';
import { useLoyaltyUser } from 'state/loyalty/hooks/use-loyalty-user';
import { routes } from 'utils/routing';

import {
  CooldownMessageContainer,
  OffersCooldownContainer,
  OffersCooldownMessage,
  OffersCooldownTitle,
} from './loyalty-offers.styled';

export const LoyaltyOffersCooldownContent = () => {
  const { formatMessage } = useIntl();
  const { loyaltyUser } = useLoyaltyUser();

  const availableAfterTime = useMemo(() => {
    const { availableAfter } = loyaltyUser?.offerRedemptionAvailability || {};
    const availableAfterDate = availableAfter && new Date(availableAfter);
    return (
      availableAfterDate && isValid(availableAfterDate) && format(availableAfterDate, 'hh:mm a')
    );
  }, [loyaltyUser?.offerRedemptionAvailability]);

  return (
    <>
      <Icon variant="errorFill" size="14" marginRight="$2" />
      <CooldownMessageContainer>
        <OffersCooldownTitle>{formatMessage({ id: 'offersCooldownTitle' })}</OffersCooldownTitle>
        <OffersCooldownMessage>
          {availableAfterTime
            ? formatMessage({ id: 'offersAvailableAt' }, { time: availableAfterTime })
            : formatMessage({ id: 'offersAvailableAtFallback' })}
        </OffersCooldownMessage>
      </CooldownMessageContainer>
    </>
  );
};

export const LoyaltyOffersCooldownMessage = () => {
  const { pathname } = useRoute();
  const isOffersPage = pathname.includes(routes.offers);
  const loyaltyOffersInCooldown = useAppSelector(selectors.loyalty.selectLoyaltyOffersInCooldown);

  if (!isOffersPage || !loyaltyOffersInCooldown) {
    return null;
  }

  return (
    <OffersCooldownContainer>
      <LayoutContainer flexDirection="row" alignItems="center">
        <LoyaltyOffersCooldownContent />
      </LayoutContainer>
    </OffersCooldownContainer>
  );
};
