import { Box } from '@rbilabs/universal-components';

import { primitive } from 'styles/constants/primitives';

export const ToastTemplateContainer = Box.withConfig({
  width: {
    base: '90%',
    md: '1/2',
  },
  height: '86px',
  flexDirection: 'row',
  alignItems: 'center',
  marginY: '$2',
  padding: '$3',
  backgroundColor: primitive.$white,
  borderRadius: Styles.borderRadius,
  borderWidth: 2,
  borderColor: primitive.$blackOpacity4,
  shadow: '2',
});
