import { differenceInMinutes } from 'date-fns';

import { IRestaurant } from '@rbi-ctg/store';
import { ILocation } from 'hooks/use-map/types';
import { useGeolocation } from 'state/geolocation';
import { coordinateDistance } from 'utils/rbi-common';

interface UserDistanceOptions {
  restaurant: IRestaurant | null;
  shouldUseUserCoordinatesIfAvailable?: boolean;
}

const MAX_TIME_WITHOUT_UPDATES_IN_MIN = 30;

export const useDistance = ({
  restaurant,
  shouldUseUserCoordinatesIfAvailable = false,
}: UserDistanceOptions): number | undefined => {
  const { activeCoordinates, userCoordinates, lastUserCoordinateTimestamp } = useGeolocation();

  return calculateDistance(
    restaurant,
    activeCoordinates,
    userCoordinates,
    lastUserCoordinateTimestamp,
    shouldUseUserCoordinatesIfAvailable
  );
};

export const calculateDistance = (
  restaurant: IRestaurant | null,
  activeCoordinates: ILocation | null,
  userCoordinates: ILocation | null,
  lastUserCoordinateTimestamp: number | null,
  shouldUseUserCoordinatesIfAvailable = false
) => {
  const latitude = restaurant?.latitude;
  const longitude = restaurant?.longitude;

  if (!latitude || !longitude) {
    return undefined;
  }

  const lastLocationTimeDiff = differenceInMinutes(
    lastUserCoordinateTimestamp ?? Date.now(),
    Date.now()
  );

  let distance = undefined;
  const coordinates = shouldUseUserCoordinatesIfAvailable
    ? userCoordinates ?? activeCoordinates
    : activeCoordinates;

  if (
    coordinates &&
    coordinates.lat &&
    coordinates.lng &&
    latitude &&
    longitude &&
    lastLocationTimeDiff < MAX_TIME_WITHOUT_UPDATES_IN_MIN
  ) {
    distance = coordinateDistance(
      { latitude: coordinates.lat, longitude: coordinates.lng },
      {
        latitude,
        longitude,
      }
    );
  }

  return distance;
};
